
import { defineStore } from 'pinia'

export const use_load_store = defineStore({
	id: 'load',
	state: () => ({
		// kpi data parameters
		_visible: new Map(),
		_error_message: new Map(),
	}),
	getters: {
		visible(state) {
			return (type = 'main') => {
				if (!state._visible.has(type)) return false
				return state._visible.get(type) || state._error_message.has(type)
			}
		},
		has_error(state) {
			return (type = 'main') => {
				return state._error_message.has(type)
			}
		},
		error_message(state) {
			return (type = 'main') => {
				if (!state._error_message.has(type)) return ""
				return state._error_message.get(type)
			}
		},
	},
	actions: {
		show(type = 'main') {
			this._visible.set(type, true)
			this.is_error = false
		},
		hide(type = 'main') {
			this._visible.set(type, false)
		},
		set_error(err, type = 'main') {
			this._error_message.set(type, err.response ? err.response.data.message : err.message)
			return false
		},
		set_error_message(error_message, type = 'main') {
			this._error_message.set(type, error_message)
		},
		clear_errors(type = 'main') {
			this._error_message.delete(type)
		},
		async wrap(action, type = 'main') {
			var ret = true
			this.show(type)
			try {
				await action()
			} catch(err) {
				this.set_error(err, type)
				console.error(err)
				ret = false
			} finally {
				this.hide(type)
			}

			return ret
		}
	}
})
