<template>
	<LoadIndicator :fixed_backdrop="true"/>
	<router-view name="menu"/>
	<router-view name="content"/>
	<router-view name="footer"/>
</template>

<script>
//import { use_config_store } from '@/stores/config.js'
import LoadIndicator from '@/components/LoadIndicator.vue'
export default {

	name: 'App',
	components: {
		LoadIndicator,
	},
	setup() {
		//const store = use_config_store()
		//store.load_config()
	},
	watch: {
		$route: {
			handler(to) {
				document.title = to.meta.title || 'MercuriusData.com';
			}
		},
	}
}

</script>

<style>

</style>
