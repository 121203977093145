<template>
	<form @submit.prevent="handle_save_column">
		<div class="row">
			<div class="col-6">
				<label>Filter Min</label>
				<input type="text" class="form-control" v-model="editable_filter_min" />
			</div>
			<div class="col-6">
				<label>Filter Max</label>
				<input type="text" class="form-control" v-model="editable_filter_max" />
			</div>
			<div class="col-12">
				<button type="submit" class="btn btn-primary float-end mt-3">Save Filter</button>
			</div>
		</div>
	</form>
</template>

<script>

import { use_column_store } from '@/stores/column.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: "FilterColumnEdit",
	props: {
		column: {
			type: Object,
			required: true,
		}
	},
	setup() {

		const column_store = use_column_store()

		return {
			column_store,
		}
	},
	data() {
		return {
			editable_filter_min: this.column.filter_min || "",
			editable_filter_max: this.column.filter_max || "",
		}
	},
	methods: {
		async handle_save_column() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.column_store.edit_column_props(this.column, {filter_min: this.editable_filter_min, filter_max: this.editable_filter_max})
			})
		},
	},
	watch: {
		column(new_column) {
			this.editable_filter_min = new_column.filter_min || ""
			this.editable_filter_min = new_column.filter_max || ""
		}
	}
}
</script>

<style scoped>

</style>
