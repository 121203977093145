
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { use_column_store } from '@/stores/column.js'
import { use_modal_store } from '@/stores/modal.js'
import { api } from '@/api.js'

export const use_workspace_store = defineStore({
	id: 'workspace',
	state: () => ({
		// column data parameters
		workspace: new Map(),
		workspace_ids: [],

		active_workspace_id: -1,

		workspace_picker_base_url: "/workspace/",

		// data loading parameters
		is_loading: false,
		is_error: false,
		error_message: '',
	}),
	getters: {
		get_workspace_name: (state) => {
			return (workspace_id) => {
				if (!state.workspace.has(workspace_id)) return "No Name"
				return state.workspace.get(workspace_id).name
			}			
		},
		has_workspace: (state) => {
			return state.active_workspace_id > 0
		},
		workspace_id: (state) => {
			return state.active_workspace_id
		},
		name: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return "No Name"
			return state.workspace.get(state.active_workspace_id).name
		},
		is_default: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return false
			return state.workspace.get(state.active_workspace_id).is_default
		},
		segment: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return null
			return state.workspace.get(state.active_workspace_id).segment_id
		},
		rebalance: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return 'year'
			return state.workspace.get(state.active_workspace_id).rebalance
		},
		date_begin: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return null
			return state.workspace.get(state.active_workspace_id).date_begin
		},
		date_end: (state) => {
			if (!state.workspace.has(state.active_workspace_id)) return null
			return state.workspace.get(state.active_workspace_id).date_end
		},
	},
	actions: {
		async fetch_workspaces(set_active = 0) {
			const user = use_user_store()
			const column_store = use_column_store()
			const res = await axios.get(
				api.host + '/workspace/all',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.workspace_ids = []
			for (var workspace of res.data.workspaces) {
				this.workspace.set(workspace.id, workspace)
				this.workspace_ids.push(workspace.id);
				if (set_active == 0 && workspace.is_current) {
					this.active_workspace_id = workspace.id
					column_store.order_by_column_id = workspace.order_by_column_id
					column_store.order_by_column_dir = workspace.order_by_column_dir
				} else if (set_active > 0 && workspace.id == set_active) {
					this.active_workspace_id = workspace.id
					column_store.order_by_column_id = workspace.order_by_column_id
					column_store.order_by_column_dir = workspace.order_by_column_dir
				}
			}
		},
		async add_workspace(name) {
			const user = use_user_store()
			const modal_store = use_modal_store()
			const res = await axios.post(
				api.host + '/workspace/add',
				{
					name: name,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			modal_store.close_modal()

			this.workspace.set(res.data.workspace.id, res.data.workspace)
			this.$router.push(this.workspace_picker_base_url + res.data.workspace.id)
		},
		async edit_workspace(workspace_id, update_data) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/workspace/edit/' + workspace_id,
				update_data,
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.workspace.set(res.data.workspace.id, res.data.workspace)
			return true
		},
		async delete_workspace(workspace_id, delete_metrics_also) {
			const user = use_user_store()
			await axios.post(
				api.host + '/workspace/delete/' + workspace_id,
				{
					delete_metrics_also: delete_metrics_also,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
			return true
		},
		async change_workspace(new_workspace_id) {
			this.$router.push(this.workspace_picker_base_url + new_workspace_id)
		},
		async change_workspace_simple(company_id, new_workspace_id) {
			this.$router.push('/company/' + company_id + '/'+ new_workspace_id)
		},
		reset_error() {
			this.error_message = ""
			this.is_error = false
		},
	}
})
