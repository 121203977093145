<template>
	<div class="row row-cols-1 row-cols-md-3 mb-3">
		<LoadIndicator type="plan_selector" />
		<div class="col" v-for="plan_id in billing_store.plan_ids" :key="plan_id">
			<div class="card" style="width: 18rem;">
				<div class="card-header text-center py-3">
					<h4 class="my-0 card-title">{{ billing_store.plan_name(plan_id) }}</h4>
				</div>
				<div class="card-body">
					<h2 class="text-center">{{ billing_store.plan_price(plan_id) }}kr <small>/mo</small></h2>
					<ul>
						<li v-for="feature in billing_store.plan_features(plan_id)" :key="feature">{{ feature }}</li>
					</ul>
					<button type="button" class="w-100 btn btn-lg btn-outline-primary" @click="() => handle_sign_up(plan_id)">Sign Up</button>
				</div>
			</div>
		</div>
		<SignUp />
	</div>
</template>

<script setup>
</script>

<script>

import LoadIndicator from '@/components/LoadIndicator.vue'
import SignUp from '@/modals/SignUp.vue'
import { use_billing_store } from '@/stores/billing.js'
import { use_signup_store } from '@/stores/signup.js'
import { use_modal_store } from '@/stores/modal.js'

export default {
	name: 'PlanSelector',
	components: {
		LoadIndicator,
		SignUp,
	},
	setup() {
		const billing_store = use_billing_store()

		return {
			billing_store,
		}
	},
	props: {
		
	},
	methods: {
		handle_sign_up(plan_id) {
			const signup_store = use_signup_store()
			const modal_store = use_modal_store()

			signup_store.plan_id = plan_id

			modal_store.open_modal("sign_up")
		}
	}
}
</script>
<style scoped>

small {
	color: #999;
	font-size: 14px;
}
.plan-choose {
	border: 1px solid #dee2e6;
	border-radius: 3px;
	padding: 10px;
	cursor: pointer;
}

.plan-choose.active {
	background: #efefef;
	border: 1px solid #bbb;
}

.plan-choose-title {
	font-size: 12px;
}

.plan-choose-price {
	font-weight: bold;

	small {
		font-weight: normal;
		color: #555;
	}
}
</style>
