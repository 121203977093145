<template>
	<div class="row mt-3">
		<div class="col-12">
			<table class="analytics-table">
				<template v-for="column_id in column_store.active_non_info_columns" :key="column_id">
					<tr>
						<td class="first-col">
							<b class="metric-name">{{ column_store.get_column_name(column_id) }}</b>
							<i class="edit-column bi bi-pencil ms-2 me-2" @click="() => edit_column(column_id)" v-if="column_id > 0"></i>
						</td>
						<td class="asc-desc-col">
						</td>
						<td v-for="date_pair in analytics_store.dates" :key="date_pair[0]" class="analysis-date">{{ date_pair[0] }}</td>
					</tr>
					<template v-for="idx in analytics_store.segment_indexes(column_id)" :key="idx">
						<template v-if="analytics_store.has_data(column_id, idx)">
							<tr :class="analytics_max_class(column_id, idx)">
								<td class="info-col">
									<a :href="'/analytics/one_segment/' + workspace_store.active_workspace_id + '/' + column_id + '/' + idx + '/' + analytics_store.first_date + '/' + analytics_store.last_date">
										{{ analytics_store.total_return(column_id, idx) }}% total return<br/>
									</a>
									<div>{{ analytics_store.cagr(column_id, idx) }}% CAGR</div>
									<div>{{ $utils.growth(1.0 - analytics_store.max_drawdown(column_id, idx)) }} Max Decline</div>
									<div v-if="analytics_store.waiting(column_id)">&#8987; Average CAGR</div>
									<div v-if="analytics_store.waiting(column_id)">&#8987; Stickyness</div>
									<div v-if="analytics_store.waiting(column_id)">&#8987; Stickyness vs Index</div>
									<div v-if="!analytics_store.waiting(column_id)"><a :href="'/analytics/extended_analysis/' + analytics_store.result_id(column_id, idx)">{{ analytics_store.avg_cagr(column_id, idx) }}% Average CAGR</a></div>
									<div v-if="!analytics_store.waiting(column_id)">{{ analytics_store.stickyness(column_id, idx) }}% Stickyness</div>
									<div v-if="!analytics_store.waiting(column_id)">{{ analytics_store.stickyness_vs_index(column_id, idx) }}% Stickyness vs Index</div>
								</td>
								<td class="asc-desc-col segment-info">
									{{ analytics_store.segment_description(column_id, idx) }}
								</td>
								<td v-for="date_pair in analytics_store.dates" :key="date_pair[0]">
									<a :href="'/analytics/one_segment/' + workspace_store.active_workspace_id + '/' + column_id + '/' + idx + '/' + date_pair[0] + '/' + date_pair[1]">
										<img class="analysis-img" :src="analytics_store.image_url(column_id, idx, date_pair[0])" />
									</a>
								</td>
							</tr>
							<tr>
								<td class="info-col"></td>
								<td class="asc-desc-col">
									<div class="mb-3">
										<div>Max</div>
										<div>Min</div>
										<div>Size</div>
									</div>
								</td>
								<td v-for="date_pair in analytics_store.dates" :key="date_pair[0]">
									<div class="minmax-container mb-3">
										<CellValue class="d-block" :value="analytics_store.min(column_id, idx, date_pair[0])" />
										<CellValue class="d-block" :value="analytics_store.max(column_id, idx, date_pair[0])" />
										<div>{{ analytics_store.size(column_id, idx, date_pair[0]) }}</div>
									</div>
								</td>
							</tr>
						</template>
					</template>
					<tr>
						<td colspan="1000"><hr class="separator" /></td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>

import { use_analytics_store } from '@/stores/analytics.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_column_store } from '@/stores/column.js'
import { use_metric_store } from '@/stores/metric.js'
import { use_modal_store } from '@/stores/modal.js'
import CellValue from '@/atom/CellValue.vue'

export default {
	name: 'AnalyticsTable',
	components: {
		CellValue,
	},
	props: {
	},
	setup() {

		const analytics_store = use_analytics_store()
		const workspace_store = use_workspace_store()
		const column_store = use_column_store()
		const metric_store = use_metric_store()

		return {
			analytics_store,
			workspace_store,
			column_store,
			metric_store,
		}
	},
	data() {
		return {
		}
	},
	methods: {
		edit_column(column_id) {
			const modal_store = use_modal_store()
			this.metric_store.edit_column(column_id, this.column_store.get_metric_id(column_id))
			modal_store.open_modal("add_metric")
		},
		analytics_max_class(column_id, idx) {
			if (this.analytics_store.has_long_str(column_id, idx)) {
				return {
					"two-row-numbers": true,
				}
			} else {
				return {
					"one-row-numbers": true,
				}
			}
		}
	}
}
</script>

<style scoped>


.two-row-numbers {
	.analysis-min {
		width: 100%;
		display: block;
		margin-left: 4px;
	}

	.analysis-max {
		margin-right: 4px;
		width: 100%;
		display: block;
		text-align: right;
	}
}

.one-row-numbers {
	.analysis-min {
		margin-left: 4px;
	}
	.analysis-max {
		position: absolute;
		right: 4px;
	}
}

.analytics-table {
	font-size: 12px;
	width: auto;
}

.minmax-container {
	position: relative;
}

.analysis-img {
	display: block;
}

.first-col {
	min-width: 200px;
	max-width: 250px;
}

.analysis-date {
	font-weight: bold;
	text-align: center;
}

.separator {
	border-width: 5px;
	margin-top: 1em;
	margin-bottom: 1em;
}

.metric-name {
	font-size: 20px;
}

.asc-desc-col {
	text-align: right;
	color: #6D747B;
}

.info-col {
	vertical-align: top;
}

.segment-info {
	font-size: 18px;
}

.edit-column {
	cursor: pointer;
}

</style>
