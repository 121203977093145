<template>
	<div class="container-md mt-3">
		<div class="mb-3 row">
			<div class="col-md-6 offset-md-3">
				<div :class="make_class">{{ billing_store.confirm_message }}</div>
				<center>
					<a href="/billing">Back to Billing</a> &nbsp; <a href="/screener" class="btn btn-primary">To Screener</a>
				</center>
			</div>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'
import { use_billing_store } from '@/stores/billing.js'

async function load_data(to, from, next) {
	
	const load = use_load_store()
	await load.wrap(async () => {
		const billing_store = use_billing_store()

		if (to.query.setup_intent_client_secret) {
			await billing_store.fetch_setup_intent(to.query.setup_intent_client_secret)
		} else if (to.query.payment_intent_client_secret) {
			await billing_store.fetch_payment_intent(to.query.payment_intent_client_secret)
		}
		await billing_store.fetch_subscription()
		await billing_store.fetch_user()
	})

	next()
}

export default {
	components: {
	},
	props: {
		seti_client_secret: {
			type: String,
		},
		pay_client_secret: {
			type: String,
		},
	},
	setup() {

		const user_store = use_user_store()
		const billing_store = use_billing_store()

		return {
			user_store,
			billing_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)

	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	computed: {
		make_class() {
			var cls = {'alert': true}
			cls['alert-' + this.billing_store.confirm_status] = true
			return cls
		}
	},
	methods: {
		
	}
}
</script>
