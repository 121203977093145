<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-6">
				<a class="btn btn-primary" :href="'/analytics/' + workspace_id">Back</a>
				<h1>Analyze One Segment</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<label>Performance vs Index</label>
				<table class="table">
					<thead>
						<tr>
							<th></th><th>Backtest</th><th>Index</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>CAGR</td>
							<td>{{ $utils.growth(analytics_store.one_period_cagr) }}</td>
							<td>{{ $utils.growth(analytics_store.one_period_cagr_index) }}</td>
						</tr>
						<tr>
							<td>Total Return</td>
							<td>{{ $utils.growth(analytics_store.one_period_total_return) }}</td>
							<td>{{ $utils.growth(analytics_store.one_period_total_return_index) }}</td>
						</tr>
						<tr>
							<td>Max Decline</td>
							<td>{{ $utils.growth(1.0 - analytics_store.one_period_max_drawdown) }}</td>
							<td>{{ $utils.growth(1.0 - analytics_store.one_period_max_drawdown_index) }}</td>
						</tr>
					</tbody>
				</table>
				<label>Info</label>
				<table class="table">
					<tbody>
						<tr>
							<td>Date begin</td><td>{{ analytics_store.one_period_date_begin }}</td><td></td>
						</tr>
						<tr>
							<td>Date end</td><td>{{ analytics_store.one_period_date_end }}</td><td></td>
						</tr>
						<tr>
							<td>Metric</td><td>{{ analytics_store.one_period_metric_name }}</td><td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-6">
				<Line :data="analytics_store.graph_data" :options="analytics_store.graph_options" />
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h3>Event Log</h3>
				<div v-for="(meta, index) in analytics_store.one_period_meta" :key="index" class="card">
					<div class="card-body">
						<h4>Buy Date {{ $utils.date_to_str(meta.date_begin) }} Sell Date {{ $utils.date_to_str(meta.date_end) }}</h4>
						<a :href="'/date/' + $utils.date_to_str(meta.date_begin)">View Screener at {{ $utils.date_to_str(meta.date_begin) }}</a>
						<table class="table">
							<tbody>
								<tr><th>Company</th><th>{{ analytics_store.one_period_metric_name }}</th><th>Buy</th><th>Sell</th><th>Sell (Unadjusted)</th><th>Dividends (SEK)</th><th>Split Factor</th><th>Return</th></tr>
								<tr v-for="(company_id, company_index) in meta.company_ids" :key="company_index">
									<td><a :href="'/company/' + company_id + '/' + workspace_id">{{ company_store.company_name_by_id(company_id) }}</a></td>
									<td><CellValue :value="meta.column_values[company_index]" /></td>
									<td><CellValue :value="meta.value_begin[company_index]" /></td>
									<td><CellValue :value="sell_adj(meta, company_id, company_index)" /></td>
									<td><CellValue :value="meta.value_end[company_index]" /></td>
									<td><CellValue :value="dividends(meta, company_id)" /></td>
									<td><CellValue :value="splits(meta, company_id)" /></td>
									<td><CellValue :value="$utils.growth(sell_adj(meta, company_id, company_index) / meta.value_begin[company_index])" /></td>
								</tr>
								<tr>
									<td>Return</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td><CellValue :value="$utils.growth(analytics_store.sum_return(meta))" /></td>
								</tr>
							</tbody>
						</table>
						<h4>Event List</h4>
						<table class="table">
							<tbody>
								<tr><th>Company</th><th>Event</th><th>Exdate</th><th>Amount/Multiple</th></tr>
								<tr v-for="(event, event_index) in meta.events" :key="event_index">
									<td>{{ company_store.company_name_by_id(event[1]) }}</td>
									<td>{{ event[0] }}</td>
									<td>{{ event[2] }}</td>
									<td>{{ event[3] }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

import { use_load_store } from '@/stores/load.js'
import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_analytics_store } from '@/stores/analytics.js'
import { use_company_store } from '@/stores/company'
import { use_workspace_store } from '@/stores/workspace'
import CellValue from '@/atom/CellValue.vue'

export default {
	name: 'AnalyzeOneSegment',
	components: {
		Line,
		CellValue,
	},
	props: {
		workspace_id: {
			type: Number,
			required: true,
		},
		column_id: {
			type: Number,
			required: true,
		},
		segment: {
			type: Number,
			required: true,
		},
		date_begin: {
			type: String,
			required: true,
		},
		date_end: {
			type: String,
			required: true,
		},
	},
	setup() {

		const modal_store = use_modal_store()
		const company_store = use_company_store()
		const analytics_store = use_analytics_store()

		return {
			modal_store,
			company_store,
			analytics_store,
		}
	},
	async beforeRouteEnter(to, from, next) {
		const load = use_load_store()
		await load.wrap(async () => {
			
			const company_store = use_company_store()
			await company_store.fetch_all_companies()

			const workspace_store = use_workspace_store()
			await workspace_store.fetch_workspaces(parseInt(to.params.workspace_id || 0))

			const column_store = use_column_store()
			column_store.fetch_columns_for_workspace(workspace_store.active_workspace_id, false)

			const analytics_store = use_analytics_store()
			await analytics_store.fetch_segments()
			await analytics_store.fetch_one_period(parseInt(to.params.column_id || 0), parseInt(to.params.segment_idx || 0), to.params.date_begin, to.params.date_end)
		})
		next()
	},
	data() {
		return {
			
		}
	},
	computed: {
	},
	methods: {
		edit_strategy() {
			this.modal_store.open_modal("edit_strategy")
		},
		dividends(meta, company_id) {
			return meta.events.filter((e) => e[0] == "dividend" && e[1] == company_id).reduce((acc, e) => acc + e[3], 0)
		},
		splits(meta, company_id) {
			return meta.events.filter((e) => e[0] == "split" && e[1] == company_id).reduce((acc, e) => acc * e[3], 1.0)
		},
		sell_adj(meta, company_id, company_index) {
			let split_factor = this.splits(meta, company_id)
			let dividends = this.dividends(meta, company_id)
			return meta.value_end[company_index] / split_factor + dividends
		},
	}
}
</script>
