<template>
	<div class="container-md">
		<div class="row mt-3">
			<div class="col-12">
				<h1>{{ watchlist_store.name }}</h1>
				<Line :data="watchlist_store.graph_data" :options="watchlist_store.graph_options" />
				<div class="row">
					<div class="col">
						<input ref="add_share_input" type="text" v-model="search_term" class="form-control search-input" placeholder="Search for company" @input="handle_search" />
						<div class="search-result-outer">
							<div class="search-result-wrapper">
								<template v-for="share_id in watchlist_store.search_result_ids" :key="share_id">
									<div class="search-result" @click="handle_add(share_id)">
										{{ watchlist_store.search_result_name(share_id) }} {{ watchlist_store.search_result_delisted(share_id) }}
									</div>
								</template>
							</div>
						</div>
					</div>
					<div class="col">
						<button class="btn btn-primary float-end" @click="open_edit_modal">Edit</button>
					</div>
				</div>
				<table class="table">
					<thead>
						<tr>
							<th>Company</th>
							<th>Short Name</th>
							<th>Number of Shares</th>
							<th>Buy Price</th>
							<th>Last Price</th>
							<th>Buy Value</th>
							<th>Last Value</th>
							<th>Dividends</th>
							<th>Growth</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="watchlist_store.share_ids.length == 0">
							<tr>
								<td colspan="100" class="no-shares">No companies in watchlist <button class="btn btn-sm btn-primary" @click="handle_focus_add_share">Add</button></td>
							</tr>
						</template>
						<template v-for="share_id in watchlist_store.share_ids" :key="share_id">
							<tr>
								<td>{{ watchlist_store.share_company(share_id) }}</td>
								<td>{{ watchlist_store.share_name(share_id) }}</td>
								<template v-if="watchlist_store.buy_price(share_id) == 0">
									<td colspan="7" class="share-info-message">{{ watchlist_store.message(share_id) }}</td>
								</template>
								<template v-if="watchlist_store.buy_price(share_id) > 0">
									<td>{{ watchlist_store.num_shares(share_id) }}</td>
									<td>{{ $utils.round(watchlist_store.buy_price(share_id)) }}</td>
									<td>{{ $utils.round(watchlist_store.last_price(share_id)) }}</td>
									<td>{{ $utils.round(watchlist_store.buy_value(share_id)) }}</td>
									<td>{{ $utils.round(watchlist_store.last_value(share_id)) }}</td>
									<td>{{ $utils.round(watchlist_store.dividends(share_id)) }}</td>
									<td>{{ $utils.growth(watchlist_store.growth(share_id)) }}</td>
								</template>
								<td><button type="button" class="btn-close" @click="handle_remove(share_id)"></button></td>
							</tr>
						</template>
						<tr v-if="watchlist_store.share_ids.length > 0">
							<td>Sum</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td>{{ $utils.round(watchlist_store.buy_value_sum) }}</td>
							<td>{{ $utils.round(watchlist_store.last_value_sum) }}</td>
							<td>{{ $utils.round(watchlist_store.dividends_sum) }}</td>
							<td>{{ $utils.growth(watchlist_store.growth_sum) }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<div class="card">
					<div class="card-body watchlist-info">
						<div class="row">
							<div class="col-auto">
								<label>Date Begin</label><br/>
								<b>{{ watchlist_store.date_begin }}</b>
							</div>
							<div class="col-auto">
								<label>Initial Value</label><br/>
								<b>{{ $utils.round(watchlist_store.buy_value_sum) }} SEK</b>
							</div>
							<div class="col-auto">
								<label>Earned Dividends</label><br/>
								<b>{{ $utils.round(watchlist_store.dividends_sum) }} SEK</b>
							</div>
							<div class="col-auto">
								<label>Market Value</label><br/>
								<b>{{ $utils.round(watchlist_store.last_value_sum) }} SEK</b>
							</div>
							<div class="col-auto">
								<label>Growth %</label><br/>
								<b :class="{growth: true, positive: watchlist_store.growth_sum >= 1}">{{ watchlist_store.growth_sum > 1 ? '+' : ''}}{{ $utils.growth(watchlist_store.growth_sum) }} </b>
							</div>
							<div class="col-auto">
								<label>Earnings</label><br/>
								<b :class="{growth: true, positive: watchlist_store.growth_sum >= 1}">{{ watchlist_store.last_value_sum + watchlist_store.dividends_sum - watchlist_store.buy_value_sum >= 0 ? '+' : ''}}{{ $utils.round(watchlist_store.last_value_sum + watchlist_store.dividends_sum - watchlist_store.buy_value_sum) }} SEK</b>
							</div>
							<div class="col-auto">
								<label>Comment</label><br/>
								<p>{{ watchlist_store.comment }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card mt-5">
					<div class="card-body watchlist-info">
						<div class="row">
							<div class="col">
								<button class="btn btn-warning" @click="handle_delete">Delete Watchlist</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<EditWatchlistModal :watchlist_id="watchlist_store.id" :is_open="modal_store.current_modal == 'edit_watchlist'"/>
	</div>
</template>

<script setup>
</script>

<script>

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

import { use_watchlist_store } from '@/stores/watchlist.js'
import { use_load_store } from '@/stores/load.js'
import { use_modal_store } from '@/stores/modal.js'
import EditWatchlistModal from '@/modals/EditWatchlistModal.vue'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {

		const watchlist_store = use_watchlist_store()
		await watchlist_store.fetch_watchlist(parseInt(to.params.watchlist_id))

	})

	next()
}

export default {
	name: 'WatchlistView',
	components: {
		Line,
		EditWatchlistModal,
	},
	props: {
	},
	setup() {

		const watchlist_store = use_watchlist_store()
		const modal_store = use_modal_store()

		return {
			watchlist_store,
			modal_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
			search_term: '',
			is_loading: false,
		}
	},
	computed: {
	},
	methods: {
		async handle_search() {
			this.is_loading = true
			await this.watchlist_store.search_for(this.search_term)
			this.is_loading = false
		},
		async handle_add(share_id) {
			const load = use_load_store()

			await load.wrap(async () => {

				await this.watchlist_store.add(share_id)
				this.search_term = ''

			})
		},
		async handle_remove(share_id) {
			const load = use_load_store()

			await load.wrap(async () => {

				await this.watchlist_store.remove(share_id)

			})
		},
		async handle_delete() {
			const load = use_load_store()

			await load.wrap(async () => {

				await this.watchlist_store.delete()

			})

			document.location.href = "/watchlists"

		},
		open_edit_modal() {
			this.modal_store.open_modal('edit_watchlist')
		},
		handle_focus_add_share() {
			this.$nextTick(() => {
				this.$refs.add_share_input.focus()
			})
		},
	}
}
</script>

<style scoped>

.watchlist-info b {
	font-size: 1.2em;
}

.search-input {
}

.growth {
	color: red;
}

.growth.positive {
	color: green;
}

.no-shares {
	text-align: center;
	font-style: italic;
}

.search-result-outer {
	position: relative;
}

.search-result-wrapper {
	position: absolute;
	background: #fff;
	border: 1px solid var(--bs-border-color);
	z-index: 1000;
	left: 5px;
	top: -1px;
}

.share-info-message {
	font-style: italic;
}

.search-result {
	padding: 3px;
	cursor: pointer;
	text-decoration: underline;
}

</style>
