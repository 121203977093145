<template>
	<span :class="this.class" v-if="!is_obj && !is_arr" :title="title_value">{{ parsed_value }}</span>
	<template v-if="is_arr">
		<span :class="this.class" v-for="(child, child_index) in this.value" :key="child_index" class="array-item"><CellValue :value="child" type="value" /></span>
	</template>
	<template v-if="is_obj && !is_arr">
		<a v-if="this.value.type === 'link'" :href="this.value.href" target="_blank">{{ this.value.text }}</a>
		<span :class="this.class" v-if="this.value.type !== 'link'">{{ JSON.stringify(this.value) }}</span>
	</template>
</template>

<script>

export default {

	name: 'CellValue',
	components: {

	},
	props: {
		value: {
			type: [Number, String, Object, Array],
			required: true,
		},
		type: {
			type: String,
			required: false,
			default: "value",
		},
		class: {
			type: String,
			required: false,
			default: "",
		},
	},
	data() {
		const value_type = typeof this.value
		return {
			is_arr: value_type == "object" && Array.isArray(this.value) && this.value !== null,
			is_obj: value_type == "object" && this.value !== null,
		}
	},
	computed: {
		parsed_value() {
			if (this.value === null) return 'null';
			if (this.value == "-") return this.value
			if (this.type == "percent") {
				return (Math.round(this.value * 10000) / 100) + "%"
			}
			if (this.type == "value_trimmed") {
				if (typeof this.value === "number") {
					const strv = (Math.round(this.value * 100)/100).toString()
					if (strv.length > 2 && strv[strv.length - 2] == ".") return strv + "0"
					if (strv.length > 3 && strv[strv.length - 3] == ".") return strv
					return strv
				}
				if (typeof this.value === "string") {
					const maxlen = 15
					if (this.value.length > maxlen) return this.value.substring(0, maxlen) + '...'
				}
			}
			if (this.type == "value") {
				if (typeof this.value === "number") {
					const strv = (Math.round(this.value * 100)/100).toString()
					if (strv.length > 2 && strv[strv.length - 2] == ".") return strv + "0"
					if (strv.length > 3 && strv[strv.length - 3] == ".") return strv
					return strv + ".00"
				}
				if (typeof this.value === "string") {
					const maxlen = 15
					if (this.value.length > maxlen) return this.value.substring(0, maxlen) + '...'
				}
			}
			return this.value
		},
		title_value() {
			if (typeof this.value === "number") {
				return this.value
			}
			if (typeof this.value === "string") {
				return this.value
			}
			return ""
		}
	}
}

</script>

<style scoped>
div {
	white-space: nowrap;
}
.array-item {
	margin-right: 5px;
	display: inline;
}
</style>
