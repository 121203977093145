<template>
	<div class="container-md mt-3">
		<div v-if="billing_store.needs_payment_method">
			<div class="mb-3 row">
				<div class="col-md-6 offset-md-3" v-if="billing_store.subscription_status != 'incomplete'">
					<p>Add a payment method to activate your free trial</p>
					<PaymentAdditional  />
				</div>
				<div class="col-md-6 offset-md-3" v-if="billing_store.subscription_status == 'incomplete'">
					<p>Make your first payment to activate the subscription.</p>
					<PaymentInitial v-if="billing_store.subscription_status == 'incomplete'"/>
				</div>
			</div>
		</div>
		<div v-if="!billing_store.needs_payment_method">
			<div class="mb-3 row">
				<div class="col-md-6 offset-md-3">
					<div class="card subscription-info">
						<div class="card-body" v-if="billing_store.subscription_plan_id !== null">
							<h5>{{ billing_store.plan_name(billing_store.subscription_plan_id)}}</h5>
							<div>Status: {{ billing_store.subscription_pretty_status }}</div>
							<div>Amount: {{ billing_store.subscription.amount }} {{ billing_store.subscription.currency }}</div>
							<div v-if="billing_store.subscription_status == 'trialing'">First Payment: {{ billing_store.subscription.period_end }}</div>
							<div v-if="billing_store.subscription_status != 'trialing'">Next Payment: {{ billing_store.subscription.period_end }}</div>
						</div>
						<div class="card-body" v-if="billing_store.subscription_plan_id === null">
							<h5>Free Account</h5>
						</div>
					</div>
					<PaymentMethod v-for="pm_id in billing_store.payment_method_ids" :key="pm_id" :pm_id="pm_id" />
					<center v-if="billing_store.subscription_status != 'inactive'"><button class="btn btn-sm btn-link" @click="modal_store.open_modal('add_payment_method')">Add Payment Method</button></center>
				</div>
			</div>
			<div class="mb-3 row">
				<div class="col-md-6 offset-md-3">
					<PaymentModify v-for="plan_id in billing_store.plan_ids" :key="plan_id" :plan_id="plan_id" />
				</div>
			</div>
			<AddPaymentMethod v-if="billing_store.subscription_status != 'inactive'"/>
		</div>
	</div>
</template>

<script>

import { use_load_store } from '@/stores/load.js'
import { use_billing_store } from '@/stores/billing.js'
import { use_modal_store } from '@/stores/modal.js'

import PaymentAdditional from '@/components/PaymentAdditional.vue'
import PaymentMethod from '@/components/PaymentMethod.vue'
import PaymentModify from '@/components/PaymentModify.vue'
//import Subscription from '@/components/Subscription.vue'
import PaymentInitial from '@/components/PaymentInitial.vue'
import AddPaymentMethod from '@/modals/AddPaymentMethod.vue'

async function load_data(to, from, next) {

	const load = use_load_store()
	await load.wrap(async () => {
		const billing_store = use_billing_store()

		await billing_store.fetch_user()
		await billing_store.fetch_plans()
		await billing_store.fetch_subscription()
		await billing_store.fetch_payment_methods()
	})

	next()
}

export default {
	components: {
		PaymentMethod,
		PaymentInitial,
		PaymentModify,
		AddPaymentMethod,
		PaymentAdditional,
	},
	setup() {

		const billing_store = use_billing_store()
		const modal_store = use_modal_store()

		return {
			billing_store,
			modal_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)

	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	computed: {
		elements_options() {
			return {
			}
		}
	},
	methods: {
		async handle_delete_payment_method(pm_id) {
			const load = use_load_store()
			await load.wrap(async () => {
				const billing_store = use_billing_store()
				await billing_store.delete_payment_method(pm_id)
			})
		}
	}
}
</script>

<style scoped>
.subscription-info {
}
</style>
