
import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
	(response) => {
		if (typeof response.data !== 'object') {
			throw new Error('Invalid JSON response received: ' + response.data);
		}
		return response;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
