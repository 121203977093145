<template>
	<div :style="css_vars" class="graphical-explanation">
		<div class="box1"></div>
		<div class="line1"></div>
		<div class="line2"></div>
		<div class="text1">This is the 'scope', usually it is set to <pre>_current</pre> to get the most recent data but this is where we control if we want to look at past data. See <a href="#scopes">Scopes</a></div>

		<div class="box2"></div>
		<div class="line3"></div>
		<div class="line4"></div>
		<div class="text2">This part is a table name and a variable name. In the documentation below you can find all table and variable names.</div>
	</div>
</template>

<script setup>
</script>

<script>

export default {
	name: 'GraphicalExplanation1',
	components: {
	},
	props: {
	},
	setup() {

		return {
		}
	},
	data() {
		return {
			monospace_scale: document.getElementById("font_measure").getBoundingClientRect().width,
		}
	},
	computed: {
		css_vars() {
			return {
				'--monospace-scale': this.monospace_scale / 9.600006103515625,
			}
		}
	},
	methods: {
		
	}
}
</script>

<style scoped>

pre {
	display: inline;
}

.graphical-explanation {
	position: relative;
	width: 100%;
	height: 170px;
}

.box1 {
	width: calc(80px * var(--monospace-scale));
	height: 10px;
	border-top: none;
	border-left: 2px solid #000;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;

	top: -17px;
	left: calc(347px * var(--monospace-scale));
	position: absolute;
}

.line1 {
	width: 2px;
	height: 50px;
	background: #000;

	top: -7px;
	left: calc(385px * var(--monospace-scale));
	position: absolute;
}

.line2 {
	width: calc(40px * var(--monospace-scale));
	height: 2px;
	background: #000;

	top: 43px;
	left: calc(347px * var(--monospace-scale));
	position: absolute;
}

.text1 {
	width: calc(300px * var(--monospace-scale));
	height: 200px;
	top: 31px;
	left: calc(64px * var(--monospace-scale));
	position: absolute;
}

.box2 {
	width: calc(108px * var(--monospace-scale));
	height: 10px;
	border-top: none;
	border-left: 2px solid #000;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;

	top: -17px;
	left: calc(434px * var(--monospace-scale));
	position: absolute;
}

.line3 {
	width: 2px;
	height: 70px;
	background: #000;

	top: -7px;
	left: calc(482px * var(--monospace-scale));
	position: absolute;
}

.line4 {
	width: calc(56px * var(--monospace-scale));
    height: 2px;
    background: #000;
    top: 63px;
    left: calc(482px * var(--monospace-scale));
    position: absolute;
}

.text2 {
	width: calc(300px * var(--monospace-scale));
    height: 200px;
    top: 50px;
    left: calc(549px * var(--monospace-scale));
    position: absolute;
}



</style>
