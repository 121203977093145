
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_backtest_result_store = defineStore({
	id: 'backtest_result',
	state: () => ({
		// column data parameters
		periods: new Map(),
		period_ids: [],

		workspace_id: -1,

		avg_cagr: '',
		best_cagr: '',
		worst_cagr: '',

		avg_max_decline: '',
		best_max_decline: '',
		worst_max_decline: '',

	}),
	getters: {
		date_begin: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).date_begin
			}
		},
		date_end: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).date_end
			}
		},
		cagr: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).cagr
			}
		},
		max_decline: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).max_decline
			}
		},
		abs_up_up: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).abs_up_up
			}
		},
		abs_up_down: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).abs_up_down
			}
		},
		index_up_up: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).index_up_up
			}
		},
		index_up_down: (state) => {
			return (period_id) => {
				if (!state.periods.has(period_id)) return ""
				return state.periods.get(period_id).index_up_down
			}
		},
	},
	actions: {
		async fetch_extended_analysis(result_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/analytics/extended_analysis/' + result_id,
				{
					headers: { Authorization: user.session_id }
				}
			)
			for (var i in res.data.result) {
				this[i] = res.data.result[i]
			}
			this.period_ids = []
			for (var period of res.data.periods) {
				this.periods.set(period.id, period)
				this.period_ids.push(period.id)
			}
		},
	}
})
