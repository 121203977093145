
export default {
	salt1() {
		return "906294508778046048313859"
	},
	salt2() {
		return "871568507533827441448565"
	},
	percent(val) {
		return (val * 100).toFixed(2) + "%";
	},
	round(val) {
		return Math.round(val * 100) / 100;
	},
	growth(val) {
		return ((val - 1.0) * 100).toFixed(2) + "%";
	},
	date_to_str(date) {
		const strval = date.toString()
		if (strval.length == 8) {
			return strval.substring(0, 4) + "-" + strval.substring(4, 6) + "-" + strval.substring(6)
		}
		return strval
	},
	calculate_var_name(name) {
		name = name.toLowerCase()
		name = name.replace(/[^a-z0-9åäö]+/ig, " ")
		name = name.trim().replace(/[\s]+/ig, "_")
		if (/^[0-9]/.test(name)) {
			name = "_" + name
		}
		return name
	},
	async sha256(message) {
		// encode as UTF-8
		const msgBuffer = new TextEncoder().encode(message);                    

		// hash the message
		const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

		// convert ArrayBuffer to Array
		const hashArray = Array.from(new Uint8Array(hashBuffer));

		// convert bytes to hex string                  
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex;
	},
}
