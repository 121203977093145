<template>
	<div class="container-fluid">
		<ResetPassword />
	</div>
</template>

<script>

import ResetPassword from '../components/ResetPassword.vue'

export default {
	components: {
		ResetPassword,
	}
}
</script>
