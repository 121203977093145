<template>
	<div class="container-md">
		<div class="row mt-3">
			<div class="col-md-12">
				<h1>API Keys</h1>
				<div v-for="key_id in api_store.api_key_ids" :key="key_id">
					{{ key_id }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { use_load_store } from '@/stores/load.js'
import { use_api_store } from '@/stores/api.js'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {

		const api_store = use_api_store()
		await api_store.fetch_api_info()

	})

	next()
}

export default {
	name: 'APIView',
	components: {
	},
	props: {
	},
	setup() {

		const api_store = use_api_store()

		return {
			api_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>


</style>
