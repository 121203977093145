
<template>
	<div class="code-display mb-3">
		<v-ace-editor
			v-model:value="content"
			@input="handle_input"
			lang="javascript"
			theme="chrome"
			:options="ace_options" />
	</div>
</template>

<script setup>
</script>

<script>

import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';
import { ref } from 'vue'

import modeJavascriptUrl from 'ace-builds/src-noconflict/mode-javascript?url';
ace.config.setModuleUrl('ace/mode/javascript', modeJavascriptUrl);

import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);

export default {
	name: 'CodeDisplay',
	components: {
		VAceEditor,
	},
	props: {
		value: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		show_gutter: {
			type: Boolean,
			default: true,
		}
	},
	setup() {
		const name= ref("v-ace-editor");
		console.log(name)
	},
	data() {
		return {
			content: this.value,
			ace_options: {
				readOnly: this.disabled,
				highlightActiveLine: false,
				showLineNumbers: true,
				showFoldWidgets: false,
				showGutter: this.show_gutter,
				printMargin: false,
				enableKeyboardAccessibility: false,
				highlightSelectedWord: false,
				maxLines: 20,
				minLines: 1,
				fontSize: "16px",
			},
		}
	},
	computed: {
	},
	methods: {
		handle_input () {
			this.$emit('input', this.content)
		}
	}
}
</script>

<style>
.ace_gutter-active-line {
	background-color: transparent !important;
}
</style>
