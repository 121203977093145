<template>
	<div class="container-fluid">
		<div class="col-md-4 offset-md-4 mt-3" v-if="user_store.sign_up_valid_token">

			<h2 class="text-center">Continue Registration</h2>

			<form @submit.prevent="handle_submit">
				<div class="mb-3">
					<label>Email</label>
					<input type="text" class="form-control" placeholder="Email" required="required" name="email" v-model="user_store.sign_up_email" readonly autocomplete="new-password">
				</div>
				<div class="mb-3">
					<label>Enter Password</label>
					<input type="password" class="form-control" placeholder="Password 1" required="required" name="password" v-model="user_store.sign_up_password1" autocomplete="new-password">
				</div>
				<div class="mb-3">
					<label>Enter Password Again</label>
					<input type="password" class="form-control" placeholder="Password 2" required="required" name="password2" v-model="user_store.sign_up_password2" autocomplete="new-password">
				</div>
				<div class="mb-3">
					<label>Name</label>
					<input type="text" class="form-control" placeholder="Name" required="required" name="name" v-model="user_store.sign_up_name">
				</div>
				<div class="mb-3 row">
					<div v-for="plan_id in billing_store.plan_ids" :key="plan_id" class="col-4">
						<div :class="{'plan-choose': true, active: billing_store.sign_up_plan_id == plan_id}" @click="() => billing_store.sign_up_plan_id = plan_id">
							<div class="plan-choose-title">{{ billing_store.plan_name(plan_id) }}</div>
							<div class="plan-choose-price">{{ billing_store.plan_price(plan_id) }}kr <small>/mo</small></div>
						</div>
					</div>
				</div>
				<div class="mb-3">
					<StripeElements
						v-if="stripe_loaded"
						v-slot="{ elements }"
						ref="elms"
						:stripe-key="stripe_key"
						:instance-options="instance_options"
						:elements-options="elements_options">

						<StripeElement
							type="payment"
							ref="payment"
							:elements="elements"
							:options="payment_options" />
					</StripeElements>
				</div>
				<div class="mb-3">
					<button type="submit" class="btn btn-primary btn-block" :disabled="user_store.is_loading">Continue</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'
import { use_billing_store } from '@/stores/billing.js'

import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ref, onBeforeMount } from 'vue'

async function load_data(to, from, next) {

	const load = use_load_store()
	await load.wrap(async () => {
		const user_store = use_user_store()
		await user_store.validate_verification_token(to.params.token)

		const billing_store = use_billing_store()

		await billing_store.fetch_plans()
	})

	next()
}

export default {
	components: {
		StripeElement,
		StripeElements,
	},
	data() {
		return {
		}
	},
	setup() {

		const user_store = use_user_store()
		const billing_store = use_billing_store()

		const stripe_key = process.env.VUE_APP_STIPE_PK
		const stripe_loaded = ref(false)

		onBeforeMount(() => {
			const stripe_promise = loadStripe(stripe_key)
			stripe_promise.then(() => {
				stripe_loaded.value = true
			})
		})

		const instance_options = ref({
		})
		
		const payment_options = ref({
		})

		const payment = ref()
		const elms = ref()

		return {
			user_store,
			billing_store,
			stripe_key,
			stripe_loaded,
			instance_options,
			payment_options,
			payment,
			elms,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)

	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	computed: {
		elements_options() {
			return {
				mode: 'subscription',
				amount: this.billing_store.plan_price(this.billing_store.sign_up_plan_id) * 100,
				currency: 'sek',
			}
		}
	},
	methods: {
		async handle_submit(evt) {
			const load = use_load_store()
			await load.wrap(async () => {
				const form_data = new FormData(evt.target)
				console.log(Object.fromEntries(form_data))
			})
		}
	}
}
</script>

