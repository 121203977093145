
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { use_column_store } from '@/stores/column.js'
import { use_load_store } from '@/stores/load.js'
import { api } from '@/api.js'

export const use_company_details_store = defineStore({
	id: 'company_details',
	state: () => ({
		id: -1,
		name: "-",

		periods: new Map(),
		years: [],
		column_data: new Map(),

		report_labels: new Map(),
		report_data: new Map(),

		last_year: 0,
		last_period: 0,

		max_years: 15,

		metric_table: 'year',

		company_id: 0,
		workspace_id: 0,

		// graph data
		graph_data: {
			labels: [],
			datasets: [
			]
		},
		graph_options: {
			aspectRatio: 5,
			responsive: true,
			maintainAspectRatio: true,
			animation: {
				duration: 0
			},
			scales: {
				y: {
					beginAtZero: true,
				}
			},
			transitions: {
				zoom: {
					animation: {
						duration: 0
					}
				}
			},
			plugins: {
				zoom: {
					zoom: {
						wheel: {
							enabled: false,
						},
						pinch: {
							enabled: false
						},
						drag: {
							enabled: true
						},
						mode: 'x',
					}
				}
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
		},
	}),
	getters: {
		active_years: (state) => {
			return state.years.slice(Math.max(state.years.length - state.max_years, 0))
		},
		min_date: (state) => {
			var active_years = state.years.slice(Math.max(state.years.length - state.max_years, 0))
			return active_years[0] + "-01-01";
		},
		get_period_metric(state) {
			return (year, period, column_id) => {
				if (!state.periods.has(year)) return "-"
				if (state.periods.get(year)[period] === null) return "-"

				let key = column_id + '-' + year + '-' + period
				if (!state.column_data.has(key)) return "-"
				return state.column_data.get(key);
			}
		},
		has_quarter(state) {
			return (year, period) => {
				if (state.last_year && state.last_period) {
					if (year > state.last_year && period > state.last_period) return false
				}
				return true
			}
		},
		get_report_data(state) {
			return (year, period, label) => {
				if (!state.report_data.has(year)) return "-"

				let data = state.report_data.get(year)[String(period)]

				if (data === undefined) return "-"
				if (data[label] === null) return "-"

				return data[label]
			}
		},
	},
	actions: {
		async fetch_company_details(company_id) {
			const user = use_user_store()
			
			const res = await axios.get(
				api.host + '/company/details/' + company_id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.name = res.data.name
			this.id = res.data.id
			this.periods.clear()
			this.years = []
			for (let year in res.data.periods) {
				this.periods.set(parseInt(year), res.data.periods[year])
				this.years.push(parseInt(year))
			}
			this.last_year = res.data.last_year
			this.last_period = res.data.last_period
		},
		async fetch_column_data(company_id, workspace_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/company/column_data/' + company_id + '/' + workspace_id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.column_data = new Map(res.data.column_data)
		},
		async fetch_report_data(company_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/company/report_data/' + company_id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.report_labels = new Map(Object.entries(res.data.labels))
			this.report_data = new Map(Object.entries(res.data.data).map(e => [parseInt(e[0]), e[1]]))
		},
		async change_graph_column(checked, column_id) {
			const column_store = use_column_store()
			const load_store = use_load_store()

			load_store.show('company_graph')

			await column_store.edit_column_props(column_store.get_column(column_id), {is_graph: checked})
			await this.fetch_graph_data(this.company_id, this.workspace_id)

			load_store.hide('company_graph')

		},
		async fetch_graph_data(company_id, workspace_id) {
			this.company_id = company_id
			this.workspace_id = workspace_id
			const user = use_user_store()

			const res = await axios.get(
				api.host + '/company/graph_data/' + company_id + '/' + workspace_id + '?min_date=' + this.min_date ,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
	}
})
