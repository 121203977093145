<template>
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<div class="container-fluid">
			<a class="navbar-brand" href="/documentation">Documentation</a>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a :class="{'nav-link': true, active: tab == 'default'}" href="/documentation">Custom KPIs</a>
					</li>
					<li class="nav-item">
						<a :class="{'nav-link': true, active: tab == 'code'}" href="/documentation/code">Code Interface</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>

export default {
	name: 'DocumentationMenuView',
	components: {
	},
	props: {
		tab: {
			type: String,
			default: 'default',
		}
	},
	setup() {

		return {
		}
	},
	data() {
		return {
		}
	},
	computed: {
		
	},
	methods: {
	}
}
</script>
