<template>
	<div class="card mt-3">
		<div class="card-body payment-method" v-if="billing_store.payment_method_type(pm_id) == 'card'">
			<div class="card-img">
				<img :src="billing_store.payment_method_card_img(pm_id)" />
			</div>
			<span class="dots">•••• •••• ••••</span><span class="payment-method-name">{{ billing_store.payment_method_last4(pm_id) }}</span>
			<span class="payment-method-expires">{{ billing_store.payment_method_expires(pm_id) }}</span>
			<span v-if="billing_store.payment_method_is_default(pm_id)" class="badge rounded-pill bg-secondary">Default</span>
			<button class="btn btn-link" @click="handle_make_default" v-if="!billing_store.payment_method_is_default(pm_id)">Make Default</button>
			<div class="float-end">
				<button class="btn btn-sm btn-link" @click="handle_delete_payment_method">Delete</button>
			</div>
		</div>
		<div class="card-body payment-method" v-if="billing_store.payment_method_type(pm_id) == 'paypal'">
			<div class="card-img">
				<img :src="billing_store.payment_method_card_img(pm_id)" />
			</div>
			<span class="dots">PayPal</span><span class="payment-method-name">{{ billing_store.payment_method_name(pm_id) }}</span>
			<span v-if="billing_store.payment_method_is_default(pm_id)" class="badge rounded-pill bg-secondary">Default</span>
			<button class="btn btn-link" @click="handle_make_default" v-if="!billing_store.payment_method_is_default(pm_id)">Make Default</button>
			<div class="float-end">
				<button class="btn btn-sm btn-link" @click="handle_delete_payment_method">Delete</button>
			</div>
		</div>
		<div class="card-body payment-method" v-if="billing_store.payment_method_type(pm_id) != 'paypal' && billing_store.payment_method_type(pm_id) != 'card'">
			<span class="dots">Unknown payment type</span>
			<div class="float-end">
				<button class="btn btn-sm btn-link" @click="handle_delete_payment_method">Delete</button>
			</div>
		</div>
	</div>
</template>

<script>

import { use_billing_store } from '@/stores/billing.js'
import { use_load_store } from '@/stores/load.js'

export default {
	components: {
	},
	props: {
		pm_id: {
			type: Number,
		}
	},
	setup() {

		const billing_store = use_billing_store()

		return {
			billing_store,
		}
	},
	computed: {
	},
	methods: {
		async handle_delete_payment_method() {
			const load = use_load_store()
			await load.wrap(async () => {
				const billing_store = use_billing_store()
				await billing_store.delete_payment_method(this.pm_id)
			})
		},
		async handle_make_default() {
			const load = use_load_store()
			await load.wrap(async () => {
				const billing_store = use_billing_store()
				await billing_store.make_default(this.pm_id)
			})
		}
	}
}
</script>
<style scoped>
.payment-method {
	

}

.payment-method > * {
	vertical-align: middle;
	margin-left: 10px;
}

.payment-method-name {
	margin-left: 5px;
}

.card-img {
	display: inline;
	margin-left: 0;
}

.card-img img {
	height: 30px;
	border: 1px solid #ccc;
	border-radius: 6px;
}

.dots {
}
</style>
