
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_watchlist_store = defineStore({
	id: 'watchlist',
	state: () => ({

		id: -1,
		name: '',
		date_begin: '',
		comment: '',

		shares: new Map(),
		share_ids: [],

		search_results: new Map(),
		search_result_ids: [],

		share_metrics: new Map(),

		graph_data: {
			labels: [],
			datasets: [
			]
		},
		graph_options: {
			aspectRatio: 5,
			responsive: true,
			maintainAspectRatio: true,
			animation: {
				duration: 0
			},
			scales: {
				y: {
					beginAtZero: true,
				}
			},
			transitions: {
				zoom: {
					animation: {
						duration: 0
					}
				}
			},
			plugins: {
				zoom: {
					zoom: {
						wheel: {
							enabled: false,
						},
						pinch: {
							enabled: false
						},
						drag: {
							enabled: true
						},
						mode: 'x',
					}
				}
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
		},

	}),
	getters: {
		search_result_name: (state) => {
			return (id) => {
				if (!state.search_results.has(id)) return "-"
				return state.search_results.get(id).company
			}
		},
		search_result_delisted: (state) => {
			return (id) => {
				if (!state.search_results.has(id)) return ""
				var delisted = state.search_results.get(id).delist_date
				if (delisted) {
					return '(delisted ' + delisted + ')'
				}
			}
		},
		share_company: (state) => {
			return (id) => {
				if (!state.shares.has(id)) return "-"
				return state.shares.get(id).company
			}
		},
		share_name: (state) => {
			return (id) => {
				if (!state.shares.has(id)) return "-"
				return state.shares.get(id).share
			}
		},
		num_shares: (state) => {
			return (id) => {
				if (!state.shares.has(id)) return "-"
				return state.shares.get(id).num_shares
			}
		},
		message: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).message
			}
		},
		buy_price: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).buy_price
			}
		},
		last_price: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).last_price
			}
		},
		buy_value: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).buy_value
			}
		},
		last_value: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).last_value
			}
		},
		dividends: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).dividends
			}
		},
		growth: (state) => {
			return (id) => {
				if (!state.share_metrics.has(id)) return "-"
				return state.share_metrics.get(id).growth
			}
		},
		buy_value_sum: (state) => {
			return state.share_ids.map((id) => {
				return state.share_metrics.get(id).buy_value
			}).reduce((a, b) => a + b, 0)
		},
		last_value_sum: (state) => {
			return state.share_ids.map((id) => {
				return state.share_metrics.get(id).last_value
			}).reduce((a, b) => a + b, 0)
		},
		dividends_sum: (state) => {
			return state.share_ids.map((id) => {
				return state.share_metrics.get(id).dividends
			}).reduce((a, b) => a + b, 0)
		},
		growth_sum: (state) => {
			if (state.buy_value_sum == 0) return 1
			return (state.last_value_sum + state.dividends_sum) / state.buy_value_sum
		},
	},
	actions: {
		async fetch_watchlist(watchlist_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/watchlist/get/' + watchlist_id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.name = res.data.watchlist.name
			this.id = res.data.watchlist.id
			this.date_begin = res.data.watchlist.date_begin
			this.comment = res.data.watchlist.comment
			this.share_ids = []
			for (let share of res.data.shares) {
				this.shares.set(share.id, share)
				this.share_ids.push(share.id)
			}
			this.metrics = res.data.metrics
			for (let share_id in res.data.share_metrics) {
				this.share_metrics.set(parseInt(share_id), res.data.share_metrics[share_id])
			}
			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
		async search_for(search_term) {
			if (search_term.trim().length == 0) {
				this.search_result_ids = []
			} else {
				const user = use_user_store()
				const res = await axios.post(
					api.host + '/watchlist/search',
					{
						search_term: search_term,
					},
					{
						headers: { Authorization: user.session_id }
					}
				)

				this.search_result_ids = []
				for (let share of res.data.shares) {
					this.search_results.set(share.id, share)
					this.search_result_ids.push(share.id)
				}
			}
		},
		async add(share_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/watchlist/add_share/' + this.id,
				{
					share_id: share_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.search_result_ids = []
			this.name = res.data.watchlist.name
			this.id = res.data.watchlist.id
			this.date_begin = res.data.watchlist.date_begin
			this.comment = res.data.watchlist.comment
			this.share_ids = []
			for (let share of res.data.shares) {
				this.shares.set(share.id, share)
				this.share_ids.push(share.id)
			}
			this.metrics = res.data.metrics
			for (let share_id in res.data.share_metrics) {
				this.share_metrics.set(parseInt(share_id), res.data.share_metrics[share_id])
			}
			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
		async edit_watchlist(name, date_begin, comment, num_shares, amount) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/watchlist/edit/' + this.id,
				{
					name: name,
					date_begin: date_begin,
					comment: comment,
					num_shares: num_shares,
					amount: amount,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.name = res.data.watchlist.name
			this.id = res.data.watchlist.id
			this.date_begin = res.data.watchlist.date_begin
			this.comment = res.data.watchlist.comment
			this.share_ids = []
			for (let share of res.data.shares) {
				this.shares.set(share.id, share)
				this.share_ids.push(share.id)
			}
			this.metrics = res.data.metrics
			for (let share_id in res.data.share_metrics) {
				this.share_metrics.set(parseInt(share_id), res.data.share_metrics[share_id])
			}
			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
		async remove(share_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/watchlist/remove_share/' + this.id,
				{
					share_id: share_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.name = res.data.watchlist.name
			this.id = res.data.watchlist.id
			this.date_begin = res.data.watchlist.date_begin
			this.comment = res.data.watchlist.comment
			this.share_ids = []
			for (let share of res.data.shares) {
				this.shares.set(share.id, share)
				this.share_ids.push(share.id)
			}
			this.metrics = res.data.metrics
			for (let share_id in res.data.share_metrics) {
				this.share_metrics.set(parseInt(share_id), res.data.share_metrics[share_id])
			}
			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
		async delete() {
			const user = use_user_store()
			await axios.post(
				api.host + '/watchlist/delete/' + this.id,
				{
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
		},
	}
})
