
import { defineStore } from 'pinia'
import axios from "axios";
import { api } from '@/api.js'
import { use_billing_store } from '@/stores/billing.js'

export const use_user_store = defineStore({
	id: 'user',
	state: () => ({
		login_email: '',
		login_password: '',
		session_id: localStorage.getItem('session_id'),
		cookie_id: '',
		user_name: '',
		customer_name: '',
		permissions: {},

		reset_email: '',
		reset_email_step: 1,
		reset_valid_token: false,
		reset_password1: '',
		reset_password2: '',
		reset_token: '',
	}),
	getters: {
		fetch_cookie_url: (state) => api.host + '/user/pixel/' + state.cookie_id,
		has_permission: (state) => {
			return (perm) => {
				return state.permissions[perm] || false
			}
		}
	},
	actions: {
		async fetch_user(with_session_id = null) {
			try {
				const res = await axios.get(
					api.host + '/user/info',
					{
						headers: { Authorization: with_session_id !== null ? with_session_id : this.session_id }
					}
				)

				this.user_name = res.data.user.name
				this.customer_name = res.data.customer.name
				this.permissions = res.data.customer.permissions

			} catch (error) {
				if (error.response && error.response.status == 402) {
					this.$router.push('/billing')
					return
				}
				throw error;
			}
		},
		async log_in() {
			const res = await axios.post(
				api.host + '/user/login',
				{
					email: this.login_email,
					password: this.login_password,
				},
			)

			let session_id = res.data.session_id
			let cookie_id = res.data.cookie_id

			window.localStorage.setItem('session_id', session_id);

			this.session_id = session_id
			this.cookie_id = cookie_id
			this.$router.push('/')
		},
		async log_out() {
			await window.localStorage.removeItem('session_id')
			this.session_id = null
			document.location.href = "/"
		},
		async reset_password() {
			const res = await axios.post(
				api.host + '/user/reset',
				{
					email: this.reset_email,
				}
			)

			// scrub sensitive data
			this.reset_email = ""

			if (res.data.status == "success") {
				this.reset_email_step = 2
			} else {
				this.reset_error_message = res.data.message
			}
		},
		async reset_password_with_token() {
			const res = await axios.post(
				api.host + '/user/reset_with_token',
				{
					email: this.reset_email,
					token: this.reset_token,
					password1: this.reset_password1,
					password2: this.reset_password2,
				}
			)

			if (res.data.status == "success") {
				this.$router.push('/login/reset_success')
			} else {
				throw new Error(res.data.message)
			}
		},
		async validate_token(token) {
			const res = await axios.get(
				api.host + '/user/reset/' + token,
				{
				}
			)

			if (res.data.status == "success") {
				this.reset_valid_token = true
				this.reset_token = token
				this.reset_email = res.data.message.email
			} else {
				throw new Error('The token has expired')
			}
		},
		async validate_verification_token(token) {
			const res = await axios.get(
				api.host + '/user/verify/' + token,
				{
				}
			)

			if (res.data.status == "success") {
				let session_id = res.data.session_id
				let cookie_id = res.data.cookie_id

				window.localStorage.setItem('session_id', session_id);

				this.session_id = session_id
				this.cookie_id = cookie_id
				document.location.href = '/billing'
			} else {
				throw new Error('The token has expired')
			}
		},
		async sign_up(cb1, cb2) {

			const res = await axios.post(
				api.host + '/user/nonce',
				{
					email: this.sign_up_email,
					hash: await cb1(this.sign_up_email),
				}
			)

			const nonce = res.data.nonce
			const billing_store = use_billing_store()

			const res2 = await axios.post(
				api.host + '/user/register',
				{
					email: this.sign_up_email,
					plan_id: billing_store.sign_up_plan_id,
					nonce: nonce,
					hash: await cb2(this.sign_up_email, nonce)
				}
			)

			this.sign_up_message = res2.data.message

		},
	}
})
