<template>
	<div class="container-md">
		<div class="row">
			<div class="alert alert-danger mt-3" v-if="company_details_store.is_error">{{ company_details_store.error_message }}</div>
			<div class="col-12 mt-3" v-if="!company_details_store.is_error">
				<h1>{{ company_details_store.name }}</h1>
				<WorkspacePickerSimple :company_id="company_id" />
				<div class="graph-container">
					<Line :data="company_details_store.graph_data" :options="company_details_store.graph_options" />
					<LoadIndicator type="company_graph" />
				</div>
				<MetricTableChooser />
				<MetricTableYearQuarter v-if="company_details_store.metric_table == 'year_quarter'" />
				<MetricTableYear v-if="company_details_store.metric_table == 'year'" />
				<MetricTableQuarter v-if="company_details_store.metric_table == 'quarter'" />

				<ReportDataTableYear v-if="company_details_store.metric_table == 'year'" />
				<ReportDataTableQuarter v-if="company_details_store.metric_table == 'quarter'" />

			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

import MetricTableChooser from '@/components/MetricTableChooser.vue'
import MetricTableYearQuarter from '@/components/MetricTableYearQuarter.vue'
import MetricTableYear from '@/components/MetricTableYear.vue'
import MetricTableQuarter from '@/components/MetricTableQuarter.vue'
import ReportDataTableYear from '@/components/ReportDataTableYear.vue'
import ReportDataTableQuarter from '@/components/ReportDataTableQuarter.vue'
import LoadIndicator from '@/components/LoadIndicator.vue'
import WorkspacePickerSimple from '@/components/WorkspacePickerSimple.vue'

import { use_load_store } from '@/stores/load.js'
import { use_company_details_store } from '@/stores/company_details'
import { use_column_store } from '@/stores/column.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_metric_store } from '@/stores/metric.js'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {
		const company_details_store = use_company_details_store()
		await company_details_store.fetch_company_details(to.params.company_id)
		await company_details_store.fetch_report_data(to.params.company_id)

		to.meta.title = company_details_store.name

		const metric_store = use_metric_store()
		await metric_store.fetch_categories()
		await metric_store.fetch_metrics()

		const workspace_store = use_workspace_store()
		await workspace_store.fetch_workspaces(to.params.workspace_id)

		const column_store = use_column_store()
		await column_store.fetch_columns(false)
	})
	load.wrap(async () => {
		const company_details_store = use_company_details_store()
		await company_details_store.fetch_column_data(to.params.company_id, to.params.workspace_id)
		await company_details_store.fetch_graph_data(to.params.company_id, to.params.workspace_id)
	}, 'company_graph')
	next()
}

export default {
	name: 'CompanyView',
	components: {
		Line,
		MetricTableChooser,
		MetricTableYearQuarter,
		MetricTableYear,
		MetricTableQuarter,
		ReportDataTableYear,
		ReportDataTableQuarter,
		LoadIndicator,
		WorkspacePickerSimple,
	},
	props: {
		company_id: {
			type: Number,
		},
		workspace_id: {
			type: Number,
		},
	},
	setup() {

		const company_details_store = use_company_details_store()
		const column_store = use_column_store()

		return {
			company_details_store,
			column_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
			
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>
table {
	font-size: 0.6rem;
}

.color-box {
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 5px;
}

.graph-container {
	position: relative;
}
</style>
