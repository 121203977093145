<template>
	<div class="workspace-picker">
		<div class="row g-3">
			<div class="col-auto">
				<select class="form-select" :value="workspace_store.active_workspace_id" @change="workspace_store.change_workspace_simple(company_id, $event.target.value)">
					<option v-for="workspace_id in workspace_store.workspace_ids" :key="workspace_id" :value="workspace_id">Workspace: {{ workspace_store.get_workspace_name(workspace_id) }}</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>

import { use_workspace_store } from '@/stores/workspace.js'
import { use_modal_store } from '@/stores/modal.js'

export default {
	name: 'WorkspacePicker',
	components: {
	},
	props: {
		company_id: {
			type: Number
		},
	},
	setup() {

		let workspace_store = use_workspace_store()
		const modal_store = use_modal_store()

		return {
			workspace_store,
			modal_store,
		}
	},
	data() {
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>
.workspace-picker {
	margin-bottom: 1rem;
	margin-top: 1rem;
}
</style>
