<template>
	<div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<Chart v-if="code_store.has_graph_data" :data_sets="code_store.graph_data" :chart_options="{}" :type="'line'"/>

					<LoadIndicator type="code_output" />
				</div>
			</div>
			<div class="row">
				<div class="col-8 mt-3">

					<CodeEditor v-model="code_store.body" :disabled="code_store.read_only" max_lines="10000"></CodeEditor>
					<input type="text" class="form-control mt-3" v-model="code_store.name" :disabled="code_store.read_only"/>
					<CodeToolbar />

				</div>
				<div class="col-4 mt-3">
					<button class="btn btn-primary" @click="handle_save" :disabled="code_store.read_only">Run ({{ save_info }}) <i class="bi bi-arrow-right-circle"></i></button>
					<button class="btn btn-info float-end" @click="handle_clone" :disabled="code_store.read_only">Clone <i class="bi bi-arrow-right-circle"></i></button>
					<i v-if="code_store.read_only" class="ms-3">Read Only</i>
					<div class="mt-3" style="position: sticky; top: 10px;">
						<div class="alert alert-danger" v-if="code_store.has_error">{{code_store.error}}</div>
						<pre class="mt-3 code-output">{{code_store.output}}</pre>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import Chart from '@/components/Chart.vue'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

import { use_load_store } from '@/stores/load.js'
import { use_code_store } from '@/stores/code.js'
import LoadIndicator from '@/components/LoadIndicator.vue'
import CodeEditor from '@/components/CodeEditor.vue'
import CodeToolbar from '@/components/CodeToolbar.vue'

async function load_data(to, from, next) {
	const load = use_load_store()
	await load.wrap(async () => {
		const code_store = use_code_store()
		code_store.fetch_code(parseInt(to.params.code_id))
	})
	next()
}

export default {
	name: 'CodeView',
	components: {
		CodeEditor,
		LoadIndicator,
		CodeToolbar,
		Chart,
	},
	props: {
		code_id: {
			type: Number,
			required: true,
		}
	},
	mounted() {
		window.addEventListener('keydown', this.handle_keydown);
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.handle_keydown);
	},
	setup() {

		const code_store = use_code_store()

		return {
			code_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},

	computed: {
		save_info() {
			if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
				return '⌘+s'
			} else {
				return 'ctrl+s'
			}
		}
	},

	methods: {
		async handle_save() {
			const load = use_load_store()
			await load.wrap(async () => {
				const code_store = use_code_store()
				await code_store.save()
			}, 'code_output')
		},
		async handle_clone() {
			const load = use_load_store()
			await load.wrap(async () => {
				const code_store = use_code_store()
				await code_store.clone()
			}, 'code_output')
		},
		async handle_keydown(event) {
			if (event.which == 83 && (event.metaKey || event.ctrlKey)) {
				event.preventDefault()
				await this.handle_save()
			}
		},
	}
}
</script>
<style scoped>
.code-output {
	padding: 10px;
	background: #eee;
	max-height: 100vh;
}
</style>
