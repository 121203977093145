<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @click.self="close_modal" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Edit Strategy</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<form @submit.prevent="handle_submit">
							<div class="mb-3">
								<label class="form-label">Strategy Name</label>
								<input type="text" class="form-control" v-model="form.name" />
							</div>
							<div class="mb-3">
								<label class="form-label">Workspace</label>
								<select class="form-control" v-model="form.workspace_id" @change="handle_workspace_change">
									<option :value="-1">Select Workspace</option>
									<option v-for="workspace_id in workspace_store.workspace_ids" :key="workspace_id" :value="workspace_id">{{ workspace_store.get_workspace_name(workspace_id) }}</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Order by Column</label>
								<select class="form-control" v-model="form.sort_by_column_id" :disabled="form.workspace_id <= 0">
									<option :value="-1">Select Column</option>
									<option v-for="column_id in column_store.active_columns" :key="column_id" :value="column_id">{{ column_store.get_column_name(column_id) }}</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Order Direction</label>
								<select class="form-control" v-model="form.sort_dir">
									<option value="desc">Descending</option>
									<option value="asc">Ascending</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Portfolio Size</label>
								<input type="text" class="form-control" v-model="form.portfolio_size" />
							</div>
							<div class="mb-3">
								<label class="form-label">Date Begin</label>
								<input type="text" class="form-control" v-model="form.date_begin" />
							</div>
							<div class="mb-3">
								<label class="form-label">Date End</label>
								<input type="text" class="form-control" v-model="form.date_end" />
							</div>
							<div class="mb-3">
								<label class="form-label">Rebalance after (days)</label>
								<input type="text" class="form-control" v-model="form.rebalance_after" />
							</div>
							<div class="mb-3">
								<div class="alert alert-danger" v-if="strategy_store.is_error">
									{{ strategy_store.error_message }}
								</div>
							</div>
							<div>
								<button type="submit" class="btn btn-primary" :disabled="strategy_store.is_loading">
									{{ strategy_store.is_loading ? "Saving..." : "Save" }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_strategy_store } from '@/stores/strategy.js'
import { use_load_store } from '@/stores/load.js'

function default_data(store) {
	return {
		name: store.name,
		workspace_id: store.workspace_id,
		sort_by_column_id: store.column_id,
		sort_dir: store.column_order,
		portfolio_size: store.portfolio_size,
		date_begin: store.date_begin,
		date_end: store.date_end,
		rebalance_after: store.rebalance_after,
	}
}

export default {
	name: 'EditStrategyModal',
	components: {
	},
	props: {
		strategy_id: {
			type: Number,
			required: true,
		},
	},
	setup() {

		const modal_store = use_modal_store()
		const column_store = use_column_store()
		const strategy_store = use_strategy_store()
		const workspace_store = use_workspace_store()

		return {
			modal_store,
			column_store,
			strategy_store,
			workspace_store,
		}
	},
	data() {
		return {
			form: default_data(this.strategy_store)
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "edit_strategy"
		},
	},
	methods: {
		reset_form() {
			this.form = default_data(this.strategy_store)
		},
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.strategy_store.edit_strategy(this.strategy_id, this.form)
				if (!this.strategy_store.is_error) {
					this.$router.go()
				}
			})
		},
		async handle_workspace_change() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.column_store.fetch_columns_for_workspace(this.form.workspace_id, false)
			})
		},
	},
	watch: {
		strategy_id() {
			this.reset_form()
		},
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
