<template>
	<div class="lw-chart" ref="chart_container"></div>
	<div class="lw-sub-chart" ref="sub_chart_container" v-show="has_sub_chart"></div>
</template>
<script>

import { createChart } from 'lightweight-charts';

export default {
	name: 'ChartAsd',
	components: {
	},
	props: {
		type: {
			type: String,
			default: 'line',
		},
		data_sets: {
			type: Array,
			required: true,
		},
		autosize: {
			default: true,
			type: Boolean,
		},
		chart_options: {
			type: Object,
		},
		series_options: {
			type: Object,
		},
		time_scale_options: {
			type: Object,
		},
		price_scale_options: {
			type: Object,
		},
	},
	setup() {

		let main_chart;
		let sub_chart;

		return {
			main_chart,
			sub_chart,
		}
	},
	mounted() {
		this.build_charts()
	},
	unmounted() {
		this.reset_charts()
	},
	data() {
		return {
		}
	},
	computed: {
		has_sub_chart() {
			let has_sub_chart = false
			for (let data_set of this.data_sets) {
				if (data_set.pane == "sub") has_sub_chart = true
			}
			return has_sub_chart
		}
	},
	methods: {
		get_chart_constructor_name(type) {
			return `add${type.charAt(0).toUpperCase() + type.slice(1)}Series`;
		},
		reset_charts() {
			if (this.main_chart) {
				this.main_chart.remove();
				this.main_chart = null;
			}
			if (this.sub_chart) {
				this.sub_chart.remove();
				this.sub_chart = null;
			}
		},
		build_charts() {
			this.main_chart = createChart(this.$refs.chart_container, this.chart_options);
			if (this.has_sub_chart) {
				this.sub_chart = createChart(this.$refs.sub_chart_container, this.chart_options);
			}

			for (let data_set of this.data_sets) {
				const series_constructor = this.get_chart_constructor_name(data_set.type);
				let serie;
				if (data_set.pane == "sub") {
					serie = this.sub_chart[series_constructor](data_set.options);
				} else {
					serie = this.main_chart[series_constructor](data_set.options);
				}
				try {
					serie.setData(data_set.data)
					serie.setMarkers(data_set.marks)
				} catch (err) {
					console.log(err)
				}
			}

			this.main_chart.timeScale().fitContent();

			if (this.has_sub_chart) {
				this.main_chart.timeScale().subscribeVisibleLogicalRangeChange((visibleTimeRange) => {
					this.sub_chart.timeScale().setVisibleLogicalRange(visibleTimeRange);
				});
				this.sub_chart.timeScale().subscribeVisibleLogicalRangeChange((visibleTimeRange) => {
					this.main_chart.timeScale().setVisibleLogicalRange(visibleTimeRange);
				});
			}
		}
	},
	watch: {
		data_sets() {
			this.reset_charts()
			this.build_charts()
		},
	}
}

</script>
<style scoped>
.lw-chart {
	height: 400px;
}
.lw-sub-chart {
	height: 300px;
}
</style>
