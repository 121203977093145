
<template>
	<div :class="{'code-editor': true, 'inactive': inactive, 'disabled': disabled}" @click="handle_activate">
		<v-ace-editor
			v-model:value="content"
			lang="javascript"
			theme="chrome"
			:options="ace_options" />
	</div>
</template>

<script setup>
</script>

<script>

import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';

import modeJsonUrl from 'ace-builds/src-noconflict/mode-javascript?url';
ace.config.setModuleUrl('ace/mode/javascript', modeJsonUrl);

import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);

export default {
	name: 'CodeEditor',
	components: {
		VAceEditor,
	},
	props: {
		modelValue: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		max_lines: {
			type: Number,
			default: 50,
		}
	},
	setup() {
	},
	data() {
		return {
			inactive: true,
			ace_options: {
				readOnly: this.disabled,
				highlightActiveLine: false,
				showLineNumbers: true,
				showFoldWidgets: true,
				showGutter: true,
				highlightSelectedWord: true,
				printMargin: false,
				maxLines: this.max_lines,
				minLines: 5,
				background: "transparent",
			},
		}
	},
	computed: {
		content: {
			get() {
				return this.modelValue
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value)
			}
		}
	},
	methods: {
		handle_activate() {
			this.inactive = false
		}
	}
}
</script>

<style scoped>

.code-editor {
	border: 1px solid rgb(222, 226, 230);
	border-radius: 0.375rem;
	overflow: hidden;
	padding: .75rem .475rem;
}

.code-editor.disabled {
	background: rgb(233, 236, 239);
}

</style>
