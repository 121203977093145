<template>
	<div class="container-md mt-3">
		<div class="mb-3 row">
			<div class="col-md-6 offset-md-3">
				<h1>Invoices</h1>
				<table class="table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Status</th>
							<th>PDF</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="invoice_id in billing_store.invoice_ids" :key="invoice_id">
							<td>{{ billing_store.invoice_name(invoice_id) }}</td>
							<td>{{ billing_store.invoice_status(invoice_id) }}</td>
							<td><a :href="billing_store.invoice_pdf(invoice_id)" target="_blank">PDF Invoice</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

import { use_load_store } from '@/stores/load.js'
import { use_billing_store } from '@/stores/billing.js'

async function load_data(to, from, next) {
	
	const load = use_load_store()
	await load.wrap(async () => {
		const billing_store = use_billing_store()
		await billing_store.fetch_invoices()
		await billing_store.fetch_user()
	})

	next()
}

export default {
	components: {
	},
	props: {
	},
	setup() {

		const billing_store = use_billing_store()

		return {
			billing_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)

	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	computed: {
	},
	methods: {
		
	}
}
</script>
