
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_support_store = defineStore({
	id: 'support',
	state: () => ({

		support_email: '',

	}),
	getters: {
	},
	actions: {
		async fetch_support_info() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/support/info',
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.support_email = res.data.support_email

		},
	}
})
