<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Add Payment Method</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<PaymentAdditional />
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import PaymentAdditional from '@/components/PaymentAdditional.vue'

import { use_modal_store } from '@/stores/modal.js'

export default {
	name: 'AddMetricModal',
	components: {
		PaymentAdditional,
	},
	props: {
	},
	setup() {

		let modal_store = use_modal_store()

		return {
			modal_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "add_payment_method"
		}
	},
	methods: {
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
