
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_config_store = defineStore({
	id: 'config',
	state: () => ({
		// data loading parameters
		is_loading: false,
		is_error: false,
		error_message: '',
	}),
	getters: {
		
	},
	actions: {
		async load_config() {
			const user = use_user_store()
			await axios.get(
				api.host + '/config/get',
				{
					headers: { Authorization: user.session_id }
				}
			)
		},
		async set_config(name, value) {
			const user = use_user_store()
			await axios.post(
				api.host + '/config/set',
				{
					config_name: name,
					config_value: value,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
		},
	}
})
