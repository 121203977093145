<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add Watchlist</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.close_modal"></button>
			</div>
			<div class="modal-body">
				<form @submit.prevent="handle_submit">
					<div>
						<label>Name</label>
						<input type="text" class="form-control" v-model="form.name" />
					</div>
					<div class="mt-3">
						<label>Date Begin</label>
						<input type="text" class="form-control" v-model="form.date_begin" />
					</div>
					<div class="mt-3">
						<button type="submit" class="btn btn-primary float-end">Add</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_load_store } from '@/stores/load.js'
import { use_watchlist_list_store } from '@/stores/watchlist_list.js'

function default_data() {
	return {
		name: "",
		date_begin: "",
	}
}

export default {
	name: 'AddWatchlistModal',
	components: {
	},
	props: {
		
	},
	setup() {

		const modal_store = use_modal_store()
		const watchlist_list_store = use_watchlist_list_store()

		return {
			modal_store,
			watchlist_list_store,
		}
	},
	data() {
		return {
			form: default_data()
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "add_watchlist"
		}
	},
	methods: {
		close_modal() {
			this.form = default_data()
			this.modal_store.close_modal()
		},
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				const new_id = await this.watchlist_list_store.add_watchlist(this.form.name, this.form.date_begin)
				document.location.href = '/watchlist/' + new_id
			})
		},
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
