
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { use_column_store } from '@/stores/column.js'
import { api } from '@/api.js'

export const use_company_store = defineStore({
	id: 'company',
	state: () => ({

		ids: [],
		company_by_id: new Map(),
		selected_company_id: false,

	}),
	getters: {
		company_name_by_id: (state) => {
			return (company_id) => {
				if (!state.company_by_id.has(company_id)) return '-'
				return state.company_by_id.get(company_id).name
			}
		},
		company_selected: (state) => {
			return (company_id) => {
				return state.selected_company_id == company_id
			}
		}
	},
	actions: {
		async fetch_companies(base_date = null) {
			const user = use_user_store()
			const column_store = use_column_store()

			var date = (base_date ? base_date : column_store.main_date.toISOString().substring(0, 10))
			
			const res = await axios.get(
				api.host + '/company/listed?date=' + date,
				{
					headers: { Authorization: user.session_id }
				}
			)
				
			this.company_by_id.clear()
			this.ids = []
			for (var company of res.data.companies) {
				this.company_by_id.set(company.id, company)
				this.ids.push(company.id)
			}

			column_store.implement_column_order()
		},
		async fetch_all_companies() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/company/all',
				{
					headers: { Authorization: user.session_id }
				}
			)
			
			for (var company of res.data.companies) {
				this.company_by_id.set(company.id, company)
			}
		},
		order_by(perm) {
			var ids = this.ids
			ids.sort(function (a, b) {
				return (perm.get(a) < perm.get(b)) ? -1 : 1
			})
		},
		order_by_company_name(dir) {
			var dir_val = dir == "desc" ? 1 : -1;
			var ids = this.ids
			var that = this
			ids.sort(function (a, b) {
				return (that.company_name_by_id(a) < that.company_name_by_id(b)) ? dir_val : -dir_val;
			})
		},
		select_company(company_id) {
			this.selected_company_id = company_id
		},
	}
})
