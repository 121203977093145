<template>
	<div class="row">
		<div class="col-12">
			<h3>{{ metric.name }}</h3>
			<p>{{ metric.description }}</p>
			<p>{{ metric.unit }} {{ metric.currency }} </p>
		</div>
		<div class="col-4">
			<label>Column Name</label>
			<input type="text" class="form-control mb-3" v-model="editable_name" v-if="this.metric.allow_edit" />
			<input type="text" class="form-control mb-3" :value="make_column_name" v-if="!this.metric.allow_edit" disabled="true" />
		</div>
		<div class="col-4">
			<label>Column Type</label>
			<select class="form-select mb-3" v-model="editable_type" :disabled="!this.metric.allow_edit">
				<option value="value">Value</option>
				<option value="rank">Rank</option>
				<option value="percent">Percent</option>
				<option value="info">Info</option>
			</select>
		</div>
		<div class="col-4">
			<label>Variable Name</label>
			<input type="text" class="form-control mb-3" :value="calculated_var_name" :disabled="true"/>
		</div>
		<div class="col-12">
			<CodeEditor v-if="this.metric.allow_edit" v-model="editable_script"></CodeEditor>
			<CodeEditor v-if="!this.metric.allow_edit" v-model="non_editable_script" disabled="disabled"></CodeEditor>
		</div>
		<div class="col-12 mt-3" v-if="false">
			<div class="row">
				<div class="col-auto" v-if="metric_store.numerator_show(metric.id)">
					<div class="btn-group" role="group">
						<button type="submit" :class="{btn: true, 'btn-primary': editable_numerator == 'per_share', 'btn-outline-primary': editable_numerator != 'per_share'}" name="numerator_type" @click="set_numerator('per_share')">Per Share</button>
						<button type="submit" :class="{btn: true, 'btn-primary': editable_numerator == 'total', 'btn-outline-primary': editable_numerator != 'total'}" name="numerator_type" @click="set_numerator('total')">Total</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div class="row">
				<div class="col-auto" v-if="metric_store.denominator_show(metric.id)">
					<label>Divide by</label>
					<select class="form-select denominator-select" :value="this.editable_denominator_id" @change="set_denominator($event.target.value)">
						<option :value="null"></option>
						<optgroup v-for="category in metric_store.categories_with_code_metric" :key="category.id" :label="category.name">
							<option v-for="metric in metric_store.code_metrics_by_category(category)" :key="metric.id" :value="metric.id">{{ metric.name }}</option>
						</optgroup>
					</select>
					<div v-if="this.editable_denominator_id">
						{{ denominator.name }} in {{ denominator.unit }} {{ denominator.currency }}
					</div>
					
				</div>
				<div class="col-auto" v-if="metric_store.report_type_show(metric.id)">
					<label>Report type</label>
					<div>
						<div class="btn-group" role="group">
							<button type="submit" :class="{btn: true, 'btn-primary': editable_report_type == 'ttm', 'btn-outline-primary': editable_report_type != 'ttm'}" name="report_type" @click="set_report_type('ttm')">Trailing 12 months</button>
							<button type="submit" :class="{btn: true, 'btn-primary': editable_report_type == 'annual', 'btn-outline-primary': editable_report_type != 'annual'}" name="report_type" @click="set_report_type('annual')">Annual report</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<button type="button" class="btn btn-warning mt-3" @click="handle_delete_metric" v-if="metric.is_own && !metric_store.is_editing_column">Delete Metric</button>
			<button type="button" class="btn btn-primary float-end mt-3" @click="handle_add_column" v-if="!can_save && !metric_store.is_editing_column">Add Metric</button>
			<button type="button" class="btn btn-primary float-end mt-3" @click="handle_edit_column" v-if="!can_save && metric_store.is_editing_column">Save</button>
			<button type="button" class="btn btn-primary float-end mt-3 me-3" @click="handle_save_metric" v-if="can_save && !metric_store.is_editing_column">Save and Add</button>
			<button type="button" class="btn btn-primary float-end mt-3 me-3" @click="handle_save_metric" v-if="can_save && metric_store.is_editing_column">Save</button>
			<button type="button" class="btn btn-warning float-end mt-3 me-3" @click="handle_remove_column" v-if="metric_store.is_editing_column">Remove Column</button>
		</div>
	</div>
</template>

<script>

import { use_metric_store } from '@/stores/metric.js'
import { use_column_store } from '@/stores/column.js'
import { use_load_store } from '@/stores/load.js'
import { use_modal_store } from '@/stores/modal.js'

import CodeEditor from '@/components/CodeEditor.vue'

export default {
	name: "MetricSettings",
	components: {
		CodeEditor,
	},
	props: {
		metric: {
			type: Object
		},
		columnid: {
			type: Number 
		},
		base_date: {
			type: String,
			default: null,
		},
	},
	setup() {

		const metric_store = use_metric_store()
		const column_store = use_column_store()
		const load_store = use_load_store()
		const modal_store = use_modal_store()

		return {
			metric_store,
			column_store,
			load_store,
			modal_store,
		}
	},
	data() {

		const column_scope = this.column_store.get_column_scope(this.columnid)
		const numerator = this.column_store.get_column_numerator(this.columnid)
		const report_type = this.column_store.get_column_report_type(this.columnid)
		const denominator_id = this.column_store.get_column_denominator_id(this.columnid)

		return {
			editable_name: this.metric.name,
			editable_var_name: this.metric.var_name || "",
			editable_type: this.metric.value_type || "value",
			editable_scope: column_scope ? column_scope : this.metric.default_scope,
			original_script: this.metric.script,
			editable_script: this.metric.script,
			editable_numerator: numerator ? numerator : this.metric.numerator_default,
			editable_report_type: report_type ? report_type : this.metric.report_type_default,
			editable_denominator_id: denominator_id ? denominator_id : null,
		}
	},
	methods: {
		async handle_add_column() {
			const ok = await this.load_store.wrap(async () => {
				await this.column_store.add_column(this.metric, this.variables, this.base_date)
			})
			if (ok) {
				this.$emit('changed-column')
				this.modal_store.close_modal()
			}
		},
		async handle_edit_column() {
			const ok = await this.load_store.wrap(async () => {
				await this.column_store.edit_column(this.metric_store.edited_column_id, this.metric, this.variables, this.base_date)
			})
			if (ok) {
				this.$emit('changed-column')
				this.modal_store.close_modal()
			}
		},
		async handle_save_metric() {
			await this.load_store.wrap(async () => {
				await this.column_store.add_column(this.metric, this.variables, this.base_date)
				await this.metric_store.save_metric(this.metric, this.editable_script, this.editable_name, this.editable_var_name, this.editable_type, this.base_date)
			})
		},
		async handle_delete_metric() {
			await this.load_store.wrap(async () => {
				await this.metric_store.delete_metric(this.metric)
			})
		},
		async handle_remove_column() {
			const ok = await this.load_store.wrap(async () => {
				await this.column_store.remove_column(this.metric_store.edited_column_id)
			})

			if (ok) {
				this.$emit('changed-column')
				this.modal_store.close_modal()
			}
		},
		inject_variables(script, input = {}) {

			var lines = script.split(/\r?\n/)
			var code = []
			var re = /# IF ([^#]+)/g
			var operators = /==|!=/
			var last_statement = false
			for (var line_num in lines) {
				var line = lines[line_num]
				if (line.startsWith('#')) {
					var if_statement_value = true
					var matches = line.match(re)
					for (var match of matches) {
						match = match.replace(/^# IF /, "")
						var operator = match.match(operators)
						var parts = match.split(operators).map((e) => e.trim())
						if (parts.length != 2) continue
						var var_name = parts[0]
						var value = parts[1]
						if (value == "NULL") value = null
						var part_value = false
						if (operator == "==" && input[var_name] == value) part_value = true
						if (operator == "!=" && input[var_name] != value) part_value = true
						if_statement_value = if_statement_value && part_value
					}
					last_statement = if_statement_value
				} else {
					if (last_statement) {
						code.push(line)
					}
				}
			}
			if (code.length == 0) {
				code = [script]
			}
			code = code.join("\n")
			for (let _var_name in input) {
				let regex = new RegExp("{"+_var_name+"}", "g")
				code = code.replace(regex, input[_var_name])
			}
			for (let _var_name in input) {
				let regex = new RegExp("{"+_var_name+"}", "g")
				code = code.replace(regex, input[_var_name])
			}
			return code
		},
		set_numerator(new_val) {
			this.editable_numerator = new_val
		},
		set_report_type(new_val) {
			this.editable_report_type = new_val
		},
		set_denominator(new_val) {
			if (new_val == "") this.editable_denominator_id = null
			else this.editable_denominator_id = parseInt(new_val)
		},
	},
	watch: {
		metric(new_metric) {
			this.editable_name = new_metric.name
			this.editable_var_name = new_metric.var_name
			this.editable_type = new_metric.value_type || "value"
			this.editable_scope = new_metric.default_scope
			this.original_script = new_metric.script
			this.editable_numerator = new_metric.numerator_default
			this.editable_report_type = new_metric.report_type_default
			this.editable_script = this.inject_variables(this.original_script, this.variables)
		},
		columnid(new_column_id) {
			this.editable_scope = this.column_store.get_column_scope(new_column_id)
			this.editable_numerator = this.column_store.get_column_numerator(new_column_id)
			this.editable_report_type = this.column_store.get_column_report_type(new_column_id)
			this.editable_denominator_id = this.column_store.get_column_denominator_id(new_column_id)
		},
	},
	computed: {
		can_save() {
			return this.metric.is_own
		},
		loading() {
			return this.metric_store.is_loading || this.column_store.is_loading
		},
		calculated_var_name() {
			return this.$utils.calculate_var_name(this.editable_name)
		},
		variables() {
			const denominator = this.denominator
			return {
				numerator: this.editable_numerator,
				report_type: this.editable_report_type,
				scope: '_current',
				denominator: denominator ? denominator.code_variable : null,
				denominator_id: denominator ? denominator.id : null,
				var_name: this.calculated_var_name,
			}
		},
		non_editable_script() {
			return this.inject_variables(this.original_script, this.variables)
		},
		denominator() {
			return this.metric_store.get_metric(this.editable_denominator_id)
		},
		make_column_name() {

			const variables = this.variables

			let suffix = ''
			if (variables.report_type == 'annual') {
				suffix = " (annual)"
			}

			if (variables.denominator_id !== null) {
				const other_metric = this.metric_store.get_metric(variables.denominator_id)
				if (other_metric) {
					return this.metric.name + " / " + other_metric.name + suffix;
				}
			}
			if (variables.numerator == 'per_share') {
				return this.metric.name + " / share" + suffix;
			}

			return this.metric.name + suffix;
		}
	}
}
</script>

<style scoped>
.code-input {
	font-family: monospace;
	font-size: 12px;
	white-space: pre;
	height: 100px;
}

.denominator-select {
	min-width: 200px;
}
</style>
