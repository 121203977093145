
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_watchlist_list_store = defineStore({
	id: 'watchlist_list',
	state: () => ({

		watchlist: new Map(),
		watchlist_ids: [],

	}),
	getters: {
		name: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).name
			}
		},
		comment: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return ""
				return state.watchlist.get(id).comment
			}
		},
		date_begin: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).date_begin
			}
		},
		url: () => {
			return (id) => {
				return '/watchlist/' + id
			}
		},
		size: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).size
			}
		},
		growth: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).growth
			}
		},
		earned: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).earned
			}
		},
		index_growth: (state) => {
			return (id) => {
				if (!state.watchlist.has(id)) return "-"
				return state.watchlist.get(id).index_growth
			}
		},
	},
	actions: {
		async fetch_watchlists() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/watchlist/all',
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.watchlist_ids = []
			for (var watchlist of res.data.watchlists) {
				this.watchlist.set(watchlist.id, watchlist)
				this.watchlist_ids.push(watchlist.id)
			}
		},
		async add_watchlist(name, date_begin) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/watchlist/add',
				{
					name: name,
					date_begin: date_begin,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.watchlist.set(res.data.watchlist.id, res.data.watchlist)
			this.watchlist_ids.push(res.data.watchlist.id)

			return res.data.watchlist.id
		},
	}
})
