<template>
	<div class="container-md">
		<div class="row mt-3">
			<div class="col-6">
				<h1>Watchlists</h1>
			</div>
			<div class="col-6">
				<button class="btn btn-primary float-end" @click="open_watchlist_modal">Add Watchlist <i class="bi bi-plus-circle-fill"></i></button>
			</div>
			<div class="col-12">
				<p>Build watchlists and analyze performance</p>

				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Date Begin</th>
							<th>Comment</th>
							<th>Size</th>
							<th>Growth</th>
							<th>Earned</th>
							<th>Index</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="id in watchlist_list_store.watchlist_ids" :key="id">
							<tr>
								<td><h5><a :href="watchlist_list_store.url(id)">{{ watchlist_list_store.name(id) }}</a></h5></td>
								<td>{{ watchlist_list_store.date_begin(id) }}</td>
								<td>{{ watchlist_list_store.comment(id) }}</td>
								<td>{{ watchlist_list_store.size(id) }}</td>
								<td><b :class="{growth: true, positive: watchlist_list_store.growth(id) >= 1}">{{ watchlist_list_store.growth(id) >= 1 ? '+' : '' }}{{ $utils.growth(watchlist_list_store.growth(id)) }}</b></td>
								<td><b :class="{growth: true, positive: watchlist_list_store.growth(id) >= 1}">{{ watchlist_list_store.growth(id) >= 1 ? '+' : '' }}{{ $utils.round(watchlist_list_store.earned(id)) }} SEK</b></td>
								<td>{{ $utils.growth(watchlist_list_store.index_growth(id)) }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<AddWatchlistModal />
	</div>
</template>

<script setup>
</script>

<script>

import { use_watchlist_list_store } from '@/stores/watchlist_list.js'
import { use_load_store } from '@/stores/load.js'
import { use_modal_store } from '@/stores/modal.js'
import AddWatchlistModal from '@/modals/AddWatchlistModal.vue'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {

		const watchlist_list_store = use_watchlist_list_store()
		await watchlist_list_store.fetch_watchlists()

	})

	next()
}

export default {
	name: 'WatchlistView',
	components: {
		AddWatchlistModal,
	},
	props: {
	},
	setup() {

		const watchlist_list_store = use_watchlist_list_store()

		return {
			watchlist_list_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
		open_watchlist_modal() {
			const modal = use_modal_store()
			modal.open_modal('add_watchlist')
		},
	}
}
</script>

<style scoped>

.growth {
	font-size: 1.2em;
	color: red;
}

.growth.positive {
	color: green;
}

</style>
