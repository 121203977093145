<template>
	<div class="container-fluid">
		<div class="row">
				<nav class="navbar navbar-expand-lg navbar-light bg-light">
					<div class="container-fluid">
						<a class="navbar-brand" href="/screener">MercuriusData</a>

						<ul class="navbar-nav me-auto mb-2 mb-lg-0">
							<li class="nav-item">
								<a class="nav-link" href="/screener">Screener</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/analytics">Backtest</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/code">Code</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/watchlists">Watchlists</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/documentation">Documentation</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/support">Support</a>
							</li>
						</ul>
					</div>
					<UserMenu />
				</nav>
			</div>
	</div>
</template>

<script setup>
</script>

<script>
import UserMenu from '@/components/UserMenu.vue'

export default {
	name: 'MainMenuView',
	components: {
		UserMenu,
	},
	setup() {

		return {
		}
	},
	beforeRouteEnter() {
	}
}
</script>
<style scoped>

.navbar-brand {
	padding-left: 35px;
	background: transparent url('../../public/mercuriusdata_logo_light.png') no-repeat 0 center;
	background-size: 30px 30px;
}
.main-menu {
	background: #FFF;
	border-bottom: 1px solid #ccc;
}

</style>
