
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { use_column_store } from '@/stores/column.js'
import { api } from '@/api.js'

export const use_metric_store = defineStore({
	id: 'metric',
	state: () => ({

		metric: new Map(),
		category_id_to_metric_id: new Map(),
		categories: [],

		search: '',
		open_category_id: -1,
		open_metric_id: -1,
		edited_column_id: -1,

		types: [
			"",
			"Quotes",
			"Market information",
			"Company report information",
		],

		// data loading parameters
		is_loading: false,
		is_error: false,
		error_message: '',
	}),
	getters: {
		get_metric: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return null
				return state.metric.get(metric_id)
			}
		},
		category_by_id: (state) => {
			return (category_id) => {
				return state.categories.find((cat) => { return cat.id == category_id; })
			}
		},
		categories_by_type: (state) => {
			return (type) => {
				return state.categories.filter((cat) => { return cat.type == type; })
			}
		},
		categories_with_code_metric: (state) => {
			return state.categories.filter((cat) => { return cat.has_code_variables; })
		},
		metrics_by_category_and_type: (state) => {
			return (category, type) => {
				return category.metric_ids.map((metric_id) => {
					return state.metric.get(metric_id)
				}).filter((metric) => metric && (metric.type == type))
			}
		},
		code_metrics_by_category: (state) => {
			return (category) => {
				return category.metric_ids.map((metric_id) => {
					return state.metric.get(metric_id)
				}).filter((metric) => metric && (metric.code_variable != ''))
			}
		},
		metrics_by_search_term: (state) => {
			var ret = []
			state.metric.forEach((metric) => {
				if (metric.name.toLowerCase().includes(state.search.toLowerCase())) {
					ret.push(metric)
				}
			})
			return ret
		},
		has_open_metric: (state) => {
			return state.open_metric_id >= 0;
		},
		is_own: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return false
				return state.metric.get(metric_id).is_own
			}
		},
		get_open_metric: (state) => {
			if (state.open_metric_id < 0) return null
			return state.metric.get(state.open_metric_id)
		},
		is_editing_column: (state) => {
			return state.edited_column_id > 0
		},
		get_edited_column_id: (state) => {
			return state.edited_column_id
		},
		name: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return "No Name"
				return state.metric.get(metric_id).name
			}
		},
		value_type: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return "value"
				return state.metric.get(metric_id).value_type
			}
		},
		numerator_show: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return false
				return state.metric.get(metric_id).numerator_show
			}
		},
		numerator_default: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return false
				return state.metric.get(metric_id).numerator_default
			}
		},
		report_type_show: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return false
				return state.metric.get(metric_id).report_type_show
			}
		},
		denominator_show: (state) => {
			return (metric_id) => {
				if (!state.metric.has(metric_id)) return false
				return state.metric.get(metric_id).denominator_show
			}
		},
	},
	actions: {
		open_category(category_id) {
			if (this.open_category_id != category_id) {
				this.open_category_id = category_id

				var category = this.category_by_id(category_id)

				if (category) {
					this.open_metric_id = category.metric_ids[0]
				}
			}
		},
		open_metric(metric_id) {
			this.open_metric_id = metric_id
		},
		async fetch_categories() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/metric_category/all',
				{
					headers: { Authorization: user.session_id }
				}
			)
			
			this.categories = res.data.categories
		},
		async fetch_metrics() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/metric/all',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.metric.clear()
			for (var id in res.data.metrics) {
				this.metric.set(parseInt(id), res.data.metrics[id])
			}
			this.open_category_id = 6
		},
		async create_metric(script, name, var_name, type) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/metric/create',
				{
					script: script,
					name: name,
					var_name: var_name,
					type: type,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			if (res.data.status == "success") {

				await this.fetch_metrics()
				await this.fetch_categories()

				this.open_metric_id = res.data.metric.id
				this.open_category_id = 6

			} else {
				this.error_message = res.data.message
				this.is_error = true
			}
		},
		async create_metric_bulk(tsv, workspace_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/metric/create_bulk',
				{
					tsv: tsv,
					workspace_id: workspace_id,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			if (res.data.status == "success") {

				return true

			} else {
				this.error_message = res.data.message
				this.is_error = true
			}
		},
		async save_metric(metric, script, name, var_name, type, base_date = null) {
			const user = use_user_store()
			const column_store = use_column_store()
			const res = await axios.post(
				api.host + '/metric/edit/' + metric.id,
				{
					script: script,
					name: name,
					var_name: var_name,
					type: type,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			if (res.data.status == "success") {
				this.metric.set(parseInt(res.data.metric.id), res.data.metric)
				await column_store.fetch_columns(true, base_date)
			} else {
				this.error_message = res.data.message
				this.is_error = true
			}
		},
		async delete_metric(metric) {
			const user = use_user_store()
			const column_store = use_column_store()

			await axios.post(
				api.host + '/metric/delete/' + metric.id,
				{
					metric_id: metric.id,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			this.not_editing_column()

			await this.fetch_metrics()
			await this.fetch_categories()
			await column_store.fetch_columns()

		},
		edit_column(column_id, metric_id) {
			this.open_metric_id = metric_id
			this.open_category_id = this.metric.get(this.open_metric_id).metric_category_id
			this.edited_column_id = column_id
		},
		not_editing_column() {
			this.edited_column_id = -1
			this.open_metric_id = -1
			this.open_category_id = 6
		}
	}
})
