<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Manage Columns</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<div class="alert alert-danger" v-if="column_store.is_error">{{ column_store.error_message }}</div>
						<draggable class="list-group" tag="transition-group" :component-data="{tag: 'ul', type: 'transition-group', name: !drag ? 'flip-list' : null}" @start="drag = true" @end="drag = false" item-key="order" v-model="column_store.active_columns" v-bind="dragOptions" @update="handle_change_column_order">
							<template #item="{ element: column_id }">
								<li class="list-group-item">{{ column_store.get_column_name(column_id) }} <button class="btn btn-sm btn-warning float-end" @click="column_store.remove_column(column_id)">Remove</button></li>
							</template>
						</draggable>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import draggable from "vuedraggable";
import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'MoveColumnModal',
	components: {
		draggable,
	},
	setup() {

		let modal_store = use_modal_store()
		let column_store = use_column_store()

		return {
			modal_store,
			column_store,
		}
	},
	data() {
		return {
		} 
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "move_columns"
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	methods: {
		async handle_change_column_order() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.column_store.save_column_order()
			})
		}
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.list-group {
	min-height: 20px;
}

.list-group-item {
	cursor: move;
}

.list-group-item i {
	cursor: pointer;
}
</style>
