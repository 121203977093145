<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Bulk Add Metrics</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<form @submit.prevent="handle_submit">
							<div class="alert alert-danger" v-if="column_store.is_error">{{ column_store.error_message }}</div>
							<textarea class="form-control" rows="20" placeholder="Name Code" v-model="form.tsv"></textarea>
							<button class="btn btn-primary float-end mt-3">Add</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_metric_store } from '@/stores/metric.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'BulkMetricsModal',
	components: {
	},
	setup() {

		let modal_store = use_modal_store()
		let column_store = use_column_store()
		let metric_store = use_metric_store()
		let workspace_store = use_workspace_store()

		return {
			modal_store,
			column_store,
			metric_store,
			workspace_store,
		}
	},
	data() {
		return {
			form: {tsv: "Name\tCode"}
		} 
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "bulk_metrics"
		},
	},
	methods: {
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.metric_store.create_metric_bulk(this.form.tsv, this.workspace_store.active_workspace_id)
				document.location.reload()
			})
		},
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.list-group {
	min-height: 20px;
}

.list-group-item {
	cursor: move;
}

.list-group-item i {
	cursor: pointer;
}
</style>
