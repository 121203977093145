<template>
	<div class="container">
		<main>
			<div class="row">
				<div class="col-6 offset-3 mb-3">
					<h1>Register as a beta tester</h1>
					<p>We are looking for beta testers and feedback for our data service. If you are interested in testing our platform fill out the form below.</p>
					<div class="alert alert-success" v-if="signup_store.beta_success != ''">
						{{ signup_store.beta_success }}
					</div>
					<form @submit.prevent="handle_submit" v-if="signup_store.beta_success == ''">
						<div class="row">
							<div class="col-12 mb-3">
								<label>Email</label>
								<input type="text" class="form-control" placeholder="Email address" v-model="signup_store.beta_email"/>
							</div>
							<div class="col-12 mb-3">
								<label>Name</label>
								<input type="text" class="form-control" placeholder="Name" v-model="signup_store.beta_name"/>
							</div>
							<div class="col-12 mb-3">
								<label>Company Name (optional)</label>
								<input type="text" class="form-control" placeholder="Company Name" v-model="signup_store.beta_company_name"/>
							</div>
							<div class="col-12 mb-3">
								<label>Size of invested capital (optional)</label>
								<select class="form-control" v-model="signup_store.beta_size">
									<option value="">Select option</option>
									<option value="0-1">0 - 1 million SEK</option>
									<option value="1-10">1 - 10 million SEK</option>
									<option value="10-100">10 - 100 million SEK</option>
									<option value="100+">100 million +</option>
								</select>
							</div>
							<div class="col-12 mb-3">
								<label>What kind of tools are you interested in</label>
								<div class="form-check" v-for="tool in tools" :key="tool">
									<input class="form-check-input" type="checkbox" v-model="signup_store.beta_tools[tool]" :id="tool">
									<label class="form-check-label" :for="tool">
										{{ tool }}
									</label>
								</div>
								<textarea class="form-control" placeholder="Describe the other tool" v-model="signup_store.beta_tool_other"></textarea>
							</div>
							<div class="col-12 mb-3">
								<label>Message</label>
								<textarea class="form-control" placeholder="Custom Message" v-model="signup_store.beta_message"></textarea>
							</div>
							<div class="col-12 mb-3">
								<button class="btn btn-primary">Submit</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</main>
	</div>
</template>

<script setup>
</script>

<script>

import { use_signup_store } from '@/stores/signup.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'BetaPage',
	components: {
	},
	props: {

	},
	setup() {

		const signup_store = use_signup_store()

		return {
			signup_store,
		}
	},
	data() {
		return {
			tools: [
				'Backtests',
				'Code my own KPIs',
				'Alternative sources of data',
				'Tools for day trading',
				'Quantitative investing',
				'Other',
			],
		}
	},
	methods: {
		async handle_submit() {
			const load = use_load_store()
			var ok = await load.wrap(async () => {
				await this.signup_store.beta_sign_up(async (em) => {
					return await this.$utils.sha256(em + this.$utils.salt1())
				}, async (em, n) => {
					return await this.$utils.sha256(em + n + this.$utils.salt2())
				})
			})
			if (ok) {
				this.registration_success = true
			}

		}
	}
}
</script>
<style scoped>
main {
	width: 960px;
	margin: 1em auto;
}

small {
	color: #999;
	font-size: 14px;
}
</style>
