<template>
	<div class="container-md">
		<div class="row mt-3">
			<div class="col-12">
				<DocumentationMenu :tab="tab" />
			</div>
		</div>
		<div class="row mt-3" v-if="tab == 'default'">
			<div class="col-12">
				<h1>Documentation</h1>
				<p>All metrics on this platform are calculated with JavaScript and to access data from our database we use the following syntax.</p>

				<p>Here is an example on how to calculate PE:</p>
				<CodeDisplay :value="sample_code_1" :show_gutter="false" />

				<GraphicalExplanation1 />

				<h2>JavaScript framework</h2>
				The system runs <a href="https://262.ecma-international.org/5.1/" target="_blank">ECMAScript version 5.1</a>. We have extended the built in functionality with our own functions (read more about those <a href="#function_docs">here</a>). All our own functions are written in UPPER CASE to avoid naming collisions.

				<div class="mt-3">
					<h2>ttm, annual and interim variables</h2>
					<p>ttm stands for <i>trailing twelve months</i> and it is the most common report format since we often want to look at the most recent data. Usually it uses data from the last four interim reports to get a representation of the company's 12 month performance.</p>
					<table class="table table-sm">
						<thead>
							<tr>
								<th>Variable Name</th>
								<th>Data Type</th>
								<th>Description</th>
								<th>Example</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(data, variable_name) in documentation_store.documentation.get('ttm')" :key="variable_name">
								<td>
									<pre>ttm.{{ variable_name }}</pre>
								</td>
								<td>{{ make_data_type(data[0]) }}</td>
								<td>{{ data[1] }}</td>
								<td><pre>_current.ttm.{{ variable_name }}</pre></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-for="table_name in documentation_store.non_report_tables" :key="table_name">
					<h2>{{ table_name }}</h2>
					<table class="table">
						<thead>
							<tr>
								<th>Variable Name</th>
								<th>Data Type</th>
								<th>Description</th>
								<th>Example</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(data, variable_name) in documentation_store.documentation.get(table_name)" :key="variable_name">
								<td>
									<pre style="display: inline;">{{ table_name }}.{{ variable_name }}</pre>
								</td>
								<td>{{ make_data_type(data[0]) }}</td>
								<td>{{ data[1] }}</td>
								<td><pre>_current.{{ table_name }}.{{ variable_name }}</pre></td>
							</tr>
						</tbody>
					</table>
				</div>
				<h2>Special Tables</h2>
				<table class="table">
					<thead>
						<tr>
							<th>Table Name</th>
							<th>Description</th>
							<th>Example</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><pre>_x_ttm_ago</pre></td>
							<td>Trailing 12 months but shifted x quarters.</td>
							<td><pre>_current._1_ttm_ago.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>_x_annual_ago</pre></td>
							<td>Annual report data shifted x years.</td>
							<td><pre>_current._5_annual_ago.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>_x_interim_ago</pre></td>
							<td>Interim report data shifted x quarters.</td>
							<td><pre>_current._5_interim_ago.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>previous_ttm</pre></td>
							<td>The same as _1_ttm_ago</td>
							<td><pre>_current.previous_ttm.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>previous_annual</pre></td>
							<td>The same as _1_annual_ago</td>
							<td><pre>_current.previous_annual.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>previous_interim</pre></td>
							<td>The same as _1_interim_ago</td>
							<td><pre>_current.previous_interim.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>calculated</pre></td>
							<td>Access other metrics by their variable name</td>
							<td><pre>_current.calculated.magic_formula</pre></td>
						</tr>
					</tbody>
				</table>
				<h2 id="scopes">Scopes</h2>
				<table class="table">
					<thead>
						<tr>
							<th>Scope Name</th>
							<th>Type</th>
							<th>Description</th>
							<th>Example</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>_current</td>
							<td>Point in time</td>
							<td>The most common scope, it is just the point in time that is the current date.</td>
							<td><pre>_current.ttm.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>_previous_day</pre></td>
							<td>Point in time</td>
							<td>The day before _current.</td>
							<td><pre>_previous_day.price.close</pre></td>
						</tr>
						<tr>
							<td><pre>_x_days_ago</pre></td>
							<td>Point in time</td>
							<td>The day x days before _current. x can be any positive integer.</td>
							<td><pre>_7_days_ago.price.close</pre></td>
						</tr>
						<tr>
							<td><pre>_x_weeks_ago</pre></td>
							<td>Point in time</td>
							<td>The day x weeks before _current. x can be any positive integer.</td>
							<td><pre>_3_weeks_ago.price.close</pre></td>
						</tr>
						<tr>
							<td><pre>_x_months_ago</pre></td>
							<td>Point in time</td>
							<td>The day x months before _current. x can be any positive integer.</td>
							<td><pre>_1_month_ago.price.close</pre></td>
						</tr>
						<tr>
							<td><pre>_x_years_ago</pre></td>
							<td>Point in time</td>
							<td>The day x years before _current. x can be any positive integer.</td>
							<td><pre>_2_years_ago.ttm.revenue</pre></td>
						</tr>
						<tr>
							<td><pre>_x_days</pre></td>
							<td>Interval</td>
							<td>A time interval spanning from x days before _current until _current. The time interval is inclusive.</td>
							<td><pre>_12_days.return.with_dividends</pre></td>
						</tr>
						<tr>
							<td><pre>_x_weeks</pre></td>
							<td>Interval</td>
							<td>A time interval spanning from x weeks before _current until _current. The time interval is inclusive.</td>
							<td><pre>_3_weeks.return.with_dividends</pre></td>
						</tr>
						<tr>
							<td><pre>_x_months</pre></td>
							<td>Interval</td>
							<td>A time interval spanning from x months before _current until _current. The time interval is inclusive.</td>
							<td><pre>_1_month.return.with_dividends</pre></td>
						</tr>
						<tr>
							<td><pre>_x_years</pre></td>
							<td>Interval</td>
							<td>A time interval spanning from x years before _current until _current. The time interval is inclusive.</td>
							<td><pre>_3_years.return.with_dividends</pre></td>
						</tr>
					</tbody>
				</table>
				<h2 id="function_docs">Functions</h2>
				<table class="table">
					<thead>
						<tr>
							<th>Function</th>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="doc in documentation_store.function_docs" :key="doc.title">
							<tr>
								<td rowspan="2"><h5>{{ doc.title }}</h5></td>
								<td>
									{{ doc.short }}
								</td>
							</tr>
							<tr>
								<td>
									<pre class="d-block">{{ doc.examples.join("\n") }}</pre>
									<pre class="d-block">{{ doc.details.join("\n") }}</pre>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row mt-3" v-if="tab == 'code'">
			<div class="col-12">
				<h1>Code Interface</h1>
				<p>Think of the code interface as a callback that will be executed each day. You can use the same variables as in the KPI interface
				but instead of variables resolving to numbers they will resolve to an object with company id as key and the value as the value. Variables that by nature is the same for all companies will be collapsed to a value instead.</p>
				<CodeDisplay :value="sample_code_2" :output="sample_output_2" />
				<p>When you print a value like we do in the code sample above the execution ends after that iteration and you get the reponse.</p>
				<p>Use the PORTFOLIO object to preserve state between iterations.</p>
				<CodeDisplay :value="sample_code_3" :output="sample_output_3" />
				<p>The example above will produce a graph with a straight line as the output.</p>
				<p>You can store any data in your portfolio but the two attributes cash and num_shares are magic because they can be altered by these helper functions.</p>
				<CodeDisplay :value="sample_code_4" :output="sample_output_4" />
				<p>If you want to compare two portfolios you can access multiple portfolios by adding a second argument to the PORTFOLIO function</p>
				<CodeDisplay :value="sample_code_5" :output="sample_output_5" />
				
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { use_documentation_store } from '@/stores/documentation.js'
import { use_load_store } from '@/stores/load.js'
import DocumentationMenu from '@/components/DocumentationMenu.vue'
import CodeDisplay from '@/components/CodeDisplay.vue'
import GraphicalExplanation1 from '@/components/GraphicalExplanation1.vue'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {

		const documentation_store = use_documentation_store()
		documentation_store.fetch_documentation()

	})

	next()
}

export default {
	name: 'DocumentationView',
	components: {
		CodeDisplay,
		GraphicalExplanation1,
		DocumentationMenu,
	},
	props: {
		tab: {
			type: String,
			default: 'default',
		}
	},
	setup() {

		const documentation_store = use_documentation_store()

		return {
			documentation_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
		}
	},
	computed: {
		sample_code_1() {
			return "return round(_current.price.close * _current.share.count / _current.ttm.ebit, 2)"
		},
		sample_code_2() {
			return "var todays_closing_price = _current.price.close\nvar todays_date = _current.info.date_end\n\nPRINT(todays_closing_price[2]) // output the closing price for company 2\nPRINT(todays_date)"
		},
		sample_output_2() {
			return "var todays_closing_price = _current.price.close\nvar todays_date = _current.info.date_end\n\nPRINT(todays_closing_price)\nPRINT(todays_date)"
		},
		sample_code_3() {
			return "// parameter is only used for initialization during first iteration.\nvar portfolio = PORTFOLIO({\n\tcash: 100000,\n\tmy_own_data: 1\n})\nportfolio.my_own_data++\nPLOT(portfolio.my_own_data, 'a straight line')"
		},
		sample_code_4() {
			return "var portfolio = PORTFOLIO({\n\tcash: 100000\n})\n// buy 1 share of SSAB (company_id == 125)\nPORTFOLIO_BUY(portfolio, 125, 1)\n// plot portfolio each day\nPLOT(PORTFOLIO_VALUE(portfolio))"
		},
		sample_code_5() {
			return "var portfolio1 = PORTFOLIO({\n\tcash: 100000\n}, 'portfolio 1')\n\nvar portfolio2 = PORTFOLIO({\n\tcash: 100000\n}, 'portfolio 2')\n\n// buy SSAB in portfolio 1 and investor in portfolio 2\nPORTFOLIO_BUY(portfolio1, \"ssab\")\nPORTFOLIO_BUY(portfolio2, \"investor\")\n\n// plot portfolios each day\nPLOT(PORTFOLIO_VALUE(portfolio1), \"portfolio 1\")\nPLOT(PORTFOLIO_VALUE(portfolio2), \"portfolio 2\")"
		},
	},
	methods: {
		make_data_type(data_type) {
			if (data_type == "double") return "Number";
			if (data_type == "int") return "Integer";
			if (data_type == "date") return "Date";
			if (data_type == "string") return "Text";
			return data_type
		}
	}
}
</script>

<style scoped>

pre {
	display: inline;
}

.color-box {
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 5px;
}

</style>
