
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'
import { loadStripe } from '@stripe/stripe-js'

export const use_billing_store = defineStore({
	id: 'billing',
	state: () => ({
		// column data parameters
		plan_ids: [],
		plans: new Map(),
		invoice_ids: [],
		invoices: new Map(),
		subscription: {},
		client_secret: null,
		payment_method_ids: [],
		payment_methods: new Map(),
		confirm_message: '',
		confirm_status: '',
	}),
	getters: {
		plan_name(state) {
			return (plan_id) => {
				if (!state.plans.has(plan_id)) return "No name"
				return state.plans.get(plan_id).name
			}
		},
		plan_price(state) {
			return (plan_id) => {
				if (!state.plans.has(plan_id)) return "-"
				return state.plans.get(plan_id).price
			}
		},
		plan_features(state) {
			return (plan_id) => {
				if (!state.plans.has(plan_id)) return []
				return state.plans.get(plan_id).features
			}
		},
		payment_method_last4(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return ""
				return state.payment_methods.get(id).last4
			}
		},
		payment_method_expires(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return ""
				return state.payment_methods.get(id).expires
			}
		},
		payment_method_is_default(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return false
				return state.payment_methods.get(id).is_default
			}
		},
		payment_method_type(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return ''
				return state.payment_methods.get(id).type
			}
		},
		payment_method_name(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return ''
				return state.payment_methods.get(id).name
			}
		},
		payment_method_card_img(state) {
			return (id) => {
				if (!state.payment_methods.has(id)) return ''
				const pm = state.payment_methods.get(id)
				var cnum = 24
				if (pm.type == "paypal") cnum = 5
				else if (pm.card_brand == "visa") cnum = 1
				else if (pm.card_brand == "mastercard") cnum = 2
				else if (pm.card_brand == "amex") cnum = 22
				else if (pm.card_brand == "diners") cnum = 10
				else if (pm.card_brand == "discover") cnum = 14
				else if (pm.card_brand == "jcb") cnum = 16
				return "/img/cards/"+cnum+".png"
			}
		},
		subscription_status(state) {
			return state.subscription.status
		},
		subscription_plan_id(state) {
			return state.subscription.plan_id
		},
		subscription_pretty_status(state) {
			if (state.subscription.status == "trialing") return "Free Trial"
			if (state.subscription.status == "active") return "Active"
			return state.subscription.status
		},
		needs_payment_method(state) {
			if (state.subscription.status == "incomplete") return true
			if (state.subscription.status == "trialing" && !state.subscription.has_payment_method) return true
			return false
		},
		invoice_name(state) {
			return (id) => {
				if (!state.invoices.has(id)) return "No name"
				return state.invoices.get(id).date
			}
		},
		invoice_status(state) {
			return (id) => {
				if (!state.invoices.has(id)) return "No name"
				return state.invoices.get(id).status
			}
		},
		invoice_pdf(state) {
			return (id) => {
				if (!state.invoices.has(id)) return "No name"
				return state.invoices.get(id).pdf
			}
		},
	},
	actions: {
		async fetch_plans() {
			const res = await axios.get(
				api.host + '/billing/plans',
			)
			for (var plan of res.data.plans) {
				this.plan_ids.push(parseInt(plan.id))
				this.plans.set(parseInt(plan.id), plan)
			}
		},
		async fetch_subscription() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/billing/subscription',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.subscription = res.data.subscription
			this.client_secret = res.data.subscription.client_secret
		},
		async fetch_payment_methods() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/billing/payment_methods',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.payment_method_ids = []
			for (var i in res.data.payment_methods) {
				const pm = res.data.payment_methods[i]
				this.payment_method_ids.push(pm.id)
				this.payment_methods.set(pm.id, pm)
			}
		},
		async delete_payment_method(pm_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/billing/delete_payment_method',
				{
					pm_id: pm_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.payment_method_ids = []
			for (var i in res.data.payment_methods) {
				const pm = res.data.payment_methods[i]
				this.payment_method_ids.push(pm.id)
				this.payment_methods.set(pm.id, pm)
			}
		},
		async change_plan(new_plan_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/billing/change_plan',
				{
					plan_id: new_plan_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.subscription = res.data.subscription
			this.client_secret = res.data.subscription.client_secret
		},
		async make_default(pm_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/billing/set_default_payment_method',
				{
					pm_id: pm_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.payment_method_ids = []
			for (var i in res.data.payment_methods) {
				const pm = res.data.payment_methods[i]
				this.payment_method_ids.push(pm.id)
				this.payment_methods.set(pm.id, pm)
			}
		},
		async fetch_setup_intent(client_secret) {
			const stripe_key = process.env.VUE_APP_STIPE_PK
			const stripe = await loadStripe(stripe_key)

			const {setupIntent} = await stripe.retrieveSetupIntent(client_secret)

			if (setupIntent.status == "succeeded") {
				this.confirm_message = 'Success! Payment method added.'
				this.confirm_status = 'success'
			}
		},
		async fetch_payment_intent(client_secret) {
			const stripe_key = process.env.VUE_APP_STIPE_PK
			const stripe = await loadStripe(stripe_key)
			const { paymentIntent } = await stripe.retrievePaymentIntent(client_secret)

			if (paymentIntent.status == "succeeded") {
				this.confirm_message = 'Success! Payment received.'
				this.confirm_status = 'success'
			} else if (paymentIntent.status == "processing") {
				this.confirm_message = "Payment processing. We'll update you when payment is received."
				this.confirm_status = 'success'
			} else if (paymentIntent.status == "requires_payment_method") {
				this.confirm_message = "Payment failed. Please try another payment method."
				this.confirm_status = 'danger'
			} else {
				this.confirm_message = "Unknown error"
				this.confirm_status = 'danger'
			}
		},
		async fetch_invoices() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/billing/invoices',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.invoice_ids = []
			for (var invoice of res.data.invoices) {
				this.invoice_ids.push(invoice.id)
				this.invoices.set(invoice.id, invoice)
			}
		},
		async fetch_user() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/billing/user_info',
				{
					headers: { Authorization: user.session_id }
				}
			)

			user.user_name = res.data.user.name
			user.customer_name = res.data.customer.name
			user.permissions = res.data.customer.permissions

		},
	}
})
