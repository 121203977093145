
import { defineStore } from 'pinia'

export const use_modal_store = defineStore({
	id: 'modal',
	state: () => ({
		current_modal: null,
		_last_close_modal_target: -1,
	}),
	getters: {
	},
	actions: {
		open_modal(modal_name) {
			this.current_modal = modal_name
		},
		close_modal() {
			this.current_modal = ''
		},
		pre_close_modal(evt) {
			this._last_close_modal_target = evt.target
		},
		do_close_modal(evt) {
			if (this._last_close_modal_target == evt.target) {
				this.current_modal = ''
			}
		}
	}
})
