<template>
	<div class="container">
		<main>
			<h1>About Us</h1>
			<p>MercuriusData.com is a dedicated platform designed for individuals keen on delving into the intricacies of the stock market. We offer comprehensive historical data for the Swedish stock market (Stockholmsbörsen), coupled with a user-friendly interface for seamless data querying and the creation of sophisticated backtests. Our guiding philosophy emphasizes the necessity of testing investment strategies on precise historical data. To that end, we have invested considerable effort in constructing an accurate model of the stock market, incorporating both price and fundamental data that authentically mirrors the information available at specific points in time.</p>

			<p>Furthermore, we actively seek out and incorporate alternative datasets that are not accessible through other channels.</p>
			<a href="/" class="btn btn-primary">Sign up now</a>
		</main>
	</div>
</template>

<script setup>
</script>

<script>

export default {
	name: 'FrontPage',
	components: {
	},
	props: {

	},
}
</script>
<style scoped>
main {
	width: 960px;
	margin: 1em auto;
}

small {
	color: #999;
	font-size: 14px;
}
</style>
