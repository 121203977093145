<template>
	<div class="container-fluid">
		<footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
			<div class="col-md-4 d-flex align-items-center">
				<a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1" v-if="false"></a>
				<span class="mb-3 mb-md-0 text-muted">© 2023 Herodotus AB</span>
			</div>
		</footer>
	</div>
</template>

<script setup>
</script>

<script>

export default {
	name: 'FooterView',
	components: {
	},
	setup() {

		return {
		}
	},
	beforeRouteEnter() {
	}
}
</script>
