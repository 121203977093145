<template>
	<div class="workspace-picker">
		<div class="row g-3">
			<div class="col-auto">
				<select class="form-select" :value="workspace_store.active_workspace_id" @change="workspace_store.change_workspace($event.target.value)">
					<option v-for="workspace_id in workspace_store.workspace_ids" :key="workspace_id" :value="workspace_id">Workspace: {{ workspace_store.get_workspace_name(workspace_id) }}</option>
				</select>
			</div>
			<div class="col-auto" v-if="!workspace_store.is_default">
				<button class="btn btn-outline-primary" @click="open_edit_modal()" :disabled="workspace_store.is_loading">Rename <i class="bi bi-pencil"></i></button>
			</div>
			<div class="col-auto">
				<button class="btn btn-outline-primary" @click="open_add_modal()" :disabled="workspace_store.is_loading">New Workspace <i class="bi bi-plus-circle-fill"></i></button>
			</div>
			<EditWorkspaceModal :workspace_id="workspace_store.active_workspace_id" />
			<AddWorkspaceModal />
		</div>
	</div>
</template>

<script>

import { use_workspace_store } from '@/stores/workspace.js'
import { use_modal_store } from '@/stores/modal.js'
import EditWorkspaceModal from '@/modals/EditWorkspaceModal.vue'
import AddWorkspaceModal from '@/modals/AddWorkspaceModal.vue'

export default {
	name: 'WorkspacePicker',
	components: {
		EditWorkspaceModal,
		AddWorkspaceModal,
	},
	setup() {

		let workspace_store = use_workspace_store()
		const modal_store = use_modal_store()

		return {
			workspace_store,
			modal_store,
		}
	},
	data() {
		return {
		}
	},
	methods: {
		open_edit_modal() {
			this.workspace_store.reset_error()
			this.modal_store.open_modal("edit_workspace")
		},
		open_add_modal() {
			this.workspace_store.reset_error()
			this.modal_store.open_modal("add_workspace")
		},
	}
}
</script>

<style scoped>
.workspace-picker {
	margin-bottom: 1rem;
	margin-top: 1rem;
	margin-right: 1rem;
	display: inline-block;
}
</style>
