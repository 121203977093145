<template>
	<div class="container-fluid">
		<div class="row mt-3">
			<div class="col-12">
				<div class="row">
					<div class="col-12">
						<a class="btn btn-primary" :href="'/analytics/' + store.workspace_id">Back</a>
						<h1>Extended Analysis</h1>
					</div>
					<div class="col-4">
						Avg CAGR: <b>{{ $utils.percent(store.avg_cagr) }}</b><br/>
						Best CAGR: <b>{{ $utils.percent(store.best_cagr) }}</b><br/>
						Worst CAGR: <b>{{ $utils.percent(store.worst_cagr) }}</b><br/>
					</div>
					<div class="col-4">
						Avg Max Decline: <b>{{ $utils.percent(store.avg_max_decline) }}</b><br/>
						Best Max Decline: <b>{{ $utils.percent(store.best_max_decline) }}</b><br/>
						Worst Max Decline: <b>{{ $utils.percent(store.worst_max_decline) }}</b><br/>
					</div>
					<div class="col-4">
						Stickyness: <b>{{ $utils.percent(store.stickyness) }}</b><br/>
						Stickyness vs index: <b>{{ $utils.percent(store.stickyness_vs_index) }}</b><br/>
					</div>
					<div class="col-12">
						<table class="table table-sm mt-3">
							<thead>
								<tr>
									<th>Date Begin</th>
									<th>Date End</th>
									<th>CAGR</th>
									<th>Max Decline</th>
									<th>Up Up</th>
									<th>Up Down</th>
									<th>Index Up Up</th>
									<th>Index Up Down</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="period_id in store.period_ids" :key="period_id">
									<td>{{ store.date_begin(period_id) }}</td>
									<td>{{ store.date_end(period_id) }}</td>
									<td>{{ $utils.percent(store.cagr(period_id)) }}</td>
									<td>{{ $utils.percent(store.max_decline(period_id)) }}</td>
									<td>{{ store.abs_up_up(period_id) }}</td>
									<td>{{ store.abs_up_down(period_id) }}</td>
									<td>{{ store.index_up_up(period_id) }}</td>
									<td>{{ store.index_up_down(period_id) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { use_load_store } from '@/stores/load.js'
import { use_backtest_result_store } from '@/stores/backtest_result.js'

async function load_data(to, from, next) {
	const load = use_load_store()
	await load.wrap(async () => {
		const backtest_result_store = use_backtest_result_store()
		await backtest_result_store.fetch_extended_analysis(parseInt(to.params.result_id || 0))
	})
	next()
}

export default {
	name: 'ExtendedAnalysis',
	components: {
	},
	props: {
		result_id: {
			type: Number,
			required: true,
		},
	},
	setup() {

		const store = use_backtest_result_store()

		return {
			store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
			
		}
	},
	computed: {
	},
	methods: {
		
	}
}
</script>
