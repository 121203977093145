
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_strategy_list_store = defineStore({
	id: 'strategy_list',
	state: () => ({

		strategy: new Map(),
		strategy_ids: [],

		// data loading parameters
		is_loading: false,
		is_error: false,
		error_message: '',
	}),
	getters: {
		name(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "No Name"
				return state.strategy.get(id).name
			}	
		},
		workspace_id(state) {
			return (id) => {
				if (!state.strategy.has(id)) return -1
				return state.strategy.get(id).workspace_id
			}	
		},
		column_name(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "No Name"
				return state.strategy.get(id).column_name
			}	
		},
		column_order(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "No Order"
				return state.strategy.get(id).column_order
			}	
		},
		date_begin(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "-"
				return state.strategy.get(id).date_begin
			}	
		},
		date_end(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "-"
				return state.strategy.get(id).date_end
			}	
		},
		rebalance_after(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "-"
				return state.strategy.get(id).rebalance_after
			}	
		},
		annual_compound(state) {
			return (id) => {
				if (!state.strategy.has(id)) return "-"
				return state.strategy.get(id).annual_compound
			}	
		}
	},
	actions: {
		async fetch_strategy_list() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/strategy/list',
				{
					headers: { Authorization: user.session_id }
				}
			)
			
			this.strategy_ids = []
			for (var strategy of res.data.strategy_list) {
				this.strategy.set(strategy.id, strategy)
				this.strategy_ids.push(strategy.id)
			}
		},
		async add_strategy(data) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/strategy/add',
				data,
				{
					headers: { Authorization: user.session_id }
				}
			)

			if (res.status == "error") {
				throw new Error(res.message)
			} else {
				var strategy = res.data.strategy
				this.strategy.set(strategy.id, strategy)
				this.strategy_ids.push(strategy.id)

				return strategy.id
			}
		},
	}
})
