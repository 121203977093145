<template>
	<div class="container-fluid">
		<div class="col-md-3 offset-md-4 mt-3" v-if="user_store.reset_valid_token">

			<h2 class="text-center">Set New Password</h2>

			<form @submit.prevent="handle_submit">
				<div class="mb-3">
					<label>Email</label>
					<input type="text" class="form-control" placeholder="Email" required="required" name="email" v-model="user_store.reset_email" readonly autocomplete="new-password">
				</div>
				<div class="mb-3">
					<label>Enter new Password</label>
					<input type="password" class="form-control" placeholder="Password 1" required="required" name="password" v-model="user_store.reset_password1" autocomplete="new-password">
				</div>
				<div class="mb-3">
					<label>Enter new Password</label>
					<input type="password" class="form-control" placeholder="Password 2" required="required" name="password2" v-model="user_store.reset_password2" autocomplete="new-password">
				</div>
				<div class="mb-3">
					<button type="submit" class="btn btn-primary btn-block" :disabled="user_store.is_loading">Set new password</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'

export default {
	components: {
	},
	setup() {
		const user_store = use_user_store()

		return {
			user_store
		}
	},
	async beforeRouteEnter(to, from, next) {
		const user_store = use_user_store()
		const load = use_load_store()

		await load.wrap(async () => {
			await user_store.validate_token(to.params.token)
		})

		next()
	},
	methods: {
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				this.user_store.reset_password_with_token()
			})
		}
	}
}
</script>
