<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add Watchlist</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.close_modal"></button>
			</div>
			<div class="modal-body">
				<form @submit.prevent="handle_submit">
					<div>
						<label>Name</label>
						<input type="text" class="form-control" v-model="form.name" />
					</div>
					<div class="mt-3">
						<label>Date Begin</label>
						<input type="text" class="form-control" v-model="form.date_begin" />
					</div>
					<div>
						<label>Comment</label>
						<textarea class="form-control" v-model="form.comment" />
					</div>
					<div class="mt-3" v-for="share_id in watchlist_store.share_ids" :key="share_id">
						<div class="row">
							<div class="col-12">
								<h5>{{ watchlist_store.share_company(share_id) }}</h5>
							</div>
							<div class="col-6">
								<label>Num Shares </label>
								<input type="number" class="form-control" :value="form.num_shares[share_id]" @input="(evt) => set_num_shares(share_id, evt.target.value)" />
							</div>
							<div class="col-6">
								<label>Buy Amount</label>
								<input type="number" step="0.01" class="form-control" :value="form.amount[share_id]" @input="(evt) => set_amount(share_id, evt.target.value)" />
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button type="submit" class="btn btn-primary float-end">Save</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_load_store } from '@/stores/load.js'
import { use_watchlist_store } from '@/stores/watchlist.js'

function default_data() {
	const watchlist_store = use_watchlist_store()
	let num_shares = {}
	let amount = {}
	let buy_price = {}

	for (var share_id of watchlist_store.share_ids) {
		num_shares[share_id] = watchlist_store.num_shares(share_id)
		buy_price[share_id] = watchlist_store.buy_price(share_id)
		amount[share_id] = Math.round(watchlist_store.buy_value(share_id) * 100) / 100
	}
	return {
		name: watchlist_store.name,
		date_begin: watchlist_store.date_begin,
		comment: watchlist_store.comment,
		num_shares: num_shares,
		amount: amount,
		buy_price: buy_price,
	}
}

export default {
	name: 'EditWatchlistModal',
	components: {
	},
	props: {
		watchlist_id: {
			type: Number
		},
		is_open: {
			type: Boolean,
		}
	},
	setup() {

		const modal_store = use_modal_store()
		const watchlist_store = use_watchlist_store()

		return {
			modal_store,
			watchlist_store,
		}
	},
	data() {
		return {
			form: default_data()
		}
	},
	computed: {
	},
	methods: {
		close_modal() {
			this.modal_store.close_modal()
		},
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.watchlist_store.edit_watchlist(this.form.name, this.form.date_begin, this.form.comment, this.form.num_shares, this.form.amount)
				this.close_modal()
			})
		},
		set_num_shares(share_id, v) {
			this.form.num_shares[share_id] = parseInt(v)
			this.form.amount[share_id] = this.$utils.round(this.form.num_shares[share_id] * this.form.buy_price[share_id])
		},
		set_amount(share_id, v) {
			this.form.amount[share_id] = parseFloat(v)
			this.form.num_shares[share_id] = Math.floor(this.form.amount[share_id] / this.form.buy_price[share_id])
		},
	},
	watch: {
		watchlist_id() {
			this.form = default_data()
		},
		is_open() {
			this.form = default_data()
		},
	},
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
