<template>
	<div>
		<input class="form-control mb-3" placeholder="Search" v-model="metric_store.search" />
		<ul class="list-unstyled" v-if="metric_store.search != ''">
			<li><a href="#" :class="{'metric-item': true, rounded: true, active: metric.id == metric_store.open_metric_id}"
				v-for="metric in metric_store.metrics_by_search_term" :key="metric.id" @click.prevent="open_metric(metric.id)">{{ metric.name }}</a></li>
		</ul>
		<div v-if="metric_store.search == ''">
			<template v-for="type in metric_store.types" :key="type">
				<small class="category-type">{{ type }}</small>
				<ul class="list-unstyled">
					<li class="mb-1" v-for="category in metric_store.categories_by_type(type)" :key="category.id" @click.prevent="open_category(category.id)">
						<button class="btn btn-toggle">
							{{ category.name }}
						</button>
						<button class="btn btn-outline-primary float-end" v-if="category.id == 6" @click.stop.prevent="new_metric">New Metric</button>
						<div class="collapse show" id="home-collapse" v-if="category.id == metric_store.open_category_id">
							<ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4">
								<li><a href="#" :class="{'metric-item': true, rounded: true, active: metric.id == metric_store.open_metric_id}"
									v-for="metric in metric_store.metrics_by_category_and_type(category, '')" :key="metric.id" @click.prevent="open_metric(metric.id)">{{ metric.name }}</a></li>

								<li v-if="metric_store.metrics_by_category_and_type(category, 'main').length > 0">Main metrics</li>
								<li><a href="#" :class="{'metric-item': true, rounded: true, active: metric.id == metric_store.open_metric_id}"
									v-for="metric in metric_store.metrics_by_category_and_type(category, 'main')" :key="metric.id" @click.prevent="open_metric(metric.id)">{{ metric.name }}</a></li>

								<li v-if="metric_store.metrics_by_category_and_type(category, 'calculated').length > 0">Calculated metrics</li>
								<li><a href="#" :class="{'metric-item': true, rounded: true, active: metric.id == metric_store.open_metric_id}"
									v-for="metric in metric_store.metrics_by_category_and_type(category, 'calculated')" :key="metric.id" @click.prevent="open_metric(metric.id)">{{ metric.name }}</a></li>

								<li v-if="metric_store.metrics_by_category_and_type(category, 'sub').length > 0">Sub metrics</li>
								<li><a href="#" :class="{'metric-item': true, rounded: true, active: metric.id == metric_store.open_metric_id}"
									v-for="metric in metric_store.metrics_by_category_and_type(category, 'sub')" :key="metric.id" @click.prevent="open_metric(metric.id)">{{ metric.name }}</a></li>
							</ul>
						</div>
					</li>
				</ul>
			</template>
		</div>
	</div>
</template>

<script>

import { use_metric_store } from '@/stores/metric.js'

export default {
	name: 'MetricMenu',
	components: {
	},
	setup() {

		const metric_store = use_metric_store()

		return {
			metric_store,
		}
	},
	methods: {
		open_category(category_id) {
			const metric_store = use_metric_store()
			metric_store.open_category(category_id)
		},
		open_metric(metric_id) {
			const metric_store = use_metric_store()
			metric_store.open_metric(metric_id)
		},
		new_metric() {
			this.metric_store.not_editing_column()
		},
	}
}
</script>
<style scoped>
.metric-item {
	padding: 5px 10px;
	display: block;
}
.active {
	background: #ccc;
}
.category-type {
	color: #aaa;
}
</style>
