<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel" v-if="!metric_store.is_editing_column">Add Metrics</h5>
				<h5 class="modal-title" id="exampleModalLabel" v-if="metric_store.is_editing_column">Modify Column</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-4" v-if="!metric_store.is_editing_column">
						<MetricMenu />
					</div>
					<div :class="{'col-8': !metric_store.is_editing_column, 'col-12': metric_store.is_editing_column}">
						
						<MetricNew v-show="metric_store.open_metric_id < 0" :base_date="base_date"/>
						<MetricSettings v-if="metric_store.open_metric_id >= 0" :metric="metric_store.get_open_metric" :columnid="metric_store.get_edited_column_id" :base_date="base_date"
							@changed-column="$emit('changed-column')" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_metric_store } from '@/stores/metric.js'
import MetricMenu from '@/components/MetricMenu.vue'
import MetricSettings from '@/components/MetricSettings.vue'
import MetricNew from '@/components/MetricNew.vue'

export default {
	name: 'AddMetricModal',
	components: {
		MetricMenu,
		MetricSettings,
		MetricNew,
	},
	props: {
		base_date: {
			type: String,
			default: null,
		},
	},
	setup() {

		let modal_store = use_modal_store()
		let metric_store = use_metric_store()

		return {
			modal_store,
			metric_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "add_metric"
		}
	},
	methods: {
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
