
import { defineStore } from 'pinia'
import axios from "axios";
import { api } from '@/api.js'

export const use_signup_store = defineStore({
	id: 'signup',
	state: () => ({
		email: '',
		name: '',
		password1: '',
		password2: '',
		message: '',
		valid_token: false,
		token: '',
		plan_id: -1,

		beta_email: '',
		beta_name: '',
		beta_company: '',
		beta_size: '',
		beta_tools: {},
		beta_tool_other: '',
		beta_message: '',
		beta_success: '',
	}),
	getters: {
		fetch_cookie_url: (state) => api.host + '/user/pixel/' + state.cookie_id,
	},
	actions: {
		async validate_verification_token(token) {
			const res = await axios.get(
				api.host + '/user/verify/' + token,
				{
				}
			)

			if (res.data.status == "success") {
				this.valid_token = true
				this.token = token
				this.email = res.data.email
				this.plan_id = res.data.plan_id
			} else {
				throw new Error('The token has expired')
			}
		},
		async sign_up(cb1, cb2) {

			const res = await axios.post(
				api.host + '/user/nonce',
				{
					email: this.email,
					hash: await cb1(this.email),
				}
			)

			const nonce = res.data.nonce

			const res2 = await axios.post(
				api.host + '/user/register',
				{
					email: this.email,
					plan_id: this.plan_id,
					name: this.name,
					password1: this.password1,
					password2: this.password2,
					nonce: nonce,
					hash: await cb2(this.email, nonce)
				}
			)

			this.message = res2.data.message
		},
		async beta_sign_up(cb1, cb2) {

			const res = await axios.post(
				api.host + '/user/nonce',
				{
					email: this.beta_email,
					hash: await cb1(this.beta_email),
				}
			)

			const nonce = res.data.nonce

			const res2 = await axios.post(
				api.host + '/user/beta_register',
				{
					email: this.beta_email,
					name: this.beta_name,
					company: this.beta_company,
					size: this.beta_size,
					tools: JSON.stringify(this.beta_tools),
					tool_other: this.beta_tool_other,
					message: this.beta_message,
					nonce: nonce,
					hash: await cb2(this.beta_email, nonce)
				}
			)

			this.beta_success = res2.data.message
		},
	}
})
