
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { api } from '@/api.js'

export const use_analytics_store = defineStore({
	id: 'analytics',
	state: () => ({
		// column data parameters
		segments: new Map(),
		segment_ids: [],

		images: new Map(),
		info: new Map(),
		dates: [],

		one_period_date_begin: null,
		one_period_date_end: null,
		one_period_total_return: null,
		one_period_total_return_index: null,
		one_period_metric_name: null,
		one_period_cagr: null,
		one_period_cagr_index: null,
		one_period_max_drawdown: null,
		one_period_max_drawdown_index: null,
		one_period_meta: {},

		graph_data: {
			labels: [],
			datasets: [
			]
		},
		graph_options: {
			aspectRatio: 5,
			responsive: true,
			maintainAspectRatio: true,
			animation: {
				duration: 0
			},
			scales: {
				y: {
					beginAtZero: true,
				}
			},
			transitions: {
				zoom: {
					animation: {
						duration: 0
					}
				}
			},
			plugins: {
				zoom: {
					zoom: {
						wheel: {
							enabled: false,
						},
						pinch: {
							enabled: false
						},
						drag: {
							enabled: true
						},
						mode: 'x',
					}
				}
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
		},
	}),
	getters: {
		segment_name: (state) => {
			return (segment_id) => {
				if (!state.segments.has(segment_id)) return "No name"
				return state.segments.get(segment_id).name
			}
		},
		image_url: (state) => {
			return (column_id, idx, date) => {
				if (!state.images.has(column_id)) return null
				if (!state.images.get(column_id).has(date)) return null
				return api.base + "/assets/minimaps/" + state.images.get(column_id).get(date)[idx].file
			}
		},
		min: (state) => {
			return (column_id, idx, date) => {
				if (!state.images.has(column_id)) return null
				if (!state.images.get(column_id).has(date)) return null
				return state.images.get(column_id).get(date)[idx].min
			}
		},
		max: (state) => {
			return (column_id, idx, date) => {
				if (!state.images.has(column_id)) return null
				if (!state.images.get(column_id).has(date)) return null
				return state.images.get(column_id).get(date)[idx].max
			}
		},
		size: (state) => {
			return (column_id, idx, date) => {
				if (!state.images.has(column_id)) return null
				if (!state.images.get(column_id).has(date)) return null
				return state.images.get(column_id).get(date)[idx].size
			}
		},
		total_return: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).total_return[idx]
			}
		},
		cagr: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).cagr[idx]
			}
		},
		avg_cagr: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).avg_cagr[idx]
			}
		},
		result_id: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).result_id[idx]
			}
		},
		waiting: (state) => {
			return (column_id) => {
				if (!state.info.has(column_id)) return true
				return state.info.get(column_id).waiting
			}
		},
		max_drawdown: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).max_drawdown[idx]
			}
		},
		stickyness: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).stickyness[idx]
			}
		},
		stickyness_vs_index: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return null
				return state.info.get(column_id).stickyness_vs_index[idx]
			}
		},
		has_data: (state) => {
			return (column_id, idx) => {
				if (!state.info.has(column_id)) return false
				return state.info.get(column_id).has_data[idx]
			}
		},
		segment_indexes: (state) => {
			return (column_id) => {
				if (!state.info.has(column_id)) return []
				return state.info.get(column_id).segment_indexes
			}
		},
		segment_description: (state) => {
			return (column_id, idx) => {
				var indexes = state.segment_indexes(column_id)
				if (indexes.length == 3) {
					if (idx == 0) return "Desc"
					if (idx == 1) return "Asc"
					if (idx == 2) return "Excluded"
				} else if (indexes.length == 6) {
					if (idx == 0) return "Quintile 1"
					if (idx == 1) return "Quintile 2"
					if (idx == 2) return "Quintile 3"
					if (idx == 3) return "Quintile 4"
					if (idx == 4) return "Quintile 5"
					if (idx == 5) return "Excluded"
				}

				return ""
			}
		},
		first_date: (state) => {
			if (state.dates.length == 0) return null
			return state.dates[0][0]
		},
		last_date: (state) => {
			if (state.dates.length == 0) return null
			return state.dates[state.dates.length - 1][1]
		},
	},
	actions: {
		async fetch_segments() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/analytics/segments',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.segment_ids = []
			for (var segment of res.data.segments) {
				this.segments.set(segment.id, segment)
				this.segment_ids.push(segment.id)
			}
		},
		async fetch_data() {
			const user = use_user_store()
			const workspace_store = use_workspace_store()
			const res = await axios.get(
				api.host + '/analytics/data/' + workspace_store.workspace_id,
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.dates = res.data.dates
			for (var column_id in res.data.images) {
				column_id = parseInt(column_id)
				this.images.set(column_id, new Map())
				for (var date in res.data.images[column_id]) {
					this.images.get(column_id).set(date, res.data.images[column_id][date])
				}
			}
			for (column_id in res.data.info) {
				column_id = parseInt(column_id)
				this.info.set(column_id, res.data.info[column_id])
			}
		},
		async fetch_one_period(column_id, segment_idx, date_begin, date_end) {
			const user = use_user_store()
			const workspace_store = use_workspace_store()
			const res = await axios.get(
				api.host + '/analytics/one_segment/' + workspace_store.workspace_id + '/' + column_id + '/' + segment_idx + '/' + date_begin + '/' + date_end,
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
			this.one_period_date_begin = res.data.date_begin
			this.one_period_date_end = res.data.date_end
			this.one_period_total_return = res.data.total_return
			this.one_period_total_return_index = res.data.total_return_index
			this.one_period_cagr = res.data.cagr
			this.one_period_cagr_index = res.data.cagr_index
			this.one_period_max_drawdown = res.data.max_drawdown
			this.one_period_max_drawdown_index = res.data.max_drawdown_index
			this.one_period_metric_name = res.data.metric_name
			this.one_period_meta = res.data.meta
		},
		async handle_save(update_data) {
			const workspace_store = use_workspace_store()
			await workspace_store.edit_workspace(workspace_store.workspace_id, update_data)
		},
		has_long_str(column_id, idx) {
			const long_str_len = 7;
			if (!this.images.has(column_id)) return false
			for (let value of this.images.get(column_id).values()) {
				if (String(value[idx].min).length > long_str_len) return true
				if (String(value[idx].max).length > long_str_len) return true
			}
			return false
		},
		sum_return(meta) {
			var return_sum = 0
			for (var company_index in meta.company_ids) {
				var company_id = meta.company_ids[company_index]

				let split_factor =  meta.events.filter((e) => e[0] == "split" && e[1] == company_id).reduce((acc, e) => acc * e[3], 1.0)
				let dividends = meta.events.filter((e) => e[0] == "dividend" && e[1] == company_id).reduce((acc, e) => acc + e[3], 0)
				var sell_adj = meta.value_end[company_index] / split_factor + dividends

				return_sum += sell_adj / meta.value_begin[company_index]
			}
			return return_sum / meta.company_ids.length
		}
	}
})
