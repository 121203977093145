<template>
	<div class="container-fluid">
		<LogIn />
		<img :src="user_store.fetch_cookie_url" v-if="user_store.cookie_id != ''" class="inv"/>
	</div>
</template>

<script>
import LogIn from '../components/LogIn.vue'
import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'LoginView',
	components: {
		LogIn,
	},
	props: {
	},
	setup() {

		const user_store = use_user_store()

		return {
			user_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		if ((to.params.session || "") != "") {

			const load = use_load_store()
			await load.wrap(async () => {
				const session_id = to.params.session
				const user_store = use_user_store()
				await user_store.fetch_user(session_id)

				window.localStorage.setItem('session_id', session_id);
				document.location.href = '/screener'
			})
		}

		next()
	},
}
</script>
<style scoped>

.inv {
	opacity: 0;
}

</style>

