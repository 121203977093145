<template>
	<table class="table table-sm table-striped">
		<template v-for="[report_name, labels_on_report] in company_details_store.report_labels" :key="report_name">
			<thead>
				<tr>
					<th class="first-col"></th>
					<template v-for="year in company_details_store.active_years" :key="year">
						<th colspan="4" class="text-center">{{ year }}</th>
					</template>
				</tr>
				<tr>
					<th class="first-col">{{ report_name }}</th>
					<template v-for="year in company_details_store.active_years" :key="year">
						<th v-if="company_details_store.has_quarter(year, 1)">Q1</th>
						<th v-if="company_details_store.has_quarter(year, 2)">Q2</th>
						<th v-if="company_details_store.has_quarter(year, 3)">Q3</th>
						<th v-if="company_details_store.has_quarter(year, 4)">Q4</th>
					</template>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(label_name, label) in labels_on_report" :key="label">
					<td class="first-col">
						<label>{{ label_name }}</label>
					</td>
					<template v-for="year in company_details_store.active_years" :key="year">
						<td v-if="company_details_store.has_quarter(year, 1)">
							<CellValue :value="company_details_store.get_report_data(year, 1, label)" type="value_trimmed" />
						</td>
						<td v-if="company_details_store.has_quarter(year, 2)">
							<CellValue :value="company_details_store.get_report_data(year, 2, label)" type="value_trimmed" />
						</td>
						<td v-if="company_details_store.has_quarter(year, 3)">
							<CellValue :value="company_details_store.get_report_data(year, 3, label)" type="value_trimmed" />
						</td>
						<td v-if="company_details_store.has_quarter(year, 4)">
							<CellValue :value="company_details_store.get_report_data(year, 4, label)" type="value_trimmed" />
						</td>
					</template>
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="1000" class="spacer"></th>
				</tr>
			</thead>
		</template>
	</table>			
</template>

<script setup>
</script>

<script>

import CellValue from '@/atom/CellValue.vue'

import { use_company_details_store } from '@/stores/company_details'

export default {
	name: 'ReportDataTableQuarter',
	components: {
		CellValue,
	},
	props: {
	},
	setup() {

		const company_details_store = use_company_details_store()

		return {
			company_details_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>
table {
	font-size: 0.6rem;
}

.spacer {
	height: 20px;
}

.first-col {
	width: 250px;
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
}
</style>
