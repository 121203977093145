<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Create your account</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div v-if="registration_success">
					{{ signup_store.message }}
				</div>
				<form @submit.prevent="handle_sign_up" v-if="!registration_success">
					<div class="row">
						<div class="col-12 mb-3">
							<label>Email</label>
							<input type="text" class="form-control" placeholder="Email address" v-model="signup_store.email"/>
						</div>
						<div class="col-12 mb-3">
							<label>Name</label>
							<input type="text" class="form-control" placeholder="Name" v-model="signup_store.name"/>
						</div>
						<div class="col-12 mb-3">
							<label>Enter Password</label>
							<input type="password" class="form-control" placeholder="Enter Password" v-model="signup_store.password1"/>
						</div>
						<div class="col-12 mb-3">
							<label>Enter Password Again</label>
							<input type="password" class="form-control" placeholder="Enter Password Again" v-model="signup_store.password2"/>
						</div>
						<div class="col-12 text-end">
							<button type="submit" class="btn btn-primary">Continue</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_billing_store } from '@/stores/billing.js'
import { use_load_store } from '@/stores/load.js'
import { use_signup_store } from '@/stores/signup.js'

export default {
	name: 'SignUp',
	components: {
	},
	props: {
	},
	setup() {

		const modal_store = use_modal_store()
		const billing_store = use_billing_store()
		const signup_store = use_signup_store()

		return {
			modal_store,
			billing_store,
			signup_store,
		}
	},
	data() {
		return {
			registration_success: false,
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "sign_up"
		}
	},
	methods: {
		async handle_sign_up() {
			const load = use_load_store()
			var ok = await load.wrap(async () => {
				await this.signup_store.sign_up(async (em) => {
					return await this.$utils.sha256(em + this.$utils.salt1())
				}, async (em, n) => {
					return await this.$utils.sha256(em + n + this.$utils.salt2())
				})
			})
			if (ok) {
				this.registration_success = true
			}
		},
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
