
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_code_store = defineStore({
	id: 'code',
	state: () => ({
		// column data parameters
		id: -1,
		name: '',
		body: '',
		date_begin: new Date(),
		date_end: new Date(),
		output: '',
		error: '',
		has_plot: false,
		read_only: false,

		// graph data
		graph_data: null,
		graph_options: {
			aspectRatio: 3,
			responsive: true,
			maintainAspectRatio: true,
			animation: {
				duration: 0
			},
			scales: {
				y: {
					beginAtZero: true,
				}
			},
			transitions: {
				zoom: {
					animation: {
						duration: 0
					}
				}
			},
			plugins: {
				zoom: {
					zoom: {
						wheel: {
							enabled: false,
						},
						pinch: {
							enabled: false
						},
						drag: {
							enabled: true
						},
						mode: 'x',
					}
				}
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
		},

	}),
	getters: {
		has_error(state) {
			return state.error != ''
		},
		has_graph_data(state) {
			return state.graph_data !== null
		}
	},
	actions: {
		async fetch_code(code_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/code/' + code_id,
				{
					headers: { Authorization: user.session_id }
				}
			)
			for (var param_name in res.data.code) {
				this[param_name] = res.data.code[param_name]
			}
			if (this.has_plot) {
				await this.load_plot_data()
			}
		},
		async save() {
			this.output = ''
			this.error = ''
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/code/save/' + this.id,
				{
					name: this.name,
					body: this.body,
					date_begin: this.date_begin,
					date_end: this.date_end,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)
			for (var param_name in res.data.code) {
				this[param_name] = res.data.code[param_name]
			}
			if (this.has_plot) {
				await this.load_plot_data()
			}
		},
		async clone() {
			this.output = ''
			this.error = ''
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/code/clone/' + this.id,
				{
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			document.location.href = "/code/" + res.data.code.id
		},
		async load_plot_data() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/code/plot_data/' + this.id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.graph_data = res.data.graph_data
			this.graph_options = res.data.graph_options
		},
		async delete_code() {
			const user = use_user_store()
			await axios.delete(
				api.host + '/code/delete/' + this.id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.id = -1
		}
	}
})
