<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Export Metrics</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<textarea class="form-control" rows="20" :value="export_tsv"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_metric_store } from '@/stores/metric.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_analytics_store } from '@/stores/analytics.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'ExportMetricsModal2',
	components: {
	},
	setup() {

		let modal_store = use_modal_store()
		let column_store = use_column_store()
		let metric_store = use_metric_store()
		let workspace_store = use_workspace_store()
		let analytics_store = use_analytics_store()

		return {
			modal_store,
			column_store,
			metric_store,
			workspace_store,
			analytics_store,
		}
	},
	data() {
		return {
		} 
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "export_metrics2"
		},
		export_tsv() {
			var rows = []
			var cols_row1 = ["Workspace", "Segments", "Rebalance", "Start date", "End date"];
			var cols_row2 = [
				this.workspace_store.name,
				this.analytics_store.segment_name(this.workspace_store.segment),
				this.workspace_store.rebalance,
				this.workspace_store.date_begin,
				this.workspace_store.date_end,
			];
			rows.push(cols_row1.join("\t"))
			rows.push(cols_row2.join("\t"))
			rows.push("")

			var cols_row3 = ["Name", "Code"];
			rows.push(cols_row3.join("\t"))

			for (var column_id of this.column_store.active_non_info_columns) {
				var code = this.metric_store.get_metric(this.column_store.get_metric_id(column_id)).script.trim()
				code = code.replaceAll('"', '""')
				var col = [
					this.column_store.get_column_name(column_id),
				]
				for (var idx of this.analytics_store.segment_indexes(column_id)) {
					col.push(this.analytics_store.avg_cagr(column_id, idx).toString().replaceAll(".", ","))
				}
				col.push('"' + code + '"')
				rows.push(col.join("\t"))
			}

			return rows.join("\n")
		},
	},
	methods: {
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.metric_store.create_metric_bulk(this.form.tsv, this.workspace_store.active_workspace_id)
				document.location.reload()
			})
		},
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.list-group {
	min-height: 20px;
}

.list-group-item {
	cursor: move;
}

.list-group-item i {
	cursor: pointer;
}
</style>
