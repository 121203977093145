<template>
	<div class="backtest-menu">
		<h3 v-if="base_date">{{ base_date }}</h3>
		<WorkspacePicker />
		<button class="btn btn-primary me-3" @click="open_add_column">Add Metrics <i class="bi bi-plus-circle-fill"></i></button>
		<button class="btn btn-secondary me-3" @click="open_move_columns">Change Order <i class="bi bi-list"></i></button>
		<button class="btn btn-outline-primary me-3" @click="open_bulk_metrics">Bulk Metrics <i class="bi bi-file-earmark-excel-fill"></i></button>
		<button class="btn btn-outline-secondary me-3" @click="open_export_metrics" v-if="show_export">Export <i class="bi bi-file-earmark-excel-fill"></i></button>
		<button class="btn btn-outline-secondary me-3" @click="open_export_metrics2" v-if="show_export">Export 2<i class="bi bi-file-earmark-excel-fill"></i></button>
	</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_metric_store } from '@/stores/metric.js'
import WorkspacePicker from '@/components/WorkspacePicker.vue'

export default {
	name: 'ScreenerMenu',
	components: {
		WorkspacePicker,
	},
	props: {
		show_add_backtest: {
			required: false,
			default: true,
		},
		base_date: {
			default: null,
		},
		show_export: {
			require: false,
			default: false,
		},
	},
	setup() {

		const modal_store = use_modal_store()
		const metric_store = use_metric_store()

		return {
			modal_store,
			metric_store,
		}
	},
	data() {
		return {
		}
	},
	methods: {
		open_add_column() {
			this.metric_store.not_editing_column()
			this.modal_store.open_modal("add_metric")
		},
		open_move_columns() {
			this.modal_store.open_modal("move_columns")
		},
		open_bulk_metrics() {
			this.modal_store.open_modal("bulk_metrics")
		},
		open_export_metrics() {
			this.modal_store.open_modal("export_metrics")
		},
		open_export_metrics2() {
			this.modal_store.open_modal("export_metrics2")
		},
		open_new_strategy() {
			this.modal_store.open_modal("add_strategy")
		},
	}
}
</script>

<style scoped>
h3 {
	text-align: center;
	color: red;
}
</style>
