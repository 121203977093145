
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_code_list_store = defineStore({
	id: 'code_list',
	state: () => ({
		// column data parameters
		code: new Map(),
		code_ids: [],

	}),
	getters: {
		name: (state) => {
			return (code_id) => {
				if (!state.code.has(code_id)) return "No name"
				return state.code.get(code_id).name
			}
		},
	},
	actions: {
		async fetch_code_list() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/code/list',
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.code_ids = []
			for (var code of res.data.code) {
				this.code.set(code.id, code)
				this.code_ids.push(code.id)
			}
		},
		async create_code() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/code/create',
				{
					headers: { Authorization: user.session_id }
				}
			)
			return res.data.code.id
		},
	}
})
