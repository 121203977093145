<template>
	<div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 mt-3">
					<button class="btn btn-primary" @click="create_code" :disabled="!user_store.has_permission('custom_backtest')">New Custom Backtest</button>
					<i class="ms-3" v-if="!user_store.has_permission('custom_backtest')">Upgrade plan to create custom backtests</i>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-3">
					<table class="table">
						<thead>
							<tr>
								<th>Name</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="code_id in code_list_store.code_ids" :key="code_id">
								<td><a :href="'/code/' + code_id">{{ code_list_store.name(code_id) }}</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>
import { use_load_store } from '@/stores/load.js'
import { use_user_store } from '@/stores/user.js'
import { use_code_list_store } from '@/stores/code_list.js'

async function load_data(to, from, next) {
	const load = use_load_store()
	await load.wrap(async () => {
		const code_list_store = use_code_list_store()
		code_list_store.fetch_code_list()
	})
	next()
}

export default {
	name: 'CodeListView',
	components: {
	},
	props: {

	},
	setup() {

		const code_list_store = use_code_list_store()
		const user_store = use_user_store()

		return {
			code_list_store,
			user_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},

	methods: {
		async create_code() {
			const load = use_load_store()
			await load.wrap(async () => {
				const code_list_store = use_code_list_store()
				var code_id = await code_list_store.create_code()
				document.location.href = "/code/" + code_id
			})		
		}
	}
}
</script>
