<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-md" @click.stop="">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Add Strategy</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<form @submit.prevent="handle_submit">
							<div class="mb-3">
								<label class="form-label">Strategy Name</label>
								<input type="text" class="form-control" v-model="form.name" />
							</div>
							<div class="mb-3">
								<label class="form-label">Workspace</label>
								<select class="form-control" v-model="form.workspace_id" @change="handle_workspace_change">
									<option :value="-1">Select Workspace</option>
									<option v-for="workspace_id in workspace_store.workspace_ids" :key="workspace_id" :value="workspace_id">{{ workspace_store.get_workspace_name(workspace_id) }}</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Order by Column</label>
								<select class="form-control" v-model="form.sort_by_column_id" :disabled="form.workspace_id <= 0">
									<option :value="-1">Select Column</option>
									<option v-for="column_id in column_store.active_columns" :key="column_id" :value="column_id">{{ column_store.get_column_name(column_id) }}</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Order Direction</label>
								<select class="form-control" v-model="form.sort_dir">
									<option value="desc">Descending</option>
									<option value="asc">Ascending</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label">Portfolio Size</label>
								<input type="text" class="form-control" v-model="form.portfolio_size" />
							</div>
							<div class="mb-3">
								<label class="form-label">Date Begin</label>
								<input type="text" class="form-control" v-model="form.date_begin" />
							</div>
							<div class="mb-3">
								<label class="form-label">Date End</label>
								<input type="text" class="form-control" v-model="form.date_end" />
							</div>
							<div class="mb-3">
								<label class="form-label">Rebalance Interval (in days)</label>
								<input type="text" class="form-control" v-model="form.rebalance_after" />
							</div>
							<div class="mb-3">
								<div class="alert alert-danger" v-if="strategy_store.is_error">
									{{ strategy_store.error_message }}
								</div>
							</div>
							<div>
								<button type="submit" class="btn btn-primary" :disabled="strategy_store.is_loading">
									{{ strategy_store.is_loading ? "Adding..." : "Add Strategy" }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_strategy_list_store } from '@/stores/strategy_list.js'
import { use_load_store } from '@/stores/load.js'

function default_data(workspace_store, column_store) {
	return {
		name: '',
		workspace_id: workspace_store.active_workspace_id,
		sort_by_column_id: column_store.order_by_column_id,
		sort_dir: column_store.order_by_column_dir,
		portfolio_size: 10,
		date_begin: '2013-04-01',
		date_end: '2023-04-01',
		rebalance_after: 365,
	}
}

export default {
	name: 'AddStrategyModal',
	components: {
	},
	props: {
		is_open: {
			type: Boolean,
		},
	},
	setup() {

		const modal_store = use_modal_store()
		const column_store = use_column_store()
		const workspace_store = use_workspace_store()
		const strategy_store = use_strategy_list_store()

		return {
			modal_store,
			column_store,
			strategy_store,
			workspace_store,
		}
	},
	data() {
		return {
			form: default_data(this.workspace_store, this.column_store)
		}
	},
	computed: {
	},
	methods: {
		reset_form() {
			this.form = default_data(this.workspace_store, this.column_store)
		},
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				var new_strategy_id = await this.strategy_store.add_strategy(this.form)

				document.location.href = "/strategy/" + new_strategy_id
			})
			
		},
		async handle_workspace_change() {
			const load = use_load_store()
			await load.wrap(async () => {
				this.column_store.fetch_columns_for_workspace(this.form.workspace_id, false)
			})
		}
	},
	watch: {
		is_open(val) {
			console.log("GOT NEW VAL")
			console.log(val)
			if (val) {
				this.reset_form()
			}
		}
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
