<template>
<div class="modal-backdrop modal d-block" v-if="is_open" @mousedown.self="this.modal_store.pre_close_modal" @mouseup.self="this.modal_store.do_close_modal">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Edit Workspace</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.modal_store.close_modal"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<form @submit.prevent="handle_submit">
							<div class="mb-3">
								<label class="form-label">Workspace Name</label>
								<input type="text" class="form-control" v-model="form.name" />
							</div>
							<div class="alert alert-danger" v-if="workspace_store.is_error">{{ workspace_store.error_message }}</div>
							<div>
								<button type="submit" class="btn btn-primary float-end" :disabled="workspace_store.is_loading">
									{{ workspace_store.is_loading ? "Saving..." : "Save" }}
								</button>
							</div>
						</form>
						<button @click="delete_workspace" class="btn btn-warning" :disabled="workspace_store.is_loading" v-if="!workspace_store.is_default">Delete</button>
						<div class="form-check mt-3">
							<input class="form-check-input" type="checkbox" v-model="delete_metrics_also" id="deleteWorkspaceCheckbox">
							<label class="form-check-label" for="deleteWorkspaceCheckbox">Delete all metrics also</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_load_store } from '@/stores/load.js'

function default_data(store) {
	return {
		name: store.name,
	}
}

export default {
	name: 'EditWorkspaceModal',
	components: {
	},
	props: {
		workspace_id: {
			type: Number
		},
	},
	setup() {

		const modal_store = use_modal_store()
		const workspace_store = use_workspace_store()

		return {
			modal_store,
			workspace_store,
		}
	},
	data() {
		return {
			form: default_data(this.workspace_store),
			delete_metrics_also: false,
		}
	},
	computed: {
		is_open() {
			return this.modal_store.current_modal == "edit_workspace"
		},
	},
	methods: {
		async delete_workspace() {
			const load = use_load_store()
			await load.wrap(async () => {
				if (await this.workspace_store.delete_workspace(this.workspace_id, this.delete_metrics_also)) {
					this.$router.push('/')
					this.modal_store.close_modal()
				}
			})
		},
		async handle_submit() {
			const load = use_load_store()
			await load.wrap(async () => {
				if (await this.workspace_store.edit_workspace(this.workspace_id, this.form)) {
					this.modal_store.close_modal()
				}
			})
		},
	},
	watch: {
		workspace_id() {
			this.form.name = this.workspace_store.name
		}
	}
}
</script>

<style scoped>
.modal-backdrop {
	background: rgba(0, 0, 0, 0.15);
}
</style>
