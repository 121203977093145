<template>
	<form @submit.prevent="pay">
		<div class="mb-3">
			<StripeElements
				v-if="stripe_loaded"
				v-slot="{ elements }"
				ref="elms"
				:stripe-key="stripe_key"
				:instance-options="instance_options"
				:elements-options="{clientSecret: billing_store.client_secret}">

				<StripeElement
					type="payment"
					ref="payment"
					:elements="elements"
					:options="payment_options" />
			</StripeElements>
		</div>
		<div class="mb-3">
			<button type="submit" class="btn btn-primary btn-block">Add Payment Method</button>
		</div>
	</form>
</template>

<script>

import { use_billing_store } from '@/stores/billing.js'
import { use_load_store } from '@/stores/load.js'

import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ref, onBeforeMount, toRaw } from 'vue'

export default {
	components: {
		StripeElement,
		StripeElements,
	},
	setup() {

		const billing_store = use_billing_store()

		const stripe_key = process.env.VUE_APP_STIPE_PK
		const stripe_loaded = ref(false)
		const stripe_instance = ref(false)

		onBeforeMount(() => {
			const stripe_promise = loadStripe(stripe_key)
			stripe_promise.then((stripe) => {
				stripe_loaded.value = true
				stripe_instance.value = stripe
			})
		})

		const instance_options = ref({
		})
		
		const payment_options = ref({
		})

		const payment = ref()
		const elms = ref()

		const pay = async () => {

			const load = use_load_store();
			await load.wrap(async () => {
				const {error} = await elms.value.instance.confirmSetup({
					elements: toRaw(elms.value.elements),
					confirmParams: {
						return_url: process.env.VUE_APP_HOST + "/billing/confirm",
					}
				});
				console.log(error)
			})
		}

		return {
			stripe_instance,
			billing_store,
			stripe_key,
			stripe_loaded,
			instance_options,
			payment_options,
			payment,
			elms,
			pay,
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>
