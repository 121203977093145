<template>
	<div class="user-menu">
		<div><small>{{ user.customer_name }}</small> <button class="user-menu-toggle" @click="toggle_menu"><i class="bi bi-gear"></i></button></div>	

		<div class="dropdown-backdrop" v-if="is_open" @click.self="close_menu"></div>
		<ul class="dropdown-menu show" v-if="is_open">
			<li><a class="dropdown-item" href="/billing">Billing</a></li>
			<li><a class="dropdown-item" href="/invoices">Invoices</a></li>
			<li><a class="dropdown-item" href="/api">API</a></li>
			<li><a class="dropdown-item" href="/log_out" @click.prevent="log_out">Log Out</a></li>
		</ul>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'

export default {
	name: 'UserMenu',
	components: {
	},
	setup() {
		let user = use_user_store()

		return {
			user
		}
	},
	data() {
		return {
			is_open: false,
		}
	},
	methods: {
		log_out() {
			let user = use_user_store()
			user.log_out()
			this.$router.go()
		},
		toggle_menu() {
			this.is_open = !this.is_open
		},
		close_menu() {
			this.is_open = false
		},
	}
}
</script>

<style scoped>
.user-menu {
	float: right;
	margin: 10px;
	min-width: 250px;
}

.user-menu small {
	vertical-align: middle;
}

.dropdown-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
}

.user-menu-toggle {
	vertical-align: middle;
	border: none;
	border-radius: 100%;
	background: transparent;
	font-size: 20px;
}

.user-menu-toggle:hover {
	background: #efefef;
}

</style>
