<template>
	<div class="col-md-3 offset-md-4 mt-3">

		<h2 class="text-center">Reset Password</h2>
		<div v-if="user_store.reset_email_step == 1">
			<form @submit.prevent="handle_reset">
				<div class="mb-3">
					<label>Enter the email for your account here</label>
					<input type="text" class="form-control" placeholder="Email" required="required" name="email" v-model="user_store.reset_email">
				</div>
				<div class="mb-3">
					<button type="submit" class="btn btn-primary btn-block">Send Reset Email</button>
				</div>
			</form>
			<div class="mb-3">
				<center>
					<router-link to="/login">Log in instead?</router-link>
				</center>
			</div>
		</div>
		<div v-if="user_store.reset_email_step == 2">
			<div class="alert alert-success mb-3">
				A reset-password email has been sent to your inbox. To reset your password you need to click the link in the email.
			</div>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'
export default {
	name: 'ResetPassword',
	components: {
	},
	setup() {
		const user_store = use_user_store()
		return {
			user_store,
		}
	},
	methods: {
		async handle_reset() {
			const load = use_load_store()

			await load.wrap(async () => {
				await this.user_store.reset_password()
			})
		}
	}
}
</script>
