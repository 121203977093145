<template>
	<div class="row mt-3">
		<div class="col">
			<label>Segments</label>
			<select class="form-select" :value="workspace_store.segment" @change="handle_save({segment_id: $event.target.value})">
				<option v-for="segment_id in analytics_store.segment_ids" :key="segment_id" :value="segment_id">{{ analytics_store.segment_name(segment_id) }}</option>
			</select>
		</div>
		<div class="col">
			<label>Rebalance</label>
			<div>
				<div class="btn-group">
					<button type="button" :class="rebalance_css('year')" @click="handle_save({rebalance: 'year'})">Year</button>
					<button type="button" :class="rebalance_css('quarter')" @click="handle_save({rebalance: 'quarter'})">Quarter</button>
					<button type="button" :class="rebalance_css('month')" @click="handle_save({rebalance: 'month'})">Month</button>
				</div>
			</div>
		</div>
		<div class="col">
			<label>Start date</label>
			<VueDatePicker :model-value="workspace_store.date_begin" @update:model-value="(new_date) => handle_save({date_begin: new_date})" :enable-time-picker="false" format="yyyy-MM-dd"></VueDatePicker>
		</div>
		<div class="col">
			<label>End date</label>
			<VueDatePicker :model-value="workspace_store.date_end" @update:model-value="(new_date) => handle_save({date_end: new_date})" :enable-time-picker="false" format="yyyy-MM-dd"></VueDatePicker>
		</div>
		<div class="col">
			<button class="btn btn-primary mt-4" @click="handle_save({date_end: null})">Set Today</button>
		</div>
	</div>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { use_analytics_store } from '@/stores/analytics.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'AnalyticsMenu',
	components: {
		VueDatePicker,
	},
	props: {
	},
	setup() {

		const analytics_store = use_analytics_store()
		const workspace_store = use_workspace_store()

		return {
			analytics_store,
			workspace_store,
		}
	},
	data() {
		return {
		}
	},
	methods: {
		async handle_save(update_data) {
			const load = use_load_store()
			await load.wrap(async () => {
				const analytics_store = use_analytics_store()
				await analytics_store.handle_save(update_data)

				document.location.reload()
			})
		},
		rebalance_css(type) {
			return {
				btn: true,
				'btn-outline-primary': true,
				active: this.workspace_store.rebalance == type,
			}
		},
	}
}
</script>

<style scoped>

</style>
