<template>
	<div :class="{card: true, 'mb-3': true, 'active': billing_store.subscription_plan_id == plan_id, 'plan-choose': true}">
		<div class="card-body">
			<span class="plan-choose-title">{{ billing_store.plan_name(plan_id) }}</span>
			<span class="plan-choose-price">{{ billing_store.plan_price(plan_id) }}kr <small>/mo</small></span>
			<div class="float-end">
				<button v-if="billing_store.subscription_plan_id != plan_id" class="btn btn-link btn-sm" @click="handle_change_plan">Change to Plan</button>
				<small v-if="billing_store.subscription_plan_id == plan_id">Current plan</small>
			</div>
		</div>
	</div>
</template>

<script>

import { use_billing_store } from '@/stores/billing.js'
import { use_load_store } from '@/stores/load.js'

export default {
	components: {
	},
	props: {
		plan_id: {
			type: Number,
		}
	},
	setup() {

		const billing_store = use_billing_store()

		return {
			billing_store,
		}
	},
	computed: {
	},
	methods: {
		async handle_change_plan() {
			const load = use_load_store()
			await load.wrap(async () => {
				const billing_store = use_billing_store()
				await billing_store.change_plan(this.plan_id)
			})
		}
	}
}
</script>
<style scoped>

.plan-choose {
	.card-body > * {
		vertical-align: middle;
		margin-left: 10px;
	}
	.card-body > *:first-child {
		margin-left: 0;
	}
}

.plan-choose.active {
	background: #efefef;
}

.plan-choose-title {
}

.plan-choose-price {
	font-weight: bold;

	small {
		font-weight: normal;
		color: #555;
	}
}
</style>
