<template>
	<th>
		<div>
			<span v-if="column_store.order_by_column_id == column_id" class="me-1">
				<i class="bi bi-caret-down-fill" v-if="column_store.order_by_column_dir == 'desc'"></i>
				<i class="bi bi-caret-up-fill" v-if="column_store.order_by_column_dir == 'asc'"></i>
			</span>
			<a href="#" @click="handle_sort">
				{{ column_store.get_column_name(column_id) }}
			</a>
			<small v-if="column_store.has_filter(column_id)">
				[{{ column_store.min_filter(column_id) }}, {{ column_store.max_filter(column_id) }}]
			</small>
			<i class="edit-column bi bi-pencil ms-2 me-2 visible-on-hover" @click="edit_column" v-if="column_id > 0"></i>
			<i class="edit-column bi bi-filter-circle ms-2 me-2 visible-on-hover" @click="filter_column" v-if="column_id > 0"></i>
		</div>
	</th>
</template>

<script>
import { use_column_store } from '@/stores/column.js'
import { use_metric_store } from '@/stores/metric.js'
import { use_modal_store } from '@/stores/modal.js'

export default {

	name: 'ColumnHeader',
	components: {

	},
	props: {
		column_id: {
			type: Number,
			required: true,
		}
	},
	setup(props) {
		const column_store = use_column_store()
		const metric_store = use_metric_store()
		const modal_store = use_modal_store()

		return {
			column_store,
			metric_store,
			modal_store,
			is_loading: column_store.get_column_is_loading(props.column_id)
		}
	},
	methods: {
		edit_column() {
			this.metric_store.edit_column(this.column_id, this.column_store.get_metric_id(this.column_id))
			this.modal_store.open_modal("add_metric")
		},
		filter_column() {
			this.column_store.edit_filter_column(this.column_id)
			this.modal_store.open_modal("filter_column")
		},
		handle_sort(evt) {
			evt.preventDefault()
			this.column_store.order_by_column(this.column_id)
		}
	}
}

</script>

<style scoped>

th {
	white-space: pre;
	position: sticky;
	top: 0;
	background-color: white !important;
	background-clip: padding-box;

	div {
		border: 1px solid #ccc;
	}
}

.drag-handle {
	cursor: grab;
}

.ghost {
	background: #ccc;
	opacity: 0.5;
}

.dragged-element {
	padding-top: 60px;
	opacity: 0.5;
}

.edit-column {
	cursor: pointer;
}

th {
}


</style>
