<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-6">
				<h1>Strategies</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<button class="btn btn-secondary me-3" @click="open_add_strategy">Add Strategy <i class="bi bi-plus-circle-fill"></i></button>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Workspace</th>
							<th>Column</th>
							<th>Order</th>
							<th>Annual Compound</th>
							<th>Date Start</th>
							<th>Date End</th>
							<th>Rebalance After</th>
							<th>Edit</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="strategy_id in strategy_list_store.strategy_ids" :key="strategy_id">
							<td><a :href="strategy_url(strategy_id)">{{ strategy_list_store.name(strategy_id) }}</a></td>
							<td>{{ workspace_store.get_workspace_name(strategy_list_store.workspace_id(strategy_id)) }}</td>
							<td>{{ strategy_list_store.column_name(strategy_id) }}</td>
							<td>{{ strategy_list_store.column_order(strategy_id) }}</td>
							<td>{{ $utils.growth(strategy_list_store.annual_compound(strategy_id)) }}</td>
							<td>{{ strategy_list_store.date_begin(strategy_id) }}</td>
							<td>{{ strategy_list_store.date_end(strategy_id) }}</td>
							<td>{{ strategy_list_store.rebalance_after(strategy_id) }}</td>
							<td>
								<button class="btn btn-primary float-end" @click="edit_strategy(strategy_id)">Edit</button>
							</td>
							<td>
								<button class="btn btn-xs btn-warning"  @click="delete_strategy(strategy_id)">Delete</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<AddStrategyModal :is_open="modal_store.current_modal == 'add_strategy'" />
		<EditStrategyModal :strategy_id="edit_strategy_id" />
	</div>
</template>

<script setup>
</script>

<script>

import { use_modal_store } from '@/stores/modal.js'
import { use_strategy_list_store } from '@/stores/strategy_list.js'
import { use_strategy_store } from '@/stores/strategy.js'
import { use_load_store } from '@/stores/load.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_column_store } from '@/stores/column.js'
import AddStrategyModal from '@/modals/AddStrategyModal.vue'
import EditStrategyModal from '@/modals/EditStrategyModal.vue'

export default {
	name: 'StrategyListView',
	components: {
		AddStrategyModal,
		EditStrategyModal,
	},
	setup() {

		const strategy_list_store = use_strategy_list_store()
		const strategy_store = use_strategy_store()
		const modal_store = use_modal_store()
		const workspace_store = use_workspace_store()


		return {
			strategy_list_store,
			strategy_store,
			modal_store,
			workspace_store,
		}
	},
	data() {
		return {
			edit_strategy_id: -1,
		}
	},
	async beforeRouteEnter() {
		const load = use_load_store()
		await load.wrap(async () => {
			const store = use_strategy_list_store()
			await store.fetch_strategy_list()

			const workspace_store = use_workspace_store()
			await workspace_store.fetch_workspaces()

			if (workspace_store.active_workspace_id > 0) {
				const column_store = use_column_store()
				column_store.fetch_columns_for_workspace(workspace_store.active_workspace_id, false)
			}
		})
	},
	computed: {
		
	},
	methods: {
		open_add_strategy() {
			this.modal_store.open_modal("add_strategy")
		},
		strategy_url(id) {
			return '/strategy/' + id
		},
		async delete_strategy(strategy_id) {
			const load = use_load_store()
			await load.wrap(async () => {
				await this.strategy_store.delete_strategy(strategy_id)
				await this.strategy_list_store.fetch_strategy_list()
			})
		},
		async edit_strategy(strategy_id) {

			const load = use_load_store()
			await load.wrap(async () => {
				await this.strategy_store.fetch_strategy(strategy_id)

				const workspace_store = use_workspace_store()
				await workspace_store.fetch_workspaces()

				const column_store = use_column_store()
				column_store.fetch_columns_for_workspace(this.strategy_store.workspace_id, false)
			})

			this.edit_strategy_id = strategy_id
			this.modal_store.open_modal("edit_strategy")
		},
	}
}
</script>
