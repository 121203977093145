<template>
	<div :class="{'spin-backdrop': true, 'fixed-backdrop': fixed_backdrop}" v-if="load_store.visible(type)" @click.self="handle_close">
		<div v-if="load_store.has_error(type)" class="error-container">
			<div class="modal-header">
				<h5 class="modal-title">Error</h5>
				<button type="button" class="btn-close" @click="handle_close"></button>
			</div>
			<div class="alert alert-danger mt-3">{{ load_store.error_message(type) }}</div>
		</div>
		<div v-if="!load_store.has_error(type)" class="spin-container">
			<div class="spinner"></div>
			<div>Loading Data...</div>
		</div>
	</div>
</template>

<script>

import { use_load_store } from '@/stores/load'

export default {
	name: 'LoadIndicator',
	components: {
	},
	props: {
		type: {
			type: String,
			default: 'main',
		},
		fixed_backdrop: {
			type: Boolean,
			default: false,
		}
	},
	setup() {
		const load_store = use_load_store()
		return {
			load_store
		}
	},
	methods: {
		handle_close() {
			if (this.load_store.has_error(this.type)) {
				this.load_store.clear_errors(this.type)
			}
		}
	}
}
</script>

<style scoped>
.spin-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.15);
	z-index: 1100;
}

.fixed-backdrop {
	position: fixed;
	width: 100vw;
	height: 100vh;
}

.spin-container {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 115px;
	width: 200px;
	margin-left: -100px;
	margin-top: -50px;
	text-align: center;
	background: #fff;
	border: 1px solid #ccc;
	padding: 20px 0;
}

.error-container {
	position: absolute;
	top: 50px;
	left: 50%;
	width: 800px;
	height: 80vh;
	overflow-y: scroll;
	margin-left: -400px;
	margin-top: 0;
	background: #fff;
	border: 1px solid #ccc;
	padding: 20px;
}

.spinner {
	width: 48px;
	height: 48px;
	border: 5px solid #222;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.alert {
	white-space: pre-line;
}
</style>
