<template>
	<div class="container-md">
		<div class="row mt-3">
			<div class="col-md-6 offset-md-3">
				<h1>Support</h1>
				<p>Send an email with your questions to <i>{{ support_store.support_email }}</i></p>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { use_support_store } from '@/stores/support.js'
import { use_billing_store } from '@/stores/billing.js'
import { use_load_store } from '@/stores/load.js'

async function load_data(to, from, next) {

	const load = use_load_store()

	await load.wrap(async () => {

		const support_store = use_support_store()
		await support_store.fetch_support_info()

		const billing_store = use_billing_store()
		await billing_store.fetch_user()

	})

	next()
}

export default {
	name: 'SupportView',
	components: {
	},
	props: {
	},
	setup() {

		const support_store = use_support_store()

		return {
			support_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>


</style>
