
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { use_modal_store } from '@/stores/modal.js'
import { use_company_store } from '@/stores/company.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { api } from '@/api.js'

export const use_column_store = defineStore({
	id: 'column',
	state: () => ({
		// column data parameters
		active_columns: [],
		column: new Map(),
		column_data: new Map(),

		main_date: new Date(),

		order_by_column_id: -1,
		order_by_column_dir: 'asc',

		// indicates which column to edit filters on.
		edit_filter_column_id: -1,

	}),
	getters: {
		get_metric_id: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id).metric_id
			}			
		},
		get_column: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id)
			}
		},
		get_edit_filter_column: (state) => {
			return state.column.get(state.edit_filter_column_id)
		},
		get_column_scope: (state) => {
			return (column_id) => {
				if (column_id < 0) return null;
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id).scope
			}
		},
		get_column_numerator: (state) => {
			return (column_id) => {
				if (column_id < 0) return null;
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id).numerator
			}
		},
		get_column_report_type: (state) => {
			return (column_id) => {
				if (column_id < 0) return null;
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id).report_type
			}
		},
		get_column_denominator_id: (state) => {
			return (column_id) => {
				if (column_id < 0) return null;
				if (!state.column.has(column_id)) return null
				return state.column.get(column_id).denominator_id
			}
		},
		get_column_name: (state) => {
			return (column_id) => {
				if (column_id < 0) return "Company Name";
				if (!state.column.has(column_id)) return "No Name"
				return state.column.get(column_id).name
			}			
		},
		get_column_value: (state) => {
			return (column_id, company_id) => {
				if (!state.column_data.has(column_id)) return "-"
				if (!state.column_data.get(column_id).has(company_id)) return "-"
				return state.column_data.get(column_id).get(company_id)
			}			
		},
		get_column_value_type: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return "value"
				return state.column.get(column_id).value_type
			}			
		},
		get_column_is_loading: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return false
				return state.column.get(column_id).is_loading || false
			}			
		},
		get_column_is_graph: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return false
				return state.column.get(column_id).is_graph
			}
		},
		get_column_color: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return false
				return state.column.get(column_id).color
			}
		},
		has_filter: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return false 
				return state.column.get(column_id).filter_min != null || state.column.get(column_id).filter_max != null
			}			
		},
		min_filter: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return "∞" 
				if (state.column.get(column_id).filter_min === null) return "-∞"
				return state.column.get(column_id).filter_min
			}			
		},
		max_filter: (state) => {
			return (column_id) => {
				if (!state.column.has(column_id)) return "∞" 
				if (state.column.get(column_id).filter_max === null) return "∞"
				return state.column.get(column_id).filter_max
			}			
		},
		filter: (state) => {
			var filter_columns = []
			for (var column_id of state.active_columns) {
				var column = state.column.get(column_id)
				if (column.filter_min != null || column.filter_max != null) filter_columns.push(column)
			}
			return (ids) => {
				return ids.filter((company_id) => {
					var has_all = true
					for (var column of filter_columns) {
						const value = state.get_column_value(column.id, company_id)
						if (column.filter_min != null && value < column.filter_min) {
							has_all = false
							break
						}
						if (column.filter_max != null && value > column.filter_max) {
							has_all = false
							break
						}
					}
					return has_all
				})
			}
		},
		active_non_info_columns: (state) => {
			var ret = []
			for (var column_id of state.active_columns) {
				var column = state.column.get(column_id)
				if (column.value_type == "info") continue
				ret.push(column_id)
			}
			return ret
		},
	},
	actions: {
		add_column_data(column_id, column_data) {
			var column_data_map = new Map()
			for (var company_id in column_data) {
				column_data_map.set(parseInt(company_id), column_data[company_id])
			}
			this.column_data.set(column_id, column_data_map)
		},
		async fetch_columns(with_data = true, base_date = null) {
			const workspace_store = use_workspace_store()
			if (!workspace_store.has_workspace) return;
			await this.fetch_columns_for_workspace(workspace_store.workspace_id, with_data, base_date)
		},
		async fetch_columns_for_workspace(workspace_id, with_data = true, base_date = null) {
			if (workspace_id <= 0) {
				this.active_columns = [];
			}
			const user = use_user_store()
			
			const res = await axios.get(
				api.host + '/column/list/' + workspace_id,
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.active_columns = []
			this.column.clear()
			var promises = []
			for (var column of res.data.columns) {
				this.column.set(column.id, column)
				this.active_columns.push(column.id)
				if (with_data) {
					promises.push(this.fetch_column_data(column.id, base_date))
				}
			}
			await Promise.all(promises)
		},
		/*
		 * Adds new column to the active workspace
		 * */
		async add_column(metric, variables, base_date = null) {
			const user = use_user_store()
			const workspace_store = use_workspace_store()

			const date = (base_date ? base_date : this.main_date.toISOString().substring(0, 10))
			
			const res = await axios.post(
				api.host + '/column/add/' + workspace_store.workspace_id + '?date=' + date,
				{
					metric_id: metric.id,
					variables: variables,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			var column = res.data.column

			this.add_column_data(column.id, res.data.data)

			this.column.set(column.id, column)
			this.active_columns.unshift(column.id)
		},
		/*
		 * Change metric on existing column.
		 * */
		async edit_column(column_id, metric, variables, base_date = null) {
			const user = use_user_store()
			const date = (base_date ? base_date : this.main_date.toISOString().substring(0, 10))
			const res = await axios.post(
				api.host + '/column/edit/' + column_id + '?date=' + date,
				{
					metric_id: metric.id,
					variables: variables,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)

			var new_column = res.data.column

			this.add_column_data(new_column.id, res.data.data)

			this.column.set(new_column.id, new_column)
		},
		async edit_column_props(column, props) {
			const user = use_user_store()
			const modal_store = use_modal_store()
			const res = await axios.post(
				api.host + '/column/edit_props/' + column.id + '?date=' + this.main_date.toISOString().substring(0,10),
				props,
				{
					headers: { Authorization: user.session_id }
				},
			)

			var new_column = res.data.column

			this.column.set(new_column.id, new_column)

			modal_store.close_modal()
		},
		async fetch_column_data(column_id, base_date = null) {

			if (!this.column.has(column_id)) return
			this.column.get(column_id).is_loading = true

			const date = (base_date ? base_date : this.main_date.toISOString().substring(0, 10))
			
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/column/data/' + column_id + '?date=' + date,
				{
					headers: { Authorization: user.session_id }
				}
			)
			this.add_column_data(column_id, res.data.data)

			if (column_id == this.order_by_column_id) {
				this.implement_column_order()
			}
		},
		async remove_column(column_id) {
			if (!this.column.has(column_id)) return
			const user = use_user_store()
			this.column.get(column_id).is_loading = true
			await axios.get(
				api.host + '/column/remove/' + column_id,
				{
					headers: { Authorization: user.session_id }
				},
			)

			this.active_columns = this.active_columns.filter(el => el !== column_id);
		},
		order_by_column(column_id) {
			if (this.order_by_column_id == column_id) {
				if (this.order_by_column_dir == 'asc') this.order_by_column_dir = 'desc'
				else this.order_by_column_dir = 'asc'
			} else {
				this.order_by_column_dir = 'asc'
			}
			this.order_by_column_id = column_id

			this.save_order_by()

			this.implement_column_order()
		},
		implement_column_order() {
			const company_store = use_company_store()
			if (this.order_by_column_id > 0 && this.column_data.has(this.order_by_column_id)) {
				// update the order.
				var perm = []
				for (var company_id of company_store.ids) {
					var val = null
					if (this.column_data.get(this.order_by_column_id).has(company_id)) {
						val = this.column_data.get(this.order_by_column_id).get(company_id);
					}
					perm.push({id: company_id, val: val, secondary: company_id})
				}

				var dir_val = -1
				if (this.order_by_column_dir == "desc") dir_val = 1

				perm.sort(function (a, b) {
					if (a.val == b.val) {
						return ((a.secondary < b.secondary) ? dir_val : -dir_val)
					}
					if (a.val == "-") return 1;
					if (b.val == "-") return -1;
					return ((a.val < b.val) ? dir_val : ((a.val == b.val) ? 0 : -dir_val))
				})

				var sort_perm = new Map()
				for (var i = 0; i < perm.length; i++) {
					sort_perm.set(perm[i].id, i)
				}

				company_store.order_by(sort_perm)
			} else {
				company_store.order_by_company_name(this.order_by_column_dir)
			}
		},
		async save_order_by() {
			const workspace_store = use_workspace_store()
			await workspace_store.edit_workspace(workspace_store.active_workspace_id, {
				order_by_column_id: this.order_by_column_id,
				order_by_column_dir: this.order_by_column_dir,
			})
		},
		async save_column_order() {
			const user = use_user_store()
			await axios.post(
				api.host + '/column/reorder',
				{
					order: this.active_columns,
				},
				{
					headers: { Authorization: user.session_id }
				},
			)
		},
		async reload_column_data(base_date = null) {
			for (var column_id of this.active_columns) {
				this.fetch_column_data(column_id, base_date)
			}
		},
		edit_filter_column(column_id) {
			this.edit_filter_column_id = column_id
		},
	}
})
