
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_documentation_store = defineStore({
	id: 'documentation',
	state: () => ({


		documentation: new Map(),
		tables: [],

		report_tables: [
			"ttm",
			"annual",
			"interim",
			"previous_ttm",
			"previous_annual",
			"previous_interim",
			"_x_ttm_ago",
			"_x_annual_ago",
			"_x_interim_ago",
		],

		function_docs: [],

		is_loading: false,
		is_error: false,
		error_message: '',
	}),
	getters: {
		non_report_tables(state) {
			return state.tables.filter((table) => state.report_tables.indexOf(table) < 0)
		},
	},
	actions: {
		async fetch_documentation() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/documentation/all',
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.tables = []
			for (var table_name in res.data.documentation) {
				this.documentation.set(table_name, res.data.documentation[table_name])
				this.tables.push(table_name)
			}
			this.function_docs = res.data.function_docs
		},
	}
})
