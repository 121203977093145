<template>
	<div class="container">
		<div class="text-center mt-5">
			<a href="/beta" class="btn btn-primary btn-lg">Request Beta Access</a>
		</div>
		<main>
			<PlanSelector />
		</main>
	</div>
</template>

<script setup>
</script>

<script>

import PlanSelector from '@/components/PlanSelector'
import { use_load_store } from '@/stores/load.js'
import { use_billing_store } from '@/stores/billing.js'

async function load_data(to, from, next) {

	const load = use_load_store()

	load.wrap(async () => {
		const billing_store = use_billing_store()
		await billing_store.fetch_plans()
	}, "plan_selector")

	next()
}

export default {
	name: 'FrontPage',
	components: {
		PlanSelector,
	},
	props: {

	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},
}
</script>
<style scoped>
main {
	width: 960px;
	margin: 3em auto;
}

</style>
