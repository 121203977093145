
import { defineStore } from 'pinia'
import axios from "@/api/axios_instance.js";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_strategy_store = defineStore({
	id: 'strategy',
	state: () => ({

		strategy: {},
		meta: {},

		// data loading parameters
		is_loading: false,
		is_error: false,
		error_message: '',


		// graph data
		main_chart_data: {
			labels: [],
			datasets: [
				{
					label: "",
					data: [],
					fill: false,
					backgroundColor: '#f87979',
					pointRadius: 1,
					pointHitRadius: 10,
					borderWidth: 2,
					tension: 0,
				},
				{
					label: "",
					data: [],
					fill: false,
					backgroundColor: '#79f879',
					pointRadius: 1,
					pointHitRadius: 10,
					borderWidth: 2,
					tension: 0,
				}
			]
		},
		main_chart_options: {
			animation: {
				duration: 0
			},
			scales: {
				y: {
					beginAtZero: true,
				}
			},
			transitions: {
				zoom: {
					animation: {
						duration: 0
					}
				}
			},
			plugins: {
				zoom: {
					zoom: {
						wheel: {
							enabled: false,
						},
						pinch: {
							enabled: false
						},
						drag: {
							enabled: true
						},
						mode: 'x',
					}
				}
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
		},
	}),
	getters: {
		name(state) {
			return state.strategy.name
		},
		date_begin(state) {
			return state.strategy.date_begin
		},
		date_end(state) {
			return state.strategy.date_end
		},
		rebalance_after(state) {
			return state.strategy.rebalance_after
		},
		portfolio_size(state) {
			return state.strategy.portfolio_size
		},
		workspace_id(state) {
			return state.strategy.workspace_id
		},
		column_id(state) {
			return state.strategy.column_id
		},
		column_order(state) {
			return state.strategy.column_order
		},
		metric_name(state) {
			return state.strategy.metric_name
		},
		metric_id(state) {
			return state.strategy.metric_id
		},
		annual_compound(state) {
			return state.strategy.annual_compound
		},
	},
	actions: {
		async fetch_strategy(strategy_id) {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/strategy/' + strategy_id,
				{
					headers: { Authorization: user.session_id }
				}
			)

			this.strategy = res.data.strategy
			this.meta = res.data.meta
			this.main_chart_data.labels = res.data.main_graph.labels
			this.main_chart_data.datasets[0].label = res.data.main_graph.data_sets[0].label
			this.main_chart_data.datasets[0].data = res.data.main_graph.data_sets[0].data
			this.main_chart_data.datasets[1].label = res.data.main_graph.data_sets[1].label
			this.main_chart_data.datasets[1].data = res.data.main_graph.data_sets[1].data
		},
		async delete_strategy(strategy_id) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/strategy/delete',
				{
					strategy_id: strategy_id,
				},
				{
					headers: { Authorization: user.session_id }
				}
			)

			if (res.data.status == "success") {
				this.$router.push('/strategy/list')
			}
		},
		async edit_strategy(strategy_id, data) {
			const user = use_user_store()
			const res = await axios.post(
				api.host + '/strategy/edit/' + strategy_id,
				data,
				{
					headers: { Authorization: user.session_id }
				}
			)

			if (res.status == "error") {
				throw new Error(res.message)
			}
		},
	}
})
