
<template>
	<div class="card mt-3">
		<div class="card-body">
			<div class="row">
				<div class="col">
					<label>Start date</label>
					<VueDatePicker :model-value="code_store.date_begin" @update:model-value="(new_date) => code_store.date_begin = new_date" :enable-time-picker="false" format="yyyy-MM-dd" :disabled="code_store.read_only"></VueDatePicker>
				</div>
				<div class="col">
					<label>End date</label>
					<VueDatePicker :model-value="code_store.date_end" @update:model-value="(new_date) => code_store.date_end = new_date" :enable-time-picker="false" format="yyyy-MM-dd" :disabled="code_store.read_only"></VueDatePicker>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col">
					<button class="btn btn-warning" @click="handle_delete" :disabled="code_store.read_only">Delete</button>
				</div>
				<div class="col">
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>

import { use_code_store } from '@/stores/code.js'
import { use_load_store } from '@/stores/load.js'
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
	name: 'CodeToolbar',
	components: {
		VueDatePicker,
	},
	props: {
	},
	setup() {
		const code_store = use_code_store()
		return {
			code_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
		
	},
	methods: {
		async handle_delete() {
			if (confirm("Are you sure you want to delete this code?")) {
				const load_store = use_load_store()
				var ok = await load_store.wrap(async () => {
					await this.code_store.delete_code()
				})
				if (ok) {
					document.location.href = "/code"
				}
			}
		},
	}
}
</script>

<style scoped>



</style>
