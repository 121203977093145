<template>
	<div class="btn-group mt-3">
		<!--<button type="button" :class="classes('year_quarter')" @click="handle_click('year_quarter')">Year+Quarter</button> -->
		<button type="button" :class="classes('year')" @click="handle_click('year')">Year</button>
		<button type="button" :class="classes('quarter')" @click="handle_click('quarter')">Quarter</button>
	</div>
</template>

<script setup>
</script>

<script>

import { use_company_details_store } from '@/stores/company_details'

export default {
	name: 'MetricTableChooser',
	components: {
	},
	props: {
	},
	setup() {

		const company_details_store = use_company_details_store()

		return {
			company_details_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
		
	},
	methods: {
		classes(type) {
			return {
				btn: true,
				'btn-sm': true,
				'btn-outline-primary': true,
				active: this.company_details_store.metric_table == type,
			}
		},
		handle_click(type) {
			this.company_details_store.metric_table = type
			if (type == 'year') {
				this.company_details_store.max_years = this.company_details_store.years.length
			} else {
				this.company_details_store.max_years = 4
			}
		}
	}
}
</script>

<style scoped>

</style>
