<template>
	<div class="container-fluid g-0">
		<div class="row">
			<div class="col-12">
				<nav class="navbar navbar-expand-lg navbar-light main-menu">
					<div class="container-fluid">
						<a class="navbar-brand" href="/">MercuriusData</a>

						<ul class="navbar-nav me-auto mb-2 mb-lg-0">
							<li class="nav-item">
								<a class="nav-link" href="/about">About Us</a>
							</li>
						</ul>
						<ul class="navbar-nav float-end">
							<li class="nav-item">
								<a class="nav-link" href="/login">Log In</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
		<div class="splash-container">
			<div class="splash"></div>
		</div>
	</div>
</template>

<script setup>
</script>

<script>
export default {
	name: 'FrontPageMenu',
	components: {
	},
	setup() {

		return {
		}
	},
	beforeRouteEnter() {
	}
}
</script>
<style scoped>

.navbar-brand {
	padding-left: 35px;
	background: transparent url('../../public/mercuriusdata_logo_light.png') no-repeat 0 center;
	background-size: 30px 30px;
}
.main-menu {
	background: #FFF;
	border-bottom: 1px solid #ccc;
}

.splash-container {
	overflow: hidden;
}

.splash {
	width: 100%;
	height: 150px;
	background: #000 url('../../public/splash.png') no-repeat center center;
	background-size: cover;
	animation: MOVE-BG 2000s linear infinite;
}
@keyframes MOVE-BG {
	from {
		transform: translateX(0) scale(1);
	}
	to {
		transform: translateX(-200%) scale(10);
	}
}
</style>
