<template>
	<div class="container-fluid">
		<div class="col-md-3 offset-md-4 mt-3">
			<div class="alert alert-success">
				Your password has now been reset. You can now log in.
			</div>
			<a class="btn btn-primary" href="/login">Continue</a>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'

export default {
	components: {
	},
	setup() {
		const user_store = use_user_store()

		return {
			user_store
		}
	},
}
</script>
