<template>
	<table class="table table-sm table-striped mt-3">
		<thead>
			<ColumnHeader :column_id="-1" />
			<ColumnHeader v-for="column_id in column_store.active_columns" :key="column_id" :column_id="column_id" />
		</thead>
		<tbody>
			<tr v-for="company_id in column_store.filter(company_store.ids)" :key="company_id"
					:class="{'table-active': company_store.company_selected(company_id)}" @click="select_company(company_id)">

				<td>
					<a :href="'/company/' + company_id + '/' + workspace_store.active_workspace_id">{{ company_store.company_name_by_id(company_id) }}</a>
				</td>
				<td v-for="column_id in column_store.active_columns" :key="column_id">
					<CellValue :value="column_store.get_column_value(column_id, company_id)" :type="column_store.get_column_value_type(column_id)"/>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { use_company_store } from '@/stores/company.js'
import { use_column_store } from '@/stores/column.js'
import { use_workspace_store } from '@/stores/workspace.js'
import ColumnHeader from '@/molecule/ColumnHeader.vue'
import CellValue from '@/atom/CellValue.vue'

export default {

	name: 'ScreenerTable',
	components: {
		ColumnHeader,
		CellValue,
	},
	setup() {
		const company_store = use_company_store()
		const column_store = use_column_store()
		const workspace_store = use_workspace_store()

		return {
			company_store,
			column_store,
			workspace_store,
		}
	},
	methods: {
		select_company(company_id) {
			this.company_store.select_company(company_id)
		},
	},
}

</script>

<style scoped>

table {
	position: relative;
	font-size: 0.75rem;
}

tr.table-active {
	--bs-table-striped-bg: rgba(0, 0, 0, 0.25);
	--bs-table-accent-bg: rgba(0, 0, 0, 0.25);
}

</style>
