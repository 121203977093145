
import { defineStore } from 'pinia'
import axios from "axios";
import { use_user_store } from '@/stores/user.js'
import { api } from '@/api.js'

export const use_api_store = defineStore({
	id: 'api',
	state: () => ({

		api_key_ids: [],
		api_keys: new Map(),

	}),
	getters: {
	},
	actions: {
		async fetch_api_info() {
			const user = use_user_store()
			const res = await axios.get(
				api.host + '/api/keys',
				{
					headers: { Authorization: user.session_id }
				}
			)

			console.log(res.data)

			this.api_key_ids = []
			for (var key of res.data.api_keys) {
				this.api_key_ids.push(key.id)
				this.api_keys.set(key.id, key)
			}

		},
	}
})
