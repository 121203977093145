<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-6">
				<a class="btn btn-primary" href="/strategy/list">Back</a>
				<h1>{{ strategy_store.name }}</h1>
			</div>
			<div class="col-6">
				<button class="btn btn-primary float-end" @click="edit_strategy">Edit</button>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<label>Performance</label>
				<table class="table">
					<tbody>
						<tr>
							<td>Annual Compound Return</td><td>{{ $utils.growth(strategy_store.annual_compound) }}</td>
						</tr>
						<tr>
							<td>Date begin</td><td>{{ strategy_store.date_begin }}</td>
						</tr>
						<tr>
							<td>Date end</td><td>{{ strategy_store.date_end }}</td>
						</tr>
						<tr>
							<td>Column</td><td>{{ strategy_store.column_name }}</td>
						</tr>
						<tr>
							<td>Order Direction</td><td>{{ strategy_store.column_order }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-6">
				<label>Settings</label>
				<table class="table">
					<tbody>
						<tr>
							<td>Portfolio size</td><td>{{ strategy_store.portfolio_size }}</td>
						</tr>
						<tr>
							<td>Date begin</td><td>{{ strategy_store.date_begin }}</td>
						</tr>
						<tr>
							<td>Date end</td><td>{{ strategy_store.date_end }}</td>
						</tr>
						<tr>
							<td>Column</td><td>{{ strategy_store.column_name }}</td>
						</tr>
						<tr>
							<td>Order Direction</td><td>{{ strategy_store.column_order }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="alert alert-danger" v-if="strategy_store.is_error">
					{{ strategy_store.error_message }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<Line :data="strategy_store.main_chart_data" :options="strategy_store.main_chart_options" />
			</div>
			<div class="col-12">
				<h3>Strategy Event Log</h3>
				<div v-for="(meta, index) in strategy_store.meta" :key="index">
					<h4>Buy Date {{ meta.date_begin }} Sell Date {{ meta.date_end }}</h4>
					<table class="table">
						<tbody>
							<tr><th>Aktie</th><th>Column Value</th><th>Köp</th><th>Sälj</th><th>Sälj (Unadjusted)</th><th>Utdl</th><th>Split</th><th>Utveckling</th></tr>
							<tr v-for="(company_id, company_index) in meta.company_ids" :key="company_index">
								<td><a :href="'/company/' + company_id + '/' + strategy_store.workspace_id">{{ company_store.company_name_by_id(company_id) }}</a></td>
								<td>{{ meta.column_values[company_index] }}</td>
								<td>{{ meta.value_begin[company_index] }}</td>
								<td>{{ sell_adj(meta, company_id, company_index) }}</td>
								<td>{{ meta.value_end[company_index] }}</td>
								<td>{{ dividends(meta, company_id) }}</td>
								<td>{{ splits(meta, company_id) }}</td>
								<td>{{ $utils.growth(sell_adj(meta, company_id, company_index) / meta.value_begin[company_index]) }}%</td>
							</tr>
						</tbody>
					</table>
					<table class="table">
						<tbody>
							<tr><th>Företag</th><th>Event</th><th>Datum</th><th>Belopp/Multipel</th></tr>
							<tr v-for="(event, event_index) in meta.events" :key="event_index">
								<td>{{ company_store.company_name_by_id(event[1]) }}</td>
								<td>{{ event[0] }}</td>
								<td>{{ event[2] }}</td>
								<td>{{ event[3] }}</td>
							</tr>
						</tbody>
					</table>
					<hr />
				</div>
			</div>
		</div>
		<EditStrategyModal :strategy_id="strategy_id" />
	</div>
</template>

<script setup>
</script>

<script>

import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

import EditStrategyModal from '@/modals/EditStrategyModal.vue'

import { use_load_store } from '@/stores/load.js'
import { use_modal_store } from '@/stores/modal.js'
import { use_column_store } from '@/stores/column.js'
import { use_strategy_store } from '@/stores/strategy.js'
import { use_company_store } from '@/stores/company'
import { use_workspace_store } from '@/stores/workspace'


export default {
	name: 'StrategyView',
	components: {
		Line,
		EditStrategyModal,
	},
	props: {
		strategy_id: {
			type: Number,
			required: true,
		},
	},
	setup() {

		const strategy_store = use_strategy_store()
		const modal_store = use_modal_store()
		const company_store = use_company_store()
		const column_store = use_column_store()

		column_store.fetch_columns()

		return {
			strategy_store,
			modal_store,
			company_store,
		}
	},
	async beforeRouteEnter(to, from, next) {
		const load = use_load_store()
		await load.wrap(async () => {
			const store = use_strategy_store()
			await store.fetch_strategy(to.params.strategy_id)
			
			const company_store = use_company_store()
			await company_store.fetch_all_companies()

			const workspace_store = use_workspace_store()
			await workspace_store.fetch_workspaces()

			const column_store = use_column_store()
			column_store.fetch_columns_for_workspace(store.workspace_id, false)
		})
		next()
	},
	data() {
		return {
			
		}
	},
	computed: {
	},
	methods: {
		edit_strategy() {
			this.modal_store.open_modal("edit_strategy")
		},
		dividends(meta, company_id) {
			return meta.events.filter((e) => e[0] == "dividend" && e[1] == company_id).reduce((acc, e) => acc + e[3], 0)
		},
		splits(meta, company_id) {
			return meta.events.filter((e) => e[0] == "split" && e[1] == company_id).reduce((acc, e) => acc * e[3], 1.0)
		},
		sell_adj(meta, company_id, company_index) {
			let split_factor = this.splits(meta, company_id)
			let dividends = this.dividends(meta, company_id)
			return meta.value_end[company_index] / split_factor + dividends
		}
	}
}
</script>
