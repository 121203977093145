import { createRouter, createWebHistory } from 'vue-router'
import MainMenuView from '../views/MainMenuView.vue'
import FrontPageMenu from '../views/FrontPageMenu.vue'
import FrontPage from '../views/FrontPage.vue'
import AboutPage from '../views/AboutPage.vue'
import BetaPage from '../views/BetaPage.vue'
import ScreenerView from '../views/ScreenerView.vue'
import AnalyticsView from '../views/AnalyticsView.vue'
import AnalyzeOneSegmentView from '../views/AnalyzeOneSegmentView.vue'
import ExtendedAnalysisView from '../views/ExtendedAnalysisView.vue'
import CodeListView from '../views/CodeListView.vue'
import CodeView from '../views/CodeView.vue'
import StrategyListView from '../views/StrategyListView.vue'
import StrategyView from '../views/StrategyView.vue'
import CompanyView from '../views/CompanyView.vue'
import LoginView from '../views/LoginView.vue'
import ResetPasswordView from '../views/ResetPassword.vue'
import ResetWithTokenView from '../views/ResetWithTokenView.vue'
import ResetSuccess from '../views/ResetSuccess.vue'
import VerifyWithTokenView from '../views/VerifyWithTokenView.vue'
import BillingView from '../views/BillingView.vue'
import BillingConfirmView from '../views/BillingConfirmView.vue'
import InvoicesView from '../views/InvoicesView.vue'
import DocumentationView from '../views/DocumentationView.vue'
import WatchlistsView from '../views/WatchlistsView.vue'
import WatchlistView from '../views/WatchlistView.vue'
import SupportView from '../views/SupportView.vue'
import FooterView from '../views/FooterView.vue'
import APIView from '../views/APIView.vue'
import { use_user_store } from '@/stores/user.js'

const routes = [
	{
		path: '/',
		components: {
			menu: FrontPageMenu,
			content: FrontPage,
			footer: FooterView,
		},
		meta: {
			requiresAuth: false,
			autoRedirectToScreener: true,
			title: 'MercuriusData.com',
		}
	},
	{
		path: '/about',
		components: {
			menu: FrontPageMenu,
			content: AboutPage,
			footer: FooterView,
		},
		meta: {
			requiresAuth: false,
			title: 'MercuriusData.com - About Us',
		}
	},
	{
		path: '/beta',
		components: {
			menu: FrontPageMenu,
			content: BetaPage,
			footer: FooterView,
		},
		meta: {
			requiresAuth: false,
			title: 'MercuriusData.com - Register as Beta tester',
		}
	},
	{
		path: '/billing',
		components: {
			menu: MainMenuView,
			content: BillingView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: false,
			title: 'Billing - MercuriusData.com',
		}
	},
	{
		path: '/billing/confirm',
		components: {
			menu: MainMenuView,
			content: BillingConfirmView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					seti_client_secret: route.query.setup_intent_client_secret,
					pay_client_secret: route.query.payment_intent_client_secret,
				}
			},
		},
		meta: {
			requiresAuth: false,
			title: 'Billing - MercuriusData.com',
		}
	},
	{
		path: '/invoices',
		components: {
			menu: MainMenuView,
			content: InvoicesView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: false,
			title: 'Invoices - MercuriusData.com',
		}
	},
	{
		path: '/screener',
		components: {
			menu: MainMenuView,
			content: ScreenerView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Screener',
		}
	},
	{
		path: '/screener/date/:base_date',
		components: {
			menu: MainMenuView,
			content: ScreenerView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					base_date: route.params.base_date,
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Screener',
		}
	},
	{
		path: '/workspace/:workspace_id',
		components: {
			menu: MainMenuView,
			content: ScreenerView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					workspace_id: parseInt(route.params.workspace_id)
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Screener',
		}
	},
	{
		path: '/analytics',
		components: {
			menu: MainMenuView,
			content: AnalyticsView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Analytics',
		}
	},
	{
		path: '/code',
		components: {
			menu: MainMenuView,
			content: CodeListView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Code',
		}
	},
	{
		path: '/code/:code_id',
		components: {
			menu: MainMenuView,
			content: CodeView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					code_id: parseInt(route.params.code_id),
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Code',
		}
	},
	{
		path: '/analytics/one_segment/:workspace_id/:column_id/:segment_idx/:date_begin/:date_end',
		components: {
			menu: MainMenuView,
			content: AnalyzeOneSegmentView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					workspace_id: parseInt(route.params.workspace_id),
					column_id: parseInt(route.params.column_id),
					segment_idx: parseInt(route.params.segment_idx),
					date_begin: route.params.date_begin,
					date_end: route.params.date_end,
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Analyze One Segment',
		}
	},
	{
		path: '/analytics/extended_analysis/:result_id',
		components: {
			menu: MainMenuView,
			content: ExtendedAnalysisView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					result_id: parseInt(route.params.result_id),
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Extended Analysis',
		}
	},
	{
		path: '/analytics/:workspace_id',
		components: {
			menu: MainMenuView,
			content: AnalyticsView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					workspace_id: parseInt(route.params.workspace_id)
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Analytics',
		}
	},
	{
		path: '/strategy/list',
		components: {
			menu: MainMenuView,
			content: StrategyListView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Strategies',
		}
	},
	{
		path: '/strategy/:strategy_id',
		components: {
			menu: MainMenuView,
			content: StrategyView,
			footer: FooterView,
		},
		props: {
			menu: false,
			content: (route) => {
				return {
					strategy_id: parseInt(route.params.strategy_id)
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Strategies',
		}
	},
	{
		path: '/company/:company_id/:workspace_id',
		components: {
			menu: MainMenuView,
			content: CompanyView,
			footer: FooterView,
		},
		props: {
			menu: false,
			content: (route) => {
				return {
					company_id: parseInt(route.params.company_id),
					workspace_id: parseInt(route.params.workspace_id)
				}
			},
		},
		meta: {
			requiresAuth: true
		},

	},
	{
		path: '/login',
		components: {
			menu: FrontPageMenu,
			content: LoginView,
			footer: FooterView,
		},
		meta: {
			title: 'Log In',
		},
	},
	{
		path: '/login/reset',
		components: {
			content: ResetPasswordView,
			footer: FooterView,
		},
		meta: {
			title: 'Reset Password',
		},
	},
	{
		path: '/login/reset/:token',
		components: {
			content: ResetWithTokenView,
			footer: FooterView,
		},
		meta: {
			title: 'Reset Password',
		},
	},
	{
		path: '/login/verify/:token',
		components: {
			content: VerifyWithTokenView,
			footer: FooterView,
		},
		meta: {
			title: 'Verify Email',
		},
	},
	{
		path: '/login/with/:session',
		components: {
			content: LoginView,
			footer: FooterView,
		},
		meta: {
			title: 'Reset Password',
		},
	},
	{
		path: '/login/reset_success',
		components: {
			content: ResetSuccess,
			footer: FooterView,
		},
		meta: {
			title: 'Reset Password',
		}
	},
	{
		path: '/documentation',
		components: {
			menu: MainMenuView,
			content: DocumentationView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Documentation',
		},
	},
	{
		path: '/documentation/code',
		components: {
			menu: MainMenuView,
			content: DocumentationView,
			footer: FooterView,
		},
		props: {
			content: () => {
				return {
					tab: 'code',
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Documentation',
		},
	},
	{
		path: '/watchlists',
		components: {
			menu: MainMenuView,
			content: WatchlistsView,
			footer: FooterView,
		},
		meta: {
			requiresAuth: true,
			title: 'Watchlists',
		},
	},
	{
		path: '/watchlist/:watchlist_id',
		components: {
			menu: MainMenuView,
			content: WatchlistView,
			footer: FooterView,
		},
		props: {
			content: (route) => {
				return {
					watchlist_id: parseInt(route.params.watchlist_id),
				}
			},
		},
		meta: {
			requiresAuth: true,
			title: 'Watchlists',
		},
	},
	{
		path: '/support',
		components: {
			menu: MainMenuView,
			content: SupportView,
			footer: FooterView,
		},
		meta: {
			title: 'Support',
		},
	},
	{
		path: '/api',
		components: {
			menu: MainMenuView,
			content: APIView,
			footer: FooterView,
		},
		meta: {
			title: 'API',
		},
	},
]

const router = createRouter({
	mode: 'history',
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	const user = use_user_store()
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (user.session_id === null) {
			next({ path: '/login' })
		} else {
			try {
				await user.fetch_user()
			} catch(err) {
				user.log_out()
				next({ path: '/login' })
				return
			}
			next()
		}
	} else if (to.matched.some(record => record.meta.autoRedirectToScreener)) {
		if (user.session_id !== null) {
			next({ path: '/screener' })
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
