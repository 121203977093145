<template>
	<table class="table table-sm table-striped">
		<thead>
			<tr>
				<th class="first-col"></th>
				<template v-for="year in company_details_store.active_years" :key="year">
					<th colspan="4" class="text-center">{{ year }}</th>
				</template>
			</tr>
			<tr>
				<th>Metric</th>
				<template v-for="year in company_details_store.active_years" :key="year">
					<th v-if="company_details_store.has_quarter(year, 1)">Q1</th>
					<th v-if="company_details_store.has_quarter(year, 2)">Q2</th>
					<th v-if="company_details_store.has_quarter(year, 3)">Q3</th>
					<th v-if="company_details_store.has_quarter(year, 4)">Q4</th>
				</template>
			</tr>
		</thead>
		<tbody>
			<tr v-for="column_id in column_store.active_non_info_columns" :key="column_id">
				<td class="first-col">
					<div class="color-box" :style="{background: column_store.get_column_color(column_id)}"></div>
					<label><input type="checkbox" :checked="column_store.get_column_is_graph(column_id)" @change="company_details_store.change_graph_column($event.target.checked, column_id)" /> {{ column_store.get_column_name(column_id) }}</label>
				</td>
				<template v-for="year in company_details_store.active_years" :key="year">
					<td v-if="company_details_store.has_quarter(year, 1)"><CellValue :value="company_details_store.get_period_metric(year, 'q1', column_id)" :type="column_store.get_column_value_type(column_id)" /></td>
					<td v-if="company_details_store.has_quarter(year, 2)"><CellValue :value="company_details_store.get_period_metric(year, 'q2', column_id)" :type="column_store.get_column_value_type(column_id)" /></td>
					<td v-if="company_details_store.has_quarter(year, 3)"><CellValue :value="company_details_store.get_period_metric(year, 'q3', column_id)" :type="column_store.get_column_value_type(column_id)" /></td>
					<td v-if="company_details_store.has_quarter(year, 4)"><CellValue :value="company_details_store.get_period_metric(year, 'q4', column_id)" :type="column_store.get_column_value_type(column_id)" /></td>
				</template>
			</tr>
		</tbody>
	</table>			
</template>

<script setup>
</script>

<script>

import CellValue from '@/atom/CellValue.vue'

import { use_company_details_store } from '@/stores/company_details'
import { use_column_store } from '@/stores/column.js'

export default {
	name: 'MetricTableQuarter',
	components: {
		CellValue,
	},
	props: {
	},
	setup() {

		const company_details_store = use_company_details_store()
		const column_store = use_column_store()

		return {
			company_details_store,
			column_store,
		}
	},
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>
table {
	font-size: 0.6rem;
}

.first-col {
	width: 250px;
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
}

.color-box {
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 5px;
}
</style>
