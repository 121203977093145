import { createApp, markRaw } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import utils from '@/utils.js'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"

const plugins = {
	install(app) {
		app.config.globalProperties.$utils = utils
	}
}

const pinia = createPinia()
pinia.use(({ store }) => {
	store.$router = markRaw(router)
})
const app = createApp(App)
app.use(plugins)
app.use(pinia)
app.use(router)
app.use(VueAxios, axios)
app.use(VueGtag, {
	config: {
		id: 'G-6TYZ27CK59',
	}
})
app.mount('#app')
