<template>
	<div class="row">
		<div class="col-md-4 offset-md-4 mt-3">

			<h2 class="text-center">Log in</h2>
			<form @submit.prevent="handle_login">
				<div class="mb-3">
					<input type="text" class="form-control" placeholder="Email" required="required" name="email" v-model="user_store.login_email">
				</div>
				<div class="mb-3">
					<input type="password" class="form-control" placeholder="Password" required="required" name="password" v-model="user_store.login_password">
				</div>
				<div class="mb-3">
					<button type="submit" class="btn btn-primary btn-block">Log in</button>
				</div>
			</form>
			<div class="mb-3">
				<center>
					<router-link to="/login/reset">Forgot your password?</router-link>
				</center>
			</div>
		</div>
	</div>
</template>

<script>

import { use_user_store } from '@/stores/user.js'
import { use_load_store } from '@/stores/load.js'

export default {
	name: 'LogIn',
	components: {
	},
	setup() {
		const user_store = use_user_store()
		return {
			user_store,
		}
	},
	methods: {
		async handle_login() {
			const load = use_load_store()

			await load.wrap(async () => {
				await this.user_store.log_in()
			})
		}
	}
}
</script>
