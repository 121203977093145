<template>
	<div>
		<div class="container-fluid" v-if="workspace_store.has_workspace">
			<div class="row">
				<div class="col-12">
					<AnalyticsMenu />
				</div>
				<div class="col-12">
					<ScreenerMenu :show_add_backtest="false" :show_export="true" />
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<AnalyticsTable />
				</div>
			</div>
		</div>
		<AddMetricModal @changed-column="reload_data" />
		<FilterColumnModal />
		<MoveColumnModal />
		<BulkMetricsModal />
		<ExportMetricsModal />
		<ExportMetricsModal2 />
	</div>
</template>

<script setup>
</script>

<script>
import ScreenerMenu from '@/components/ScreenerMenu.vue'
import AnalyticsMenu from '@/components/AnalyticsMenu.vue'
import AnalyticsTable from '@/components/AnalyticsTable.vue'
import AddMetricModal from '@/modals/AddMetricModal.vue'
import MoveColumnModal from '@/modals/MoveColumnModal.vue'
import BulkMetricsModal from '@/modals/BulkMetricsModal.vue'
import ExportMetricsModal from '@/modals/ExportMetricsModal.vue'
import ExportMetricsModal2 from '@/modals/ExportMetricsModal2.vue'
import FilterColumnModal from '@/modals/FilterColumnModal.vue'
import { use_load_store } from '@/stores/load.js'
import { use_company_store } from '@/stores/company.js'
import { use_column_store } from '@/stores/column.js'
import { use_workspace_store } from '@/stores/workspace.js'
import { use_metric_store } from '@/stores/metric.js'
import { use_modal_store } from '@/stores/modal.js'
import { use_config_store } from '@/stores/config.js'
import { use_analytics_store } from '@/stores/analytics.js'

async function load_data(to, from, next) {
	const load = use_load_store()
	await load.wrap(async () => {
		const metric_store = use_metric_store()
		await metric_store.fetch_categories()
		await metric_store.fetch_metrics()

		const company_store = use_company_store()
		await company_store.fetch_companies()

		const workspace_store = use_workspace_store()
		workspace_store.workspace_picker_base_url = "/analytics/"
		await workspace_store.fetch_workspaces(parseInt(to.params.workspace_id || 0))

		if (workspace_store.active_workspace_id > 0) {
			const config_store = use_config_store()
			await config_store.set_config('current_workspace_id', workspace_store.active_workspace_id)
		}

		const column_store = use_column_store()
		await column_store.fetch_columns()

		const analytics_store = use_analytics_store()
		await analytics_store.fetch_segments()
		await analytics_store.fetch_data()
	})
	next()
}

export default {
	name: 'AnalyticsView',
	components: {
		ScreenerMenu,
		AnalyticsMenu,
		AnalyticsTable,
		AddMetricModal,
		MoveColumnModal,
		BulkMetricsModal,
		ExportMetricsModal,
		ExportMetricsModal2,
		FilterColumnModal,
	},
	props: {

		workspace_id: {
			type: Number,
			default: 0,
		},

	},
	setup() {

		const workspace_store = use_workspace_store()
		const modal_store = use_modal_store()

		return {
			workspace_store,
			modal_store,
		}
	},
	async beforeRouteEnter(to, from, next) {

		await load_data(to, from, next)
		
	},
	async beforeRouteUpdate(to, from, next) {
		
		await load_data(to, from, next)

	},

	methods: {
		async reload_data() {
			const load = use_load_store()
			await load.wrap(async () => {
				const analytics_store = use_analytics_store()
				await analytics_store.fetch_data()
			})
		}
	}
}
</script>
