<template>
	<div class="row">
		<div class="col-12">
			<h3>New Metric</h3>
			<p>Create your own metric</p>
		</div>
		<div class="col-4">
			<label>Column Name</label>
			<input type="text" class="form-control mb-3" v-model="editable_name" :disabled="!user_store.has_permission('custom_metric')" />
		</div>
		<div class="col-4">
			<label>Column Type</label>
			<select class="form-select mb-3" v-model="editable_type" :disabled="!user_store.has_permission('custom_metric')">
				<option value="value">Value</option>
				<option value="rank">Rank</option>
				<option value="percent">Percent</option>
			</select>
		</div>
		<div class="col-4">
			<label>Variable Name</label>
			<input type="text" class="form-control mb-3" :value="calculated_var_name" :disabled="true"/>
		</div>
		<div class="col-12">
			<CodeEditor v-model="editable_script" :disabled="!user_store.has_permission('custom_metric')"></CodeEditor>
		</div>
		<div class="col-12">
			<div class="alert alert-danger mt-3" v-if="metric_store.is_error">
				{{ metric_store.error_message }}
			</div>
		</div>
		<div class="col-12">
			<a href="/documentation" target="_blank">Documentation</a>
			<button type="button" class="btn btn-primary float-end mt-3" @click="handle_create_metric" :disabled="!user_store.has_permission('custom_metric')">Create Metric</button>
			<i v-if="!user_store.has_permission('custom_metric')" class="float-end me-3 mt-4">Upgrade plan to create custom metrics</i>
		</div>
	</div>
</template>

<script>

import { use_metric_store } from '@/stores/metric.js'
import { use_column_store } from '@/stores/column.js'
import { use_load_store } from '@/stores/load.js'
import { use_user_store } from '@/stores/user.js'
import { use_modal_store } from '@/stores/modal.js'
import CodeEditor from '@/components/CodeEditor.vue'

export default {
	name: "MetricSettings",
	components: {
		CodeEditor,
	},
	props: {
		base_date: {
			type: String,
			default: null,
		},
	},
	setup() {

		const metric_store = use_metric_store()
		const column_store = use_column_store()
		const modal_store = use_modal_store()
		const user_store = use_user_store()

		return {
			metric_store,
			column_store,
			modal_store,
			user_store,
		}
	},
	data() {
		return {
			editable_name: "",
			editable_var_name: "",
			editable_type: "value",
			editable_script: "return null",
		}
	},
	methods: {
		async handle_create_metric() {
			const load = use_load_store()
			var ok = await load.wrap(async () => {
				await this.metric_store.create_metric(this.editable_script, this.editable_name, this.editable_var_name, this.editable_type)
				await this.column_store.add_column(this.metric_store.get_open_metric, {}, this.base_date)
			})

			if (ok) {
				this.$emit('added-column')
				this.modal_store.close_modal()
			}
		},
	},
	watch: {
	},
	computed: {
		calculated_var_name() {
			return this.$utils.calculate_var_name(this.editable_name)
		},
	}
}
</script>

<style scoped>
.code-input {
	width: 100%;
}
</style>
